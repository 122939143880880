import React from 'react';
import { Modal, Button } from 'antd';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';

import { ModelsChangeLog } from 'types';

import styles from './change-log-modal.module.scss';
import 'react-json-view-lite/dist/index.css';

type Props = {
    log: ModelsChangeLog;
    isOpen: boolean;
    onClose: () => void;
};

export function ChangeLogModal({ log, isOpen, onClose }: Props) {
    const parsedObj = typeof log === 'string' ? JSON.parse(log) : log;
    const defaultedLog = Array.isArray(parsedObj) ? parsedObj : [];
    const newestFirst = [...defaultedLog].reverse();

    return (
        <Modal
            width={700}
            title={'Model settings changelog'}
            open={isOpen}
            className={styles.modal}
            onCancel={onClose}
            footer={<Button onClick={onClose}>Close</Button>}
        >
            <div className={styles['inner-container']}>
                {newestFirst.length ? (
                    <JsonView data={newestFirst} shouldInitiallyExpand={allExpanded} style={defaultStyles} />
                ) : (
                    <div className={styles['message-container']}>
                        <span>No changes were made yet.</span>
                    </div>
                )}
            </div>
        </Modal>
    );
}
