import { ICanvas } from 'pages/label/store/canvas/canvas';
import { DocumentStatus, IDocument, IRawDocument } from 'pages/label/store/documents/documentsTypes';
import { IDocumentLoader } from './index';
import { httpService } from 'core/services/httpService';

export class ImageLoader implements IDocumentLoader {
    private document: IRawDocument;
    constructor(document: IRawDocument) {
        this.document = document;
    }

    public async setup(): Promise<void> {
        if (this.document.url) {
            const res = await fetch(this.document.url as string, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${await httpService.getAccessToken()}`,
                },
            });

            const blob = await res.blob();
            this.document.url = URL.createObjectURL(blob);
        } else {
            const blob = this.b64toBlob(this.document.file2Base64);
            this.document.url = URL.createObjectURL(blob);
        }
        return;
    }

    private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays: Uint8Array[] = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    public async loadDocumentMeta(): Promise<IDocument> {
        return {
            ...this.document,
            thumbnail: this.document.url as string,
            numPages: 1,
            currentPage: 1,
            states: { loadingStatus: DocumentStatus.Loaded },
        };
    }

    public async loadDocumentPage(pageNumber: number): Promise<ICanvas> {
        const { width, height } = await this.readImageSize(this.document.url as string);
        return {
            imageUrl: this.document.url as string,
            width,
            height,
            angle: 0,
        };
    }

    private readImageSize(url: string): Promise<{ width: number; height: number }> {
        return new Promise((resolve, reject) => {
            const image = document.createElement('img');
            image.onload = () => {
                resolve({
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                });
            };
            image.onerror = reject;
            image.src = url;
        });
    }
}
