import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetPredictions } from 'pages/label/store/predictions/predictions';
import { DocumentLoaderFactory, IDocumentLoader } from 'pages/label/utils/documentLoader';
import { DocumentStatus, IDocument, IRawDocument } from './documentsTypes';
import { ICanvas } from '../canvas/canvas';

export type DocumentsState = { documents: IDocument[]; currentDocument: IDocument | null };

export const initialState: DocumentsState = { documents: [], currentDocument: null };

export const addDocuments = createAsyncThunk('documents/addDocuments', async (docsToAdd: IRawDocument[]) => {
    return Promise.all(docsToAdd.map(async (doc) => (await getLoader(doc)).loadDocumentMeta()));
});

export const setCurrentDocument = createAsyncThunk('documents/setCurrentDocument', async (document?: IDocument) => {
    if (document) {
        const loader = await getLoader(document);
        const documentPage = await loader.loadDocumentPage(document.currentPage);
        return { document, documentPage };
    } else {
        return { document: {} as IDocument, documentPage: {} as ICanvas };
    }
});

export const setCurrentPage = createAsyncThunk('documents/setCurrentPage', async (pageNumber: number, thunkApi) => {
    const { documents: state } = thunkApi.getState() as any;
    const loader = await getLoader(state.currentDocument);
    const documentPage = await loader.loadDocumentPage(pageNumber);
    return { pageNumber, documentPage };
});

const documentLoaders = new Map<string, IDocumentLoader>();

const getLoader = async (document: IRawDocument): Promise<IDocumentLoader> => {
    if (!document.id) {
        return await DocumentLoaderFactory.makeLoader(document);
    }

    let loader = documentLoaders.get(document.id);

    if (!loader) {
        loader = await DocumentLoaderFactory.makeLoader(document);
        documentLoaders.set(document.id, loader);
    }

    return loader;
};

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        deleteDocument(state, action) {
            const docIdToDelete = action.payload.id;
            state.documents = state.documents.filter((document) => document.id !== docIdToDelete);
            documentLoaders.delete(docIdToDelete);
        },
        changeDocumentData(state, action) {
            const docToChange = action.payload;

            state.documents = state.documents.map((document) => {
                if (document.id === docToChange.id) {
                    return docToChange;
                } else {
                    return document;
                }
            });
        },
        setDocumentAnalyzingStatus(state, action) {
            const { id, status } = action.payload;
            const document = state.documents.find((document) => document.id === id);
            if (document) {
                document.states.analyzingStatus = status;
            }
            if (state.currentDocument?.id === id) {
                state.currentDocument!.states.analyzingStatus = status;
            }
        },
        setDocumentLabelingStatus(state, action) {
            const { id, status } = action.payload;
            const document = state.documents.find((document) => document.id === id);
            if (document) {
                document.states.labelingStatus = status;
            }
            if (state.currentDocument?.id === id) {
                state.currentDocument!.states.labelingStatus = status;
            }
        },
        clearCurrentDocument(state) {
            state.currentDocument = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetPredictions, (state) => {
                state.documents.forEach((document) => {
                    document.states = { ...document.states, analyzingStatus: undefined };
                });
                if (state.currentDocument) {
                    state.currentDocument.states = { ...state.currentDocument.states, analyzingStatus: undefined };
                }
            })
            .addCase(addDocuments.pending, (state, action) => {
                const docs = action.meta.arg;
                if (docs?.length < 1) {
                    state.documents = [];
                } else {
                    docs.forEach((doc) => {
                        state.documents.push({
                            ...doc,
                            thumbnail: '',
                            numPages: 0,
                            currentPage: 0,
                            states: { loadingStatus: DocumentStatus.Loading },
                        });
                    });
                }
            })
            .addCase(setCurrentDocument.pending, (state, action) => {
                const document = action.meta.arg;
                const selectedDoc = state.documents.find((doc) => doc.id === document?.id);
                if (selectedDoc) {
                    selectedDoc.states.loadingStatus = DocumentStatus.Loading;
                }
            })
            .addCase(setCurrentDocument.fulfilled, (state, action) => {
                if (action.payload?.document?.id) {
                    const document: IDocument = {
                        ...action.payload.document,
                        states: {
                            ...action.payload.document.states,
                            loadingStatus: DocumentStatus.Loaded,
                        },
                    };
                    const selectedDoc = state.documents.find((doc) => doc.id === document.id);
                    if (selectedDoc) {
                        selectedDoc.states.loadingStatus = DocumentStatus.Loaded;
                    }
                    state.currentDocument = document;
                } else {
                    state.currentDocument = null;
                }
            })
            .addCase(setCurrentPage.fulfilled, (state, action) => {
                const { pageNumber } = action.payload;
                const document = state.documents.find((doc) => doc.id === state.currentDocument?.id);
                if (document) {
                    document.currentPage = pageNumber;
                }
                if (state.currentDocument) {
                    state.currentDocument.currentPage = pageNumber;
                }
            })
            .addCase(addDocuments.fulfilled, (state, action) => {
                const addedDocs = action.payload;
                const { documents } = state;
                addedDocs.forEach((doc) => {
                    const iDoc = documents.findIndex((d) => d.id === doc.id);
                    if (iDoc >= 0) {
                        documents[iDoc] = {
                            ...doc,
                            states: { ...documents[iDoc].states, loadingStatus: DocumentStatus.Loaded },
                        };
                    }
                });
            });
    },
});

export const {
    deleteDocument,
    changeDocumentData,
    setDocumentAnalyzingStatus,
    setDocumentLabelingStatus,
    clearCurrentDocument,
} = documentsSlice.actions;

export const reducer = documentsSlice.reducer;
