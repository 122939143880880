import React from 'react';
import { Spin } from 'antd';

import styles from './loader.module.scss';

export function Loader() {
    return (
        <div className={styles.container}>
            <Spin />
        </div>
    );
}
