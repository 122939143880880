import * as React from 'react';
import { Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { Select } from './select';
import { Input } from './input';
import { hasWhiteSpaceAtEdges, isOnlyWhiteSpace } from 'helpers';

import { RowRuleActionCondition } from 'types';

import styles from './rowCondition.module.scss';

type DropdownItem = {
    value: string;
    label: string;
};

type Props = {
    ruleIndex: number;
    actionIndex: number;
    conditionIndex: number;
    description: RowRuleActionCondition;
    onOperandChange: (item: DropdownItem, ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onOperandDetailChange: (text: string, ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onOperatorChange: (item: DropdownItem, ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onValueChange: (text: string, ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onDelete: (ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
};

export class RowCondition extends React.PureComponent<Props> {
    private getOperandDropdown = () => {
        return [{ value: 'Cell with mapped column name', label: 'Cell with mapped column name' }];
    };

    private getOperatorDropdown = () => {
        return [
            { value: 'Equals', label: 'Equals' },
            { value: 'Matches', label: 'Matches' },
            { value: 'Has a value', label: 'Has a value' },
            { value: 'Does not equal', label: 'Does not equal' },
            { value: 'Does not match', label: 'Does not match' },
        ];
    };

    private getRowCondition = () => {
        const {
            ruleIndex,
            actionIndex,
            conditionIndex,
            description,
            onOperandChange,
            onOperandDetailChange,
            onOperatorChange,
            onValueChange,
            onDelete,
        } = this.props;

        const { operand, operandDetail, operator, value } = description;

        const items: React.ReactNode[] = [];

        if (operand) {
            items.push(
                <div
                    key={`row-rule-${ruleIndex}-action-${actionIndex}-condition-${conditionIndex}-operand`}
                    className={styles['item']}
                >
                    <Select
                        className={styles['select']}
                        options={this.getOperandDropdown()}
                        onChange={(value) => {
                            const item = this.getOperandDropdown().find((option) => option.value === value);

                            return onOperandChange(item!, ruleIndex, actionIndex, conditionIndex);
                        }}
                        value={operand}
                    />
                </div>
            );
        }

        items.push(
            <div
                key={`row-rule-${ruleIndex}-action-${actionIndex}-condition-${conditionIndex}-operand-detail`}
                className={styles['item-flex']}
            >
                <Input
                    placeholder={'Some value'}
                    value={operandDetail}
                    onChange={(e) => onOperandDetailChange(e.target.value, ruleIndex, actionIndex, conditionIndex)}
                    validator={(text) => {
                        if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                            return 'Please enter a valid value';
                        }

                        return undefined;
                    }}
                />
            </div>
        );

        if (operator) {
            items.push(
                <div
                    key={`row-rule-${ruleIndex}-action-${actionIndex}-condition-${conditionIndex}-operator`}
                    className={styles['item']}
                >
                    <Select
                        className={styles['select']}
                        options={this.getOperatorDropdown()}
                        onChange={(value) => {
                            const item = this.getOperatorDropdown().find((option) => option.value === value);

                            return onOperatorChange(item!, ruleIndex, actionIndex, conditionIndex);
                        }}
                        value={operator}
                    />
                </div>
            );
        }

        items.push(
            <div
                key={`row-rule-${ruleIndex}-action-${actionIndex}-condition-${conditionIndex}-value`}
                className={styles['item-flex']}
            >
                {typeof value === 'string' && (
                    <Input
                        placeholder={'Some value'}
                        value={value}
                        isValueRegex={operator === 'Matches' || operator === 'Does not match'}
                        onChange={(e) => onValueChange(e.target.value, ruleIndex, actionIndex, conditionIndex)}
                        validator={(text) => {
                            if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                return 'Please enter a valid value';
                            }

                            return undefined;
                        }}
                    />
                )}
                {!value && typeof value !== 'string' && (
                    <Input
                        disabled={true}
                        placeholder={''}
                        value={value}
                        isValueRegex={operator === 'Matches'}
                        onChange={(e) => onValueChange(e.target.value, ruleIndex, actionIndex, conditionIndex)}
                    />
                )}
            </div>
        );

        items.push(
            <div
                key={`row-rule-${ruleIndex}-action-${actionIndex}-condition-${conditionIndex}-delete`}
                className={styles['button-container']}
            >
                <Button
                    className={styles['button']}
                    icon={<MinusOutlined style={{ fontSize: 10 }} />}
                    onClick={() => onDelete(ruleIndex, actionIndex, conditionIndex)}
                />
            </div>
        );

        return items;
    };

    public render = () => {
        return <div className={styles['row']}>{this.getRowCondition()}</div>;
    };
}
