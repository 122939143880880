import { useAuth0 } from '@auth0/auth0-react';
import { roles } from '../types';

export const useRoles = (): { userRoles: roles } => {
    const { user } = useAuth0();

    // This namespace can be kept constant throughout all envs since auth0 does not check against the domain.
    const nameSpacedCustomClaim = 'https://ocr.synatic.com';
    const userRoles = user?.[`${nameSpacedCustomClaim}/roles`] || [];

    return {
        userRoles: userRoles,
    };
};
