import React from 'react';
import { Button, Pagination } from 'antd';

import styles from './transformedOutput.module.scss';

type Props = {
    tables: any[];
    onClose: () => void;
};

type State = {
    tablePageNumber: number;
};

// TODO: The mapTables() output does not include column / row spans, this might prove problematic. If so, will need
// to find a solution to this. Assuming column / row span to be 1 now.
// TODO: Will need to account for table header location as well.

export class TransformedOutput extends React.Component<Props, State> {
    state = {
        tablePageNumber: 1,
    };

    private onPaginationChange = (tablePageNumber) => {
        this.setState({
            tablePageNumber: tablePageNumber,
        });
    };

    private buildTableBody = (table): JSX.Element[] | null => {
        if (!table) {
            return null;
        }

        const uniqueHeaders = {};

        for (const row of table) {
            const rowHeaders = Object.keys(row);

            for (const header of rowHeaders) {
                uniqueHeaders[header] = true;
            }
        }

        const headers = Object.keys(uniqueHeaders);

        const renderedRows: any[] = [];

        renderedRows.push(
            <tr key={'table-row-headers'}>
                {headers.map((header, index) => (
                    <th key={`table-row-header-${index}`} colSpan={1} rowSpan={1}>
                        {header}
                    </th>
                ))}
            </tr>
        );

        table.forEach((row, index) => {
            renderedRows.push(
                <tr key={`table-row-${index}`}>
                    {Object.values(row).map((content, contentIndex) => (
                        <td key={`table-row-data-${contentIndex}`} colSpan={1} rowSpan={1}>
                            {content}
                        </td>
                    ))}
                </tr>
            );
        });

        return renderedRows;
    };

    public render = () => {
        const { onClose, tables } = this.props;
        const { tablePageNumber } = this.state;

        if (!tables?.length) {
            return (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 32,
                            height: 256,
                        }}
                    >
                        <span style={{ textAlign: 'center', maxWidth: 512 }}>
                            {`Sorry, no tables were transformed with the selected ruleset's column or row rules. Please try refining them or select another ruleset.`}
                        </span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className={styles['footer-button']} onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </>
            );
        }

        return (
            <div className={styles['container']}>
                <div className={styles['row']}>
                    <div className={styles['table-container']}>
                        <div className={styles['table-scrollable-container']}>
                            <table className={styles['table-view']}>
                                <tbody>{this.buildTableBody(tables[tablePageNumber - 1])}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={styles['row']}>
                    <div>
                        <Pagination
                            simple={true}
                            current={tablePageNumber}
                            total={tables?.length}
                            pageSize={1}
                            onChange={this.onPaginationChange}
                        />
                    </div>
                    <div>
                        <Button className={styles['footer-button']} onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
}
