import React from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { formService } from '../data/formService';
import { IFormValues } from '../data/model';
import { useContextValues } from '../../../components/providers/MainProvider';
import { FORM_TREE_VIEW } from '../../../data/consts/apiQuery';

import styles from '../form-crud.module.scss';

export function FormTypeVersionPageCreate() {
    const queryClient = useQueryClient();
    const { notificationApi } = useContextValues();
    const [form] = Form.useForm();
    const { formId, formTypeId, formTypeVersionId } = useParams();

    const navigate = useNavigate();

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation({
        mutationFn: (values: IFormValues) => {
            return formService.createFormTypeVersionPage(
                values,
                formId as string,
                formTypeId as string,
                formTypeVersionId as string
            );
        },
        onSuccess: (data) => {
            notificationApi.success({
                message: 'Created page',
                description: 'A new page has been created.',
                placement: 'topRight',
            });

            queryClient.invalidateQueries({ queryKey: [FORM_TREE_VIEW] });

            const formId = data?.formId;
            const typeId = data?.formTypeId;
            const versionId = data?.formTypeVersionId;
            const pageId = data?._id;

            navigate(`/label/page/${formId}/${typeId}/${versionId}/${pageId}`);
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Failed to create page',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    const handleFormSubmit = (values) => {
        mutateCreate(values);
    };

    return (
        <>
            <Typography.Paragraph className={styles.card__title}>Add page</Typography.Paragraph>

            <Form
                form={form}
                layout="vertical"
                size="large"
                onFinish={(val) => handleFormSubmit({ ...val, type: 'Paged Form' })}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    required
                    rules={[{ required: true, message: 'Title cannot be empty' }]}
                >
                    <Input style={{ width: '50%' }} placeholder="Please enter a title" size="middle" />
                </Form.Item>

                <Form.Item label="Description" name="description">
                    <Input.TextArea rows={4} placeholder="Please enter a description" size="middle" />
                </Form.Item>

                <Form.Item label="Post script" name="postScript">
                    <CodeEditor
                        className={styles.form__script_field}
                        language="js"
                        placeholder="Please enter JS code."
                        padding={10}
                        minHeight={150}
                    />
                </Form.Item>

                <Button size={'middle'} loading={isLoadingCreate} type="primary" htmlType="submit">
                    {isLoadingCreate ? 'Creating' : 'Create'}
                </Button>
            </Form>
        </>
    );
}
