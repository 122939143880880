import { getRulesetsFromTableActions, getTableActionsFromRulesets, TableAction } from 'helpers';
import { httpService, IHttpService } from '../../core/services/httpService';
import { Ruleset } from '../../types';

type IRulesetService = {
    createRuleset: (ruleset: Ruleset, formId: string) => Promise<any>;
    getRuleset: (formId: string, rulesetId: string) => Promise<any>;
    getRulesets: (formId: string) => Promise<any>;
    updateRulesets: (rulesets: Ruleset[], formId: string) => Promise<any>;
    deleteRuleset: (formId: string, rulesetId: string) => Promise<any>;
};

type IRulesetRepository = {
    createRuleset: (tableActions: TableAction[], formId: string) => Promise<Response>;
    getRuleset: (formId: string, rulesetId: string) => Promise<Response>;
    getRulesets: (formId: string) => Promise<Response>;
    updateRulesets: (tableActions: TableAction[], formId: string) => Promise<Response>;
    deleteRuleset: (formId: string, rulesetId: string) => Promise<Response>;
};

type Response = {
    data: any;
    status: number;
};

// eslint-disable-next-line
class LocalStorageRulesetRepository implements IRulesetRepository {
    private setInnerStore = (formId: string, store?: any) => {
        if (!store) {
            store = {};
        }

        localStorage.setItem(formId, JSON.stringify(store));
    };

    private getInnerStore = (formId: string) => {
        const store = JSON.parse(localStorage.getItem(formId) as any);

        if (!store) {
            this.setInnerStore(formId);

            return {};
        }

        return store;
    };

    private setRulesetInStorage = (ruleset: Ruleset, formId: string, rulesetId?: string) => {
        const store = this.getInnerStore(formId);
        const id = rulesetId || crypto.randomUUID();

        store[id] = { ...ruleset, id: id };

        this.setInnerStore(formId, store);
    };

    private getRulesetFromStorage = (formId: string, rulesetId: string): Ruleset => {
        const store = this.getInnerStore(formId);

        return { ...store[rulesetId], id: rulesetId };
    };

    private deleteRulesetFromStorage = (formId: string, rulesetId: string) => {
        const store = this.getInnerStore(formId);

        delete store[rulesetId];

        this.setInnerStore(formId, store);
    };

    // TODO: If required, this needs to be updated to take in TableAction[]
    // @ts-ignore
    public createRuleset = async (ruleset: Ruleset, formId: string): Promise<Response> => {
        this.setRulesetInStorage(ruleset, formId);

        return {
            data: null,
            status: 201,
        };
    };

    public getRuleset = async (formId: string, rulesetId: string): Promise<Response> => {
        const ruleset = this.getRulesetFromStorage(formId, rulesetId);

        return {
            data: ruleset,
            status: 200,
        };
    };

    public getRulesets = async (formId: string): Promise<Response> => {
        const store = this.getInnerStore(formId);
        const rulesets = Object.values(store);

        return {
            data: rulesets,
            status: 200,
        };
    };

    // TODO: If required, this needs to be updated to take in TableAction
    // @ts-ignore
    public updateRuleset = async (ruleset: Ruleset, formId: string, rulesetId: string): Promise<Response> => {
        this.setRulesetInStorage(ruleset, formId, rulesetId);

        return {
            data: null,
            status: 201,
        };
    };

    public deleteRuleset = async (formId: string, rulesetId: string): Promise<Response> => {
        this.deleteRulesetFromStorage(formId, rulesetId);

        return {
            data: null,
            status: 200,
        };
    };
}

class HttpRulesetRepository implements IRulesetRepository {
    private httpService: IHttpService;

    constructor(httpService: IHttpService) {
        this.httpService = httpService;
    }

    createRuleset = async (tableActions: TableAction[], formId: string): Promise<Response> => {
        return this.httpService.post(`forms/${formId}/rulesets`, tableActions);
    };

    getRuleset = async (formId: string, rulesetId: string): Promise<Response> => {
        return this.httpService.get(`forms/${formId}/rulesets/${rulesetId}`);
    };

    getRulesets = async (formId: string): Promise<Response> => {
        return this.httpService.get(`forms/${formId}/rulesets`);
    };

    updateRulesets = async (tableActions: TableAction[], formId: string): Promise<Response> => {
        return this.httpService.put(`forms/${formId}/rulesets`, tableActions);
    };

    deleteRuleset = async (formId: string, rulesetId: string): Promise<Response> => {
        return this.httpService.delete(`forms/${formId}/rulesets/${rulesetId}`);
    };
}

// eslint-disable-next-line
const slowDown = async () => {
    await new Promise((res, rej) => {
        setTimeout(() => res(true), 500);
    });
};

// 'getRuleset' and 'deleteRuleset' is not currently used. If needed, rulesetId might pose a problem since the BE does
// not consider ruleset as a separate entity, and thus does not create dedicated id's for them. The 'id' property is
// artificially used on the FE by assigning the ruleset's name to it upon receiving them from the BE. So in order to
// use them, make sure that the BE actually accepts them, and if so, that the correct rulesetId is sent through.
const RulesetService = (httpService: IHttpService): IRulesetService => {
    const repository = new HttpRulesetRepository(httpService);

    const createRuleset = async (ruleset: Ruleset, formId: string): Promise<any> => {
        const tableActions = getTableActionsFromRulesets([ruleset]);
        await repository.createRuleset(tableActions, formId);
        return;
    };

    // Not currently used.
    const getRuleset = async (formId: string, rulesetId: string): Promise<any> => {
        const res = await repository.getRuleset(formId, rulesetId);
        const tableAction = res?.data;

        const ruleset = getRulesetsFromTableActions([tableAction])[0];

        return ruleset;
    };

    const getRulesets = async (formId: string): Promise<any> => {
        const newRuleset: Ruleset = {
            name: 'New',
            identificationRules: {
                searchMode: 'Column names',
                booleanOperator: 'All',
                conditions: [{ operand: 'Column name 1', operator: 'Equals', value: 'Some value' }],
            },
            columnRules: [],
            rowRules: [],
            outputHeaderPosition: 'Top row',
            shouldCreateHeadersOnNoMatch: false,
        };

        const res = await repository.getRulesets(formId);
        const tableActions = res?.data;

        const rulesets = getRulesetsFromTableActions(tableActions);
        const rulesetsCleaned = rulesets.filter((ruleset) => ruleset?.name !== 'New');

        rulesetsCleaned.unshift(newRuleset);

        return rulesetsCleaned;
    };

    const updateRulesets = async (rulesets: Ruleset[], formId: string): Promise<any> => {
        const tableActions = getTableActionsFromRulesets(rulesets);
        await repository.updateRulesets(tableActions, formId);
        return;
    };

    // Not currently used.
    const deleteRuleset = async (formId: string, rulesetId: string): Promise<any> => {
        await repository.deleteRuleset(formId, rulesetId);
        return;
    };

    return {
        createRuleset: createRuleset,
        getRuleset: getRuleset,
        getRulesets: getRulesets,
        updateRulesets: updateRulesets,
        deleteRuleset: deleteRuleset,
    };
};

export const rulesetService = RulesetService(httpService);
