import * as pdfjsLib from 'pdfjs-dist';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { DocumentStatus, IDocument, IRawDocument } from 'pages/label/store/documents/documentsTypes';
import { loadCanvasToBlob } from 'pages/label/utils/index';
import { ICanvas } from 'pages/label/store/canvas/canvas';
import { IDocumentLoader } from './index';
import { httpService } from 'core/services/httpService';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export class PdfLoader implements IDocumentLoader {
    private readonly PDF_SCALE = 2;

    private document: IRawDocument;
    private pdf;

    constructor(document: IRawDocument) {
        this.document = document;
    }

    convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    public async setup(): Promise<void> {
        if (this.document.url) {
            this.pdf = await pdfjsLib.getDocument({
                url: this.document.url,
                httpHeaders: {
                    Authorization: `Bearer ${await httpService.getAccessToken()}`,
                },
                cMapUrl: '/fonts/pdfjs-dist/cmaps/',
                cMapPacked: true,
            }).promise;
        } else if (this.document.base64) {
            var pdfAsArray = this.convertDataURIToBinary(this.document.base64);
            this.pdf = await pdfjsLib.getDocument(pdfAsArray).promise;
        }
    }

    public async loadDocumentMeta(): Promise<IDocument> {
        const firstPage = await this.loadDocumentPage(1, 1);
        return {
            ...this.document,
            thumbnail: firstPage.imageUrl,
            numPages: this.pdf.numPages,
            currentPage: 1,
            states: { loadingStatus: DocumentStatus.Loaded },
        };
    }

    public async loadDocumentPage(pageNumber: number, scale = this.PDF_SCALE): Promise<ICanvas> {
        const page = await this.pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale });

        // Prepare canvas using PDF page dimensions.
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context.
        const renderContext = {
            canvasContext: context,
            viewport,
        };
        await page.render(renderContext).promise;

        const blob = await loadCanvasToBlob(canvas);
        return { imageUrl: window.URL.createObjectURL(blob), width: canvas.width, height: canvas.height, angle: 0 };
    }
}
