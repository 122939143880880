import React from 'react';
import { Outlet } from 'react-router-dom';
import { Card } from 'antd';

import styles from './model-settings-wrapper.module.scss';

export function ModelSettingsWrapper() {
    return (
        <div className={styles['model-settings']}>
            <Card>
                <Outlet />
            </Card>
        </div>
    );
}
