export class LocalStorageService {
    private static _setItem(key, item) {
        localStorage.setItem(key, JSON.stringify(item));
    }

    private static _getItem(key) {
        return JSON.parse(localStorage.getItem(key) || 'null');
    }

    public static setItem(key, item) {
        LocalStorageService._setItem(key, item);
    }

    public static getItem(key, defaultValue) {
        return LocalStorageService._getItem(key) || defaultValue;
    }
}
