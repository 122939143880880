import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
    ADMIN_PAGE,
    ANALYZE_PAGE,
    DOCUMENT_CREATE,
    DOCUMENT_EDIT,
    FORM_CREATE,
    FORM_EDIT_ID,
    FORM_TYPE_CREATE,
    FORM_TYPE_EDIT,
    FORM_TYPE_VERSION_CREATE,
    FORM_TYPE_VERSION_EDIT,
    FORM_TYPE_VERSION_PAGE_CREATE,
    FORM_TYPE_VERSION_PAGE_EDIT,
    HOME_PAGE,
    LABEL_DOCUMENT,
    LABEL_PAGE,
    LOGIN_PAGE,
    CALLBACK_PAGE,
    REBUILD_LOGS,
    TRAINING_LOGS,
    FORM_MODEL_SETTINGS,
    FORM_TYPE_VERSION_PAGE_MODEL_SETTINGS,
    DOCUMENT_MODEL_SETTINGS,
} from '../../data/consts/routes';
import { AppLayout } from '../layout';
import { FormCreate } from '../../pages/form-crud/components/FormCreate';
import { FormEdit } from '../../pages/form-crud/components/FormEdit';
import { FormTypeCreate } from '../../pages/form-crud/components/FormTypeCreate';
import { FormTypeEdit } from '../../pages/form-crud/components/FormTypeEdit';
import { FormTypeVersionCreate } from '../../pages/form-crud/components/FormTypeVersionCreate';
import { FormTypeVersionEdit } from '../../pages/form-crud/components/FormTypeVersionEdit';
import { FormTypeVersionPageCreate } from '../../pages/form-crud/components/FormTypeVersionPageCreate';
import { FormTypeVersionPageEdit } from '../../pages/form-crud/components/FormTypeVersionPageEdit';
import { formTypes } from '../../pages/form-crud/data/data';

import LabelPage from '../../pages/label';
import HomePage from '../../pages/home';
import Analyze from '../../pages/analyze';
import Admin from '../../pages/admin';
import Login from '../../pages/login';
import Callback from '../../pages/callback';
import FormCrud from '../../pages/form-crud';
import RebuildLogs from '../../pages/rebuild-logs';
import TrainingLogs from '../../pages/training-logs';

import {
    ModelSettingsWrapper,
    FormModelSettings,
    PageModelSettings,
    DocumentModelSettings,
} from 'pages/model-settings';

export function MainRouter() {
    return (
        <Routes>
            <Route path={LOGIN_PAGE} element={<Login />} />
            <Route path={CALLBACK_PAGE} element={<Callback />} />
            <Route element={<AppLayout />}>
                <Route path={HOME_PAGE} index={true} element={<HomePage />} />
                <Route path={LABEL_PAGE} element={<LabelPage />} />
                <Route path={LABEL_DOCUMENT} element={<LabelPage />} />
                <Route path={ANALYZE_PAGE} element={<Analyze />} />
                <Route path={REBUILD_LOGS} element={<RebuildLogs />} />
                <Route path={TRAINING_LOGS} element={<TrainingLogs />} />
                <Route path={ADMIN_PAGE} element={<Admin />} />
                <Route element={<FormCrud />}>
                    <Route path={DOCUMENT_CREATE} element={<FormCreate type={formTypes.document} />} />
                    <Route path={DOCUMENT_EDIT} element={<FormEdit type={formTypes.document} />} />
                    <Route path={FORM_CREATE} element={<FormCreate type={formTypes.pagedForm} />} />
                    <Route path={FORM_EDIT_ID} element={<FormEdit type={formTypes.pagedForm} />} />
                    <Route path={FORM_TYPE_CREATE} element={<FormTypeCreate />} />
                    <Route path={FORM_TYPE_EDIT} element={<FormTypeEdit />} />
                    <Route path={FORM_TYPE_VERSION_CREATE} element={<FormTypeVersionCreate />} />
                    <Route path={FORM_TYPE_VERSION_EDIT} element={<FormTypeVersionEdit />} />
                    <Route path={FORM_TYPE_VERSION_PAGE_CREATE} element={<FormTypeVersionPageCreate />} />
                    <Route path={FORM_TYPE_VERSION_PAGE_EDIT} element={<FormTypeVersionPageEdit />} />
                </Route>
                <Route element={<ModelSettingsWrapper />}>
                    <Route path={FORM_MODEL_SETTINGS} element={<FormModelSettings />} />
                    <Route path={FORM_TYPE_VERSION_PAGE_MODEL_SETTINGS} element={<PageModelSettings />} />
                    <Route path={DOCUMENT_MODEL_SETTINGS} element={<DocumentModelSettings />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
        </Routes>
    );
}
