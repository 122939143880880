import React from 'react';

type Props = {
    className?: string;
    onClick?: () => void;
};

export function Logo({ className, onClick = () => {} }: Props) {
    return (
        <svg
            width="181"
            height="32"
            viewBox="0 0 181 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path
                d="M29.1068 0V5.55238H8.18727C6.73683 5.55238 5.55828 6.72281 5.53485 8.17558L5.53448 8.21969L5.53446 17.6291L29.1068 17.6291V23.1815H0.0124092V8.21969C0.0124092 3.72548 3.59958 0.0736876 8.05208 0.0011012L8.18727 0H29.1068Z"
                fill="#0085E5"
            />
            <path
                d="M9.43276 32V26.4476L30.3523 26.4476C31.8027 26.4476 32.9812 25.2772 33.0047 23.8244L33.005 23.7803L33.0051 14.3709L9.43277 14.3709V8.81848L38.5271 8.81848V23.7803C38.5271 28.2745 34.9399 31.9263 30.4874 31.9989L30.3523 32L9.43276 32Z"
                fill="#0085E5"
            />
            <path d="M38.5463 0.00930595H33.0784V5.50725H38.5463V0.00930595Z" fill="#0085E5" />
            <path d="M5.46795 26.4647H0V31.9627H5.46795V26.4647Z" fill="#0085E5" />
            <path
                d="M60.8546 26.4578C63.213 26.4578 65.1389 25.853 66.6182 24.6433C68.0974 23.4337 68.8371 21.9568 68.8371 20.1986C68.8371 18.6795 68.3765 17.5121 67.4555 16.6962C66.5344 15.8804 65.1947 15.2053 63.4503 14.6708L58.4822 13.0532C57.2122 12.6735 56.5703 12.0405 56.5703 11.1544C56.5703 10.5496 56.8633 10.0291 57.4634 9.60717C58.0635 9.1852 58.8311 8.97421 59.78 8.97421C60.8825 8.97421 61.8733 9.22739 62.7525 9.73376C63.6317 10.2401 64.2876 10.9012 64.7063 11.7311L68.3486 10.6621C67.7765 9.1852 66.7158 7.96149 65.1668 6.97689C63.6177 5.9923 61.8036 5.5 59.7242 5.5C57.6728 5.5 55.9562 6.06263 54.5886 7.18788C53.221 8.31313 52.5372 9.67749 52.5511 11.2669C52.5372 12.5188 52.9419 13.5737 53.7653 14.4457C54.5886 15.3178 55.6911 15.9789 57.0866 16.4149L62.1943 18.0325C63.1572 18.3279 63.841 18.6795 64.2457 19.0733C64.6504 19.4672 64.8458 19.9313 64.8458 20.4658C64.8458 21.1551 64.4969 21.7458 63.7852 22.2522C63.0735 22.7585 62.1524 23.0117 61.022 23.0117C59.6405 23.0117 58.4403 22.6601 57.4355 21.9568C56.4307 21.2535 55.719 20.3533 55.3003 19.2421L51.5463 20.3814C52.1604 22.0975 53.2768 23.5462 54.8956 24.7277C56.5145 25.9092 58.4822 26.5 60.7848 26.5L60.8546 26.4578Z"
                fill="white"
            />
            <path
                d="M77.0411 26.1154H81.0126V18.7228L88.5653 5.96154H84.1758L79.0338 14.9141L73.8778 5.96154H69.4465L77.0411 18.7228V26.1154Z"
                fill="white"
            />
            <path
                d="M95.6114 26.1154V13.0168L105.678 26.1154H108.903V5.96154H104.98V19.032L94.9273 5.96154H91.6882V26.1154H95.6114Z"
                fill="white"
            />
            <path
                d="M128.357 26.1154H132.439L124.762 5.96154H119.676L112.026 26.1154H116.081L117.739 21.7445H126.726L128.357 26.1154ZM119.021 18.259L122.212 9.74214L125.417 18.259H119.021Z"
                fill="white"
            />
            <path d="M142.4 26.1154V9.53133H149.121V5.96154H131.754V9.53133H138.474V26.1154H142.4Z" fill="white" />
            <path d="M156.509 26.1154V5.96154H152.472V26.1154H156.509Z" fill="white" />
            <path
                d="M171.075 26.4859C173.543 26.4859 175.635 25.8521 177.337 24.5704C179.038 23.2887 180.14 21.7676 180.628 20.007L176.835 19.0211C176.486 20.0775 175.789 20.9648 174.743 21.6831C173.697 22.4014 172.442 22.7676 170.991 22.7676C169.081 22.7676 167.533 22.1056 166.334 20.7676C165.134 19.4296 164.535 17.8239 164.535 15.9366C164.535 14.0634 165.134 12.4718 166.32 11.162C167.505 9.85211 169.067 9.20423 170.991 9.20423C172.442 9.20423 173.683 9.57042 174.729 10.3028C175.775 11.0352 176.472 11.9366 176.821 12.993L180.656 12.0493C180.14 10.2746 179.024 8.73944 177.323 7.44366C175.621 6.14789 173.502 5.5 170.978 5.5C168.007 5.5 165.525 6.5 163.531 8.5C161.537 10.5 160.547 12.993 160.547 15.9648C160.547 18.9648 161.537 21.4718 163.531 23.4859C165.525 25.5 168.021 26.5 171.033 26.5L171.075 26.4859Z"
                fill="white"
            />
        </svg>
    );
}
