import React from 'react';
import { Button, Result, Skeleton, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { ReloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import LogsTable from './components/LogsTable';
import { TRAINING_LOGS } from '../../data/consts/apiQuery';
import { AsyncRender } from '../../components/common/async-render';
import { logsService } from './data/logsService';
import { Drawer } from 'components/svg/drawer';

import styles from './rebuild-logs.module.scss';

export default function TrainingLogs() {
    const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = useParams();

    const queryData = useQuery({
        queryKey: [TRAINING_LOGS, formTypeVersionPageId],
        queryFn: () =>
            logsService.getList(
                formId as string,
                formTypeId as string,
                formTypeVersionId as string,
                formTypeVersionPageId as string
            ),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 0,
    });

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <AsyncRender
                    queryData={queryData}
                    checkIsFetching={true}
                    RenderSuccess={(data) => (
                        <>
                            <div className={styles.top}>
                                <Typography className={styles.title}>Training logs</Typography>
                                <Button size={'middle'} onClick={() => queryData.refetch()}>
                                    Refresh
                                </Button>
                            </div>

                            {data?.data?.length ? (
                                <LogsTable data={data?.data} />
                            ) : (
                                <div className={styles.empty}>
                                    <Drawer className={styles.drawer} />
                                    <Typography className={styles['empty-text']}>No data</Typography>
                                </div>
                            )}
                        </>
                    )}
                    renderLoading={
                        <>
                            <Skeleton active />
                            <Skeleton active className="mt-8" />
                        </>
                    }
                    renderError={
                        <Result
                            title="Failed to load logs"
                            subTitle="Please click on `Reload` to try again."
                            status="error"
                            extra={[
                                <Button
                                    danger
                                    type="primary"
                                    key="reload"
                                    icon={<ReloadOutlined rev="true" />}
                                    onClick={() => queryData.refetch()}
                                >
                                    Reload
                                </Button>,
                            ]}
                        />
                    }
                />
            </div>
        </div>
    );
}
