import React from 'react';

type Props = {
    className?: string;
    onClick?: () => void;
};

export function LogoNoText({ className, onClick = () => {} }: Props) {
    return (
        <svg
            width="41"
            height="34"
            viewBox="0 0 41 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path
                d="M30.9259 0V5.8994H8.69891C7.15782 5.8994 5.90561 7.14299 5.88072 8.68656L5.88033 8.73342L5.8803 18.731L30.9259 18.731V24.6304H0.0131226V8.73342C0.0131226 3.95832 3.8245 0.0782931 8.55527 0.00117003L8.69891 0H30.9259Z"
                fill="#0085E5"
            />
            <path
                d="M10.0223 34V28.1006L32.2493 28.1006C33.7904 28.1006 35.0426 26.857 35.0675 25.3134L35.0679 25.2666L35.0679 15.269L10.0223 15.269V9.36964L40.9351 9.36964V25.2666C40.9351 30.0417 37.1237 33.9217 32.3929 33.9988L32.2493 34L10.0223 34Z"
                fill="#0085E5"
            />
            <path d="M40.9555 0.0098877H35.1458V5.85145H40.9555V0.0098877Z" fill="#0085E5" />
            <path d="M5.8097 28.1188H0V33.9603H5.8097V28.1188Z" fill="#0085E5" />
        </svg>
    );
}
