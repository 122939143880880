import { useAuth0 } from '@auth0/auth0-react';

export const useEmail = (): { userEmail: string } => {
    const { user } = useAuth0();

    // This namespace can be kept constant throughout all envs since auth0 does not check against the domain.
    const nameSpacedCustomClaim = 'https://ocr.synatic.com';
    const userEmail = user?.[`${nameSpacedCustomClaim}/email`] || user?.email;

    return {
        userEmail: userEmail,
    };
};
