import { httpService, IHttpService } from '../../../core/services/httpService';

export interface IFormService {
    getList: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string
    ) => Promise<any>;
}

export const LogsService = (httpService: IHttpService): IFormService => {
    const baseUrl = '/forms';

    return {
        getList: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string
        ) => {
            const res = await httpService.get(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/training-logs?$sort=-_id`
            );
            return res?.data.map((item) => ({
                ...item,
                logStartDate: new Date(item?.logStartDate || Date.now()).toLocaleString(),
                logCompletedDate: new Date(item?.logCompletedDate || Date.now()).toLocaleString(),
            }));
        },
    };
};

export const logsService = LogsService(httpService);
