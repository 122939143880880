import { RegExpObj } from '@synatic/ocr-table-actions';

export const isValidName = (text) => /^[a-zA-Z0-9 ]+$/.test(text);

export const isOnlyWhiteSpace = (text) => /^\s+$/.test(text);

export const isRegExpObj = (obj: any): obj is RegExpObj => {
    return (
        typeof obj?.['$regularExpression']?.pattern === 'string' &&
        typeof obj?.['$regularExpression']?.options === 'string'
    );
};

export const hasWhiteSpaceAtEdges = (text) => /^\s+/.test(text) || /\s+$/.test(text);

export const getRegExpObjFromString = (value: any): RegExpObj => {
    const str = String(value);

    return {
        $regularExpression: {
            pattern: str,
            options: 'gi',
        },
    };
};

export const getRegExpObjPattern = (value: RegExpObj): string => {
    return String(value?.['$regularExpression']?.pattern);
};

export const isCommaDelimitedText = (text) => /^(?=\S)[^,].+[^,](?<=\S)$/gi.test(text);
