import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Stack, Text } from '@fluentui/react';
import Split from 'react-split';
import { Button, Tabs } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { SingleFileUpload } from '../../components/singleFileUpload/singleFileUpload';
import MessageModal from 'pages/label/components/messageModal/messageModal';
import {
    addDocuments,
    changeDocumentData,
    deleteDocument,
    setCurrentDocument,
    setDocumentAnalyzingStatus,
    setDocumentLabelingStatus,
} from 'pages/label/store/documents/documents';
import type { Tab } from 'rc-tabs/lib/interface';
import {
    setFields,
    setDefinitions,
    setLabelsById,
    clearLabelError,
    deleteLabelByName,
} from 'pages/label/store/customModel/customModel';
import { setDocumentPrediction } from 'pages/label/store/predictions/predictions';
import { addLoadingOverlay, removeLoadingOverlayByName } from 'pages/label/store/portal/portal';
import { ApplicationState } from 'pages/label/store';
import { DocumentStatus, IDocument, IRawDocument } from 'pages/label/store/documents/documentsTypes';
import { constants } from 'data/consts/constants';
import { getPixelWidthFromPercent } from 'pages/label/utils';
import { RouterProps, SplitPaneSizes } from 'pages/label/models';
import DocumentGallery from 'pages/label/containers/documentGallery/documentGallery';
import LabelPane from 'pages/label/containers/labelPane/labelPane';
import LabelCanvas from 'pages/label/containers/labelCanvas/labelCanvas';
import { StorageProvider, IStorageProviderError } from 'pages/label/providers/storageProvider';
import { getDocumentType } from 'pages/label/utils/documentLoader';
import urljoin from 'url-join';
import { withRouter } from '../../components/withRouter/withRouter';
import { formFilesService } from '../../services/formFilesService';
import { FieldFromDoc, IFormFieldsRes, IRawFormFile } from '../../models/customModels';
import { FileUpload, getBase64 } from '../../components/fileUpload/fileUpload';
import { isLabelFieldWithCorrectFormat } from '../../utils/customModel/schemaValidation/fieldsValidator';
import type { DocumentField, AnalyzeResult, DocumentObjectField, DocumentArrayField } from '@azure/ai-form-recognizer';
import { Alert } from 'antd';
import { getApiUrl, file2Base64 } from 'helpers';

import './customModelLabelPage.scss';

const confidenceTableFieldWorker = new Worker(new URL('./tableWorker.ts', import.meta.url), { type: 'module' });

interface ICustomModelLabelPageState {
    analyzeFile: UploadFile | null;
    isUploadModalOpen: boolean;
    isLoadingFields: boolean;
    isLoadingExtract: boolean;
    isLoadingAnalyze: boolean;
    isLoadingLabels: boolean;
    isDocumentExtracted: boolean;
    isInvalidFieldsFormatModalOpen: boolean;
    isTablePaneOpen: boolean;
    errorMessage: IStorageProviderError | undefined;
    splitPaneSizes: SplitPaneSizes;
    showEmptyFolderMessage: boolean;
}

const loadingOverlayName = 'customModelLabelPage';

export class CustomModelLabelPage extends React.PureComponent<
    ConnectedProps<typeof connector> & RouterProps & { isReadonly: boolean; formId?: string },
    ICustomModelLabelPageState
> {
    private storageProvider: StorageProvider;
    private mounted: boolean = true;

    constructor(props) {
        super(props);
        this.storageProvider = new StorageProvider();
        this.state = {
            analyzeFile: null,
            isUploadModalOpen: false,
            isLoadingFields: true,
            isLoadingLabels: true,
            isLoadingExtract: false,
            isLoadingAnalyze: false,
            isDocumentExtracted: true,
            isInvalidFieldsFormatModalOpen: false,
            isTablePaneOpen: false,
            errorMessage: undefined,
            splitPaneSizes: constants.defaultSplitPaneSizes,
            showEmptyFolderMessage: false,
        };
    }

    public componentDidMount() {
        !this.props.isReadonly && this.initLabelPage();
    }

    public componentDidUpdate(prevProps) {
        const {
            isReadonly,
            currentDocument,
            labels,
            predictions,
            setDocumentLabelingStatus,
            removeLoadingOverlayByName,
            formId,
        } = this.props;

        const { isLoadingLabels } = this.state;

        if (!isReadonly) {
            if (
                currentDocument &&
                prevProps.currentDocument?.id !== currentDocument.id &&
                !predictions[currentDocument.id!]
            ) {
                if (currentDocument.states.analyzingStatus !== DocumentStatus.Analyzing) {
                    this.getAndSetOcr();
                }
            }

            if (
                (currentDocument &&
                    prevProps.currentDocument?.id !== currentDocument.id &&
                    !labels[currentDocument.id!]) ||
                (prevProps.fields.length === 0 && this.props.fields.length > 0 && currentDocument)
            ) {
                this.getAndSetLabels();
            }

            if (
                currentDocument &&
                prevProps.labels[currentDocument.id!]?.length === 0 &&
                labels[currentDocument.id!]?.length !== 0
            ) {
                // Set labeling status to labeled when labels are added.
                setDocumentLabelingStatus({ id: currentDocument.id, status: DocumentStatus.Labeled });
            }

            if (
                currentDocument &&
                prevProps.labels[currentDocument.id!]?.length !== 0 &&
                labels[currentDocument.id!]?.length === 0
            ) {
                // Set labeling status to undefined when labels is empty.
                setDocumentLabelingStatus({ id: currentDocument.id, status: undefined });
            }

            if (currentDocument && !isLoadingLabels) {
                // Remove current loading overlay  when the first document is loaded with labels.
                removeLoadingOverlayByName(loadingOverlayName);
            }
        }

        if (isReadonly) {
            if (prevProps.formId !== formId) {
                // Resetting fields and the current document ocr extraction on model selection.
                this.props.setFields([]);
                // @ts-ignore
                this.props.setCurrentDocument({
                    ...this.props.currentDocument,
                    ocrExtractResponse: undefined,
                });
            }
        }
    }

    public componentWillUnmount() {
        this.props.addDocuments([]);
        this.props.setFields([]);
        this.props.setCurrentDocument();
        this.mounted = false;
        this.props.removeLoadingOverlayByName(loadingOverlayName);
    }

    private initLabelPage = async () => {
        const { addLoadingOverlay } = this.props;
        addLoadingOverlay({
            name: loadingOverlayName,
            message: 'Loading documents...',
        });
        await this.getAndSetDocuments();
        await this.getAndSetFields();
        this.props.removeLoadingOverlayByName(loadingOverlayName);
    };

    private async handleAnalyzeFileChange(file: UploadFile | null) {
        if (file) {
            const { addDocuments, setCurrentDocument } = this.props;
            const resultFile2Base64 = await file2Base64(file as RcFile);
            getBase64(file as RcFile, (result) => {
                addDocuments([]);
                setCurrentDocument();

                setTimeout(() => {
                    addDocuments([
                        {
                            id: file.uid,
                            type: file.type as string,
                            name: file.name,
                            base64: result,
                            file2Base64: resultFile2Base64,
                        },
                    ]);
                }, 500);
            });
        }
        this.props.setFields([]);
        this.setState({ analyzeFile: file });
    }

    private composeFileUrl = (fileId: string) => {
        if (this.props.router.params.pageType === 'document') {
            return urljoin(`${getApiUrl()}/forms/${this.props.router.params.formId}/files/${fileId}/download`);
        } else {
            return urljoin(
                `${getApiUrl()}/forms/${this.props.router.params.formId}/types/${
                    this.props.router.params.formTypeId
                }/versions/${this.props.router.params.formTypeVersionId}/pages/${
                    this.props.router.params.formTypeVersionPageId
                }/files/${fileId}/download`
            );
        }
    };

    private makeRawDocument = (file: any): IRawDocument => {
        return {
            id: file?._id,
            ocrExtractResponse: file?.ocrExtractResponse,
            name: file.filename,
            type: getDocumentType(file.extension),
            url: this.composeFileUrl(file._id),
        };
    };

    private setUploadedDocuments = (filePaths: IRawFormFile[]) => {
        const documents: IRawDocument[] = filePaths.map(this.makeRawDocument);
        const chunkSize = 3;
        for (let i = 0, j = documents.length; i < j; i += chunkSize) {
            const documentChunk = documents.slice(i, i + chunkSize);
            this.props.addDocuments(documentChunk);
        }
    };

    private getAndSetDocuments = async () => {
        try {
            const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = this.props.router.params;
            let filePaths: IRawFormFile[] = [];

            if (this.props.router.params.pageType === 'document') {
                filePaths = await formFilesService.getDocumentFiles(formId);
            } else {
                filePaths = await formFilesService.getFormPageFiles(
                    formId,
                    formTypeId,
                    formTypeVersionId,
                    formTypeVersionPageId
                );
            }

            const { addDocuments, setDocumentAnalyzingStatus, setDocumentLabelingStatus } = this.props;
            const documents: IRawDocument[] = filePaths.map(this.makeRawDocument);

            const showEmptyFolderMessage = documents.length === 0;
            if (!showEmptyFolderMessage) {
                const chunkSize = 3;
                for (let i = 0, j = documents.length; i < j; i += chunkSize) {
                    const documentChunk = documents.slice(i, i + chunkSize);

                    if (this.mounted) {
                        await addDocuments(documentChunk);
                        documentChunk.forEach((document) => {
                            const { id } = document;
                            const documentWithFullData = filePaths.find((file) => file._id === id);

                            if (documentWithFullData?.ocrExtractResponse) {
                                setDocumentAnalyzingStatus({ id, status: DocumentStatus.Analyzed });
                            }
                            if (documentWithFullData?.labels?.labels?.length) {
                                setDocumentLabelingStatus({ id, status: DocumentStatus.Labeled });
                            }
                        });
                    }
                }
            }
            this.setState({
                showEmptyFolderMessage,
            });
        } catch (err) {
            this.setState({ errorMessage: err as IStorageProviderError });
        }
    };

    private getAndSetFields = async () => {
        this.setState({ isLoadingFields: true });
        try {
            const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = this.props.router.params;
            const { setFields, setDefinitions } = this.props;
            let rawFields: IFormFieldsRes = { fields: [], definitions: {} };

            if (this.props.router.params.pageType === 'document') {
                rawFields = await formFilesService.getDocumentFields(formId);
            } else {
                rawFields = await formFilesService.getPageFields(
                    formId,
                    formTypeId,
                    formTypeVersionId,
                    formTypeVersionPageId
                );
            }

            if (rawFields?.fields?.length) {
                if (!isLabelFieldWithCorrectFormat(rawFields)) {
                    this.setState({ isInvalidFieldsFormatModalOpen: true });
                } else {
                    const { fields, definitions } = rawFields;
                    setDefinitions(definitions);
                    setFields(fields);
                }
            }
        } catch (err: any) {
            this.setState({ errorMessage: err as IStorageProviderError });
        } finally {
            this.setState({ isLoadingFields: false });
        }
    };

    private getAndSetLabels = async () => {
        this.setState({ isLoadingLabels: true });
        try {
            const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = this.props.router.params;
            const { currentDocument, setLabelsById } = this.props;

            let rawLabels = { labels: [] };

            if (this.props.router.params.pageType === 'document') {
                rawLabels = await formFilesService.getDocumentLabels(formId, currentDocument?.id!);
            } else {
                rawLabels = await formFilesService.getPageLabels(
                    currentDocument?.id!,
                    formId,
                    formTypeId,
                    formTypeVersionId,
                    formTypeVersionPageId
                );
            }

            setLabelsById({ id: currentDocument!.id, labels: rawLabels?.labels || [] });
        } catch (err) {
            this.setState({ errorMessage: err as IStorageProviderError });
        } finally {
            this.setState({ isLoadingLabels: false });
        }
    };

    private getAndSetOcr = async () => {
        const { currentDocument, setDocumentPrediction, changeDocumentData, setDocumentAnalyzingStatus } = this.props;
        if (!currentDocument) {
            return;
        }

        this.setState({ isDocumentExtracted: true });

        const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = this.props.router.params;
        if (currentDocument.ocrExtractResponse) {
            setDocumentPrediction({
                id: currentDocument.id,
                analyzeResponse: currentDocument.ocrExtractResponse,
            });
        } else {
            this.setState({ isLoadingExtract: true });
            if (this.props.router.params.pageType === 'document') {
                await formFilesService
                    .extractDocument(formId, currentDocument?.id!)
                    .then((analyzeResult) => {
                        setDocumentPrediction({
                            id: currentDocument.id,
                            analyzeResponse: analyzeResult,
                        });

                        changeDocumentData({
                            ...currentDocument,
                            ocrExtractResponse: analyzeResult,
                        });
                        setDocumentAnalyzingStatus({ id: currentDocument.id, status: DocumentStatus.Analyzed });
                        this.setState({ isLoadingExtract: false });
                    })
                    .catch((err) => {
                        this.setState({
                            errorMessage: err as IStorageProviderError,
                            isDocumentExtracted: false,
                            isLoadingExtract: false,
                        });
                    });
            } else {
                await formFilesService
                    .extractPage(currentDocument?.id!, formId, formTypeId, formTypeVersionId, formTypeVersionPageId)
                    .then((analyzeResult) => {
                        setDocumentPrediction({
                            id: currentDocument.id,
                            analyzeResponse: analyzeResult,
                        });

                        changeDocumentData({
                            ...currentDocument,
                            ocrExtractResponse: analyzeResult,
                        });
                        setDocumentAnalyzingStatus({ id: currentDocument.id, status: DocumentStatus.Analyzed });
                        this.setState({ isLoadingExtract: false });
                    })
                    .catch((err) => {
                        this.setState({
                            errorMessage: err as IStorageProviderError,
                            isDocumentExtracted: false,
                            isLoadingExtract: false,
                        });
                    });
            }
        }
    };

    private handleSplitPaneSizesChange = (sizes: number[]) => {
        const { splitPaneSizes } = this.state;
        const { isTablePaneOpen } = this.state;
        if (isTablePaneOpen) {
            this.setState({
                splitPaneSizes: { ...splitPaneSizes, labelTableSplitPaneSize: sizes },
            });
        } else {
            this.setState({
                splitPaneSizes: { ...splitPaneSizes, labelSplitPaneSize: sizes },
            });
        }
    };

    private deleteDocumentInStorage = async (doc: IDocument) => {
        const { name } = doc;
        const { deleteLabelByName } = this.props;
        const ocrFileName = `${name}${constants.ocrFileExtension}`;
        const labelFileName = `${name}${constants.labelFileExtension}`;

        deleteLabelByName(doc.name);
        try {
            await this.storageProvider.deleteFile(name);
            await this.storageProvider.deleteFile(ocrFileName, true);
            await this.storageProvider.deleteFile(labelFileName, true);
        } catch (err) {
            this.setState({ errorMessage: err as IStorageProviderError });
        }
    };

    private handleDeleteLabelFieldsJsonFile = async () => {
        try {
            await this.storageProvider.deleteFile(constants.fieldsFile, true);
        } catch (err) {
            this.setState({ errorMessage: err as IStorageProviderError });
        } finally {
            this.setState({ isInvalidFieldsFormatModalOpen: false });
        }
    };

    private handleCloseIncorrectLabelFieldsFormatModal = () => {
        this.setState({ isInvalidFieldsFormatModalOpen: false });
    };

    private handelSetIsTablePanelOpen = (state: boolean) => {
        this.setState({ isTablePaneOpen: state });
    };

    private handleCloseStorageErrorModal = () => {
        this.setState({ errorMessage: undefined });
    };

    private handleAnalyzeButtonClick = () => {
        const {
            formId,
            currentDocument,
            setDocumentAnalyzingStatus,
            setDocumentPrediction,
            changeDocumentData,
            setCurrentDocument,
        } = this.props;

        this.setState({ isLoadingAnalyze: true });

        formFilesService
            .analyzeFormFile(formId as string, currentDocument?.file2Base64 as string)
            .then(async (analyzeResult) => {
                const confidenceTableFields = await new Promise((res, rej) => {
                    confidenceTableFieldWorker.onmessage = (e) => {
                        res(e.data || {});
                    };

                    confidenceTableFieldWorker.onerror = (e) => {
                        rej(e);
                    };

                    confidenceTableFieldWorker.postMessage(analyzeResult);
                });

                return { analyzeResult, confidenceTableFields };
            })
            .then(({ analyzeResult, confidenceTableFields }) => {
                setDocumentPrediction({
                    id: currentDocument?.id,
                    analyzeResponse: { analyzeResult },
                    confidenceTableFields: confidenceTableFields,
                });

                changeDocumentData({
                    ...currentDocument,
                    ocrExtractResponse: { analyzeResult },
                });

                setDocumentAnalyzingStatus({ id: currentDocument?.id, status: DocumentStatus.Analyzed });
                // @ts-ignore
                setCurrentDocument({ ...currentDocument, ocrExtractResponse: { analyzeResult } });

                if (!(formId as string).startsWith('prebuilt-')) {
                    const fields = Object.keys(analyzeResult?.documents?.[0]?.fields).map((fieldKey) => {
                        return {
                            fieldKey,
                            fieldType: analyzeResult?.documents?.[0]?.fields?.[fieldKey]?.kind,
                            fieldFormat: 'not-specified',
                            confidence: analyzeResult?.documents?.[0]?.fields?.[fieldKey]?.confidence,
                            value: analyzeResult?.documents?.[0]?.fields?.[fieldKey]?.value,
                        };
                    });
                    this.props.setFields(fields);
                } else {
                    const docs = (analyzeResult as AnalyzeResult).documents;
                    if (!docs || docs.length === 0) {
                        return;
                    }
                    const firstDoc = docs[0];
                    //todo RK what if multiple docs
                    if (firstDoc.fields) {
                        this.props.setFields(mapResultToFields(firstDoc.fields));
                    }
                }
            })
            .catch((err) => {
                this.setState({
                    errorMessage: err as IStorageProviderError,
                });
            })
            .finally(() => {
                this.setState({ isLoadingAnalyze: false });
            });
    };

    private handleJsonDownload = (data) => {
        const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = 'data.json';

        link.click();
    };

    render() {
        const { clearLabelError, labelError } = this.props;
        const { isInvalidFieldsFormatModalOpen, isTablePaneOpen, splitPaneSizes, errorMessage } = this.state;
        const splitSize = isTablePaneOpen ? splitPaneSizes.labelTableSplitPaneSize : splitPaneSizes.labelSplitPaneSize;
        const sideMenuItems: Tab[] = [];
        if (this.props.fields.length) {
            sideMenuItems.push({
                key: `${this.props.formId} Values`,
                label: `Values`,
                children: (
                    <LabelPane
                        isReadonly={this.props.isReadonly}
                        isTablePaneOpen={isTablePaneOpen}
                        setIsTablePaneOpen={this.props.isReadonly ? () => {} : this.handelSetIsTablePanelOpen}
                        predictions={{
                            [this.props.currentDocument?.id!]: this.props.predictions[this.props.currentDocument?.id!],
                        }}
                    />
                ),
            });
        }
        if (this.props.currentDocument?.ocrExtractResponse) {
            sideMenuItems.push({
                key: `${this.props.formId} Result`,
                label: `Result`,
                children: (
                    <div className="result-view-box">
                        <Alert
                            type={'info'}
                            message={
                                'Please click on the `Download` button above in order to view the entire analysis result'
                            }
                        />
                    </div>
                ),
            });
        }
        return (
            <Stack className="custom-doc-label-page" grow={1}>
                <Stack className="label-page-main" horizontal grow={1}>
                    <Stack className="label-page-gallery">
                        {this.props.isReadonly ? (
                            <SingleFileUpload
                                disabled={!this.props.formId}
                                analyzeFile={this.state.analyzeFile}
                                setFile={(file) => this.handleAnalyzeFileChange(file)}
                            />
                        ) : (
                            <Button
                                className={'upload-button'}
                                size={'middle'}
                                icon={<UploadOutlined rev="" />}
                                onClick={() => this.setState({ isUploadModalOpen: true })}
                            >
                                Upload file
                            </Button>
                        )}
                        <DocumentGallery
                            isReadonly={this.props.isReadonly}
                            hideAddButton={false}
                            onDocumentDeleted={this.deleteDocumentInStorage}
                            shouldConfirmDeleteDocument={true}
                            onError={(err) => {
                                this.setState({
                                    errorMessage: err as IStorageProviderError,
                                });
                            }}
                        />
                    </Stack>
                    <Split
                        className="react-split-container"
                        sizes={splitSize}
                        maxSize={[Infinity, getPixelWidthFromPercent(50)]}
                        minSize={[getPixelWidthFromPercent(30), getPixelWidthFromPercent(15)]}
                        gutterSize={8}
                        onDragEnd={this.handleSplitPaneSizesChange}
                    >
                        <Stack className="label-page-canvas" grow={1}>
                            <LabelCanvas
                                formId={this.props.router.params.formId}
                                handleAnalyzeButtonClick={this.handleAnalyzeButtonClick}
                                isDisabledAnalyze={!this.state.analyzeFile}
                                isReadonly={this.props.isReadonly}
                                isDocumentExtracted={this.state.isDocumentExtracted}
                                isLoadingExtract={this.state.isLoadingExtract}
                                isLoadingAnalyze={this.state.isLoadingAnalyze}
                                getAndSetOcr={() => this.getAndSetOcr()}
                            />
                        </Stack>
                        <Stack className="label-page-pane">
                            {this.props.isReadonly ? (
                                (!!this.props.fields.length || !!this.props.currentDocument?.ocrExtractResponse) && (
                                    <Tabs
                                        style={{ marginLeft: 10 }}
                                        defaultActiveKey="1"
                                        items={sideMenuItems}
                                        tabBarExtraContent={{
                                            right: (
                                                <div style={{ paddingRight: 10 }}>
                                                    <Button
                                                        onClick={() =>
                                                            this.handleJsonDownload(
                                                                this.props.currentDocument?.ocrExtractResponse
                                                            )
                                                        }
                                                    >
                                                        Download
                                                    </Button>
                                                </div>
                                            ),
                                        }}
                                    />
                                )
                            ) : (
                                <LabelPane
                                    isReadonly={this.props.isReadonly}
                                    isTablePaneOpen={isTablePaneOpen}
                                    setIsTablePaneOpen={this.handelSetIsTablePanelOpen}
                                />
                            )}
                        </Stack>
                    </Split>
                </Stack>
                {labelError !== null && (
                    <MessageModal
                        isOpen={true}
                        title={labelError.name}
                        body={<Text variant="medium">{labelError.message}</Text>}
                        onClose={() => clearLabelError()}
                        rejectButtonText="Close"
                    />
                )}
                <link as="image" href={constants.dynamicTableImgSrc} />
                <link as="image" href={constants.fixedTableImgSrc} />
                <MessageModal
                    isOpen={isInvalidFieldsFormatModalOpen}
                    title="Incorrect fields format"
                    body={
                        <Text variant="medium">
                            The fields.json file of this project does not align with the expected schema. Please correct
                            the file and re-enter the project, or delete fields.json file and create fields again.
                        </Text>
                    }
                    onClose={this.handleCloseIncorrectLabelFieldsFormatModal}
                    actionButtonText="Delete fields.json file"
                    onActionButtonClick={this.handleDeleteLabelFieldsJsonFile}
                />
                {errorMessage !== undefined && (
                    <MessageModal
                        isOpen={true}
                        title={errorMessage.code}
                        body={<Text variant="medium">{errorMessage.message}</Text>}
                        rejectButtonText="Close"
                        onClose={this.handleCloseStorageErrorModal}
                    />
                )}
                {this.state.isUploadModalOpen && (
                    <FileUpload
                        isUploadModalOpen={this.state.isUploadModalOpen}
                        addDocuments={this.props.addDocuments}
                        setUploadedDocuments={this.setUploadedDocuments}
                        handleCloseModal={() => this.setState({ isUploadModalOpen: false })}
                    />
                )}
            </Stack>
        );
    }
}

const mapState = (state: ApplicationState) => ({
    fields: state.customModel.fields,
    documents: state.documents.documents,
    currentDocument: state.documents.currentDocument,
    labels: state.customModel.labels,
    labelError: state.customModel.labelError,
    location: state.router.location,
    predictions: state.predictions.predictions,
});
const mapDispatch = {
    addDocuments,
    changeDocumentData,
    deleteDocument,
    setDocumentAnalyzingStatus,
    setDocumentLabelingStatus,
    setDefinitions,
    setFields,
    setLabelsById,
    clearLabelError,
    setDocumentPrediction,
    addLoadingOverlay,
    removeLoadingOverlayByName,
    deleteLabelByName,
    setCurrentDocument,
};

const connector = connect(mapState, mapDispatch);

function mapResultToFields(fields: { [key: string]: DocumentField | undefined }): FieldFromDoc[] {
    const res = Object.entries(fields).map(([fieldKey, value]) => {
        return mapDocumentFields(fieldKey, value);
    });
    // @ts-ignore
    return res.filter(Boolean);
}

function documentFieldVal(field: DocumentField): any {
    if (field.kind === 'array') {
        return field.values.map(documentFieldVal);
    }
    if (field.kind === 'object') {
        const valMap = {};
        for (const fieldKey in field.properties) {
            const fieldVal = field.properties[fieldKey];
            if (!fieldVal) {
                continue;
            }
            valMap[fieldKey] = documentFieldVal(fieldVal);
        }
        return valMap;
    }
    return field.value;
}
function mapDocumentFields(fieldKey: string, value: DocumentField | undefined): FieldFromDoc | null {
    if (!value) {
        return null;
    }
    if (value.kind === 'array') {
        const castValue = value as DocumentArrayField;
        return {
            fieldKey,
            fieldType: castValue.kind,
            fieldFormat: 'not-specified',
            confidence: castValue.confidence || undefined,
            value: castValue.values.map(documentFieldVal),
        };
    }
    if (value.kind === 'object') {
        const castValue = value as DocumentObjectField;
        const mapped = mapResultToFields(castValue.properties);
        console.log({ mapped });
        return {
            fieldKey,
            fieldType: castValue.kind,
            fieldFormat: 'not-specified',
            confidence: castValue.confidence || undefined,
            value: mapped,
        };
    }
    if (!value.value) {
        return null;
    }
    if (value.kind === 'boolean') {
        return {
            fieldKey,
            fieldType: 'string',
            fieldFormat: 'not-specified',
            confidence: value.confidence || undefined,
            value: value.value.toString(),
        };
    }
    if (value.kind === 'address') {
        return {
            fieldKey,
            fieldType: 'string',
            fieldFormat: 'not-specified',
            confidence: value.confidence || undefined,
            value: value.value,
        };
    }
    if (value.kind === 'phoneNumber') {
        return {
            fieldKey,
            fieldType: 'string',
            fieldFormat: 'not-specified',
            confidence: value.confidence || undefined,
            value: value.value,
        };
    }
    if (value.kind === 'countryRegion') {
        return {
            fieldKey,
            fieldType: 'string',
            fieldFormat: 'not-specified',
            confidence: value.confidence || undefined,
            value: value.value,
        };
    }
    if (value.kind === 'currency') {
        return {
            fieldKey,
            fieldType: 'string',
            fieldFormat: 'not-specified',
            confidence: value.confidence || undefined,
            value: value.value,
            // value: `${value.value.currencySymbol ? value.value.currencySymbol + ' ' : ''}${
            //     value.value.currencyCode ? value.value.currencyCode + ' ' : ''
            // }${value.value.amount}`,
        };
    }
    if (value.kind === 'date') {
        return {
            fieldKey,
            fieldType: 'string',
            fieldFormat: 'not-specified',
            confidence: value.confidence || undefined,
            value: value.value, //.toISOString ? value.value.toISOString() : value.value,
        };
    }
    return {
        fieldKey,
        fieldType: value.kind,
        fieldFormat: 'not-specified',
        confidence: value.confidence || undefined,
        value: value.value,
    };
}

export default withRouter(connector(CustomModelLabelPage));
