import * as React from 'react';
import {
    IDocumentCardStyles,
    DocumentCard,
    DocumentCardImage,
    DocumentCardDetails,
    ImageFit,
    IDocumentCardTitleStyles,
    Spinner,
    SpinnerSize,
    FontIcon,
    Text,
} from '@fluentui/react';
import { Depths, NeutralColors } from '@fluentui/theme';

import { DocumentStatus, IDocumentStates } from 'pages/label/store/documents/documentsTypes';
import { httpService } from 'core/services/httpService';

import './documentPreview.scss';

interface IDocumentPreviewProps {
    selected: boolean;
    isReadonly: boolean;
    documentName: string;
    documentType: string;
    documentId?: string;
    documentUrl?: string;
    documentStates: IDocumentStates;
    documentImageSrc: string;
    onDocumentClick: (documentId?: string) => void;
    onDocumentDelete: (name: string, id: string) => void;
}

interface IDocumentPreviewState {
    isDocumentCardHovered: boolean;
}

type Props = IDocumentPreviewProps;

export class DocumentPreview extends React.PureComponent<Props, IDocumentPreviewState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isDocumentCardHovered: false,
        };
    }

    private getCardStyles = (): IDocumentCardStyles => {
        return {
            root: {
                position: 'relative',
                height: 124,
                minWidth: 124,
                width: 124,
                border: this.props.selected ? '1px solid ' + NeutralColors.black : 'none',
                borderRadius: '2px',
                boxShadow: Depths.depth4,
                boxSizing: 'border-box',
                marginLeft: 16,
                marginRight: 'auto',
            },
        };
    };

    private titleStyles: IDocumentCardTitleStyles = {
        /*
            Set width to make shouldTruncate work correctly
            Leave some width buffer to overflow, since the calculation of shouldTruncate might not be completely fit.
            Set overflow to visible and make nowrap to display the overflow part.
        */
        root: {
            fontSize: '12px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            height: 20,
            lineHeight: 20,
            width: 110, // Max 124.
            padding: 0,
            textAlign: 'center',
        },
    };

    private getDocumentBadge = () => {
        const { documentStates } = this.props;
        if (documentStates.labelingStatus === DocumentStatus.Labeled) {
            return <FontIcon title="Labeled" iconName="CircleFill" style={{ color: '#6C179A', fontSize: 14 }} />;
        } else if (documentStates.analyzingStatus === DocumentStatus.Analyzed) {
            return <FontIcon title="Analyzed" iconName="CircleFill" style={{ color: '#0F703B', fontSize: 14 }} />;
        }
    };

    private handleClick = () => {
        const { onDocumentClick, documentId } = this.props;
        onDocumentClick(documentId);
    };

    private handleDocumentDelete = (evt) => {
        evt.stopPropagation();
        const { onDocumentDelete, documentName, documentId } = this.props;
        onDocumentDelete(documentName, documentId!);
    };

    private getAnalyzeStatusString = () => {
        const { documentStates } = this.props;
        switch (documentStates.analyzingStatus) {
            case DocumentStatus.Analyzed:
                return 'Analyzed';
            case DocumentStatus.Analyzing:
                return 'Analyzing...';
            case DocumentStatus.AnalyzeFailed:
                return 'Analyze failed';
            default:
                return 'Loaded';
        }
    };

    private getImageObjectUrl = async () => {
        return this.props.documentUrl;
    };

    private getPdfObjectUrl = async () => {
        let total;
        let downloaded = 0;

        // If adding a progress indicator later, use react refs to mutate dom.
        /* eslint-disable @typescript-eslint/no-unused-vars */
        let progress = '';

        const fetchRes = await fetch(this.props.documentUrl as string, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${await httpService.getAccessToken()}`,
            },
        });

        const res = new Response(
            new ReadableStream({
                async start(controller) {
                    const reader = fetchRes.body?.getReader();
                    for (;;) {
                        const { done, value }: any = (await reader?.read()) || {};
                        if (done) {
                            break;
                        }
                        downloaded += value.byteLength;
                        progress = `${Math.round((downloaded / total) * 100)}%`;
                        controller.enqueue(value);
                    }
                    controller.close();
                },
            })
        );

        const blob = await res.blob();

        return URL.createObjectURL(blob);
    };

    private async getDocumentObjectUrl() {
        const type = this.props.documentType;

        const isImage = type.includes('image');
        const isPdf = type.includes('pdf');

        if (isImage) {
            return this.getImageObjectUrl();
        }

        if (isPdf) {
            return this.getPdfObjectUrl();
        }
    }

    private handleDownloadClick = async () => {
        const typeSplit = this.props.documentType?.split('/') || [];
        const type = typeSplit[typeSplit.length - 1];

        const name = this.props.documentName;

        const objectUrl = await this.getDocumentObjectUrl();

        const link = document.createElement('a');

        link.href = `${objectUrl}`;
        link.setAttribute('download', `${name}.${type}`);

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
    };

    public render() {
        const { documentName, documentStates, documentImageSrc } = this.props;
        const { isDocumentCardHovered } = this.state;

        return (
            <DocumentCard
                key={documentName}
                title={documentName}
                tabIndex={0}
                aria-label={documentName + 'Document Status:' + this.getAnalyzeStatusString()}
                styles={this.getCardStyles()}
                onClick={this.handleClick}
                onMouseEnter={() => {
                    this.setState({ isDocumentCardHovered: true });
                }}
                onMouseLeave={() => {
                    this.setState({ isDocumentCardHovered: false });
                }}
            >
                <DocumentCardImage height={100} imageFit={ImageFit.contain} imageSrc={documentImageSrc} />
                <DocumentCardDetails styles={{ root: { alignItems: 'center' } }}>
                    <Text title={documentName} styles={this.titleStyles}>
                        {documentName}
                    </Text>
                    {isDocumentCardHovered && !this.props.isReadonly && (
                        <>
                            <FontIcon
                                className="documentcard-delete-icon"
                                title="Delete"
                                iconName="delete"
                                onClick={this.handleDocumentDelete}
                            />

                            <span onClick={this.handleDownloadClick}>
                                <FontIcon className="documentcard-download-icon" title="Download" iconName="download" />
                            </span>
                        </>
                    )}
                </DocumentCardDetails>
                {documentStates.analyzingStatus === DocumentStatus.Analyzing && (
                    <div className="documentcard-overlay">
                        <Spinner
                            className="analyze-spinner"
                            size={SpinnerSize.medium}
                            label="Analyzing..."
                            styles={{ label: { fontSize: 14 } }}
                        />
                    </div>
                )}
                <div className="documentcard-badge">{this.getDocumentBadge()}</div>
            </DocumentCard>
        );
    }
}

export default DocumentPreview;
