import React from 'react';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
    key: React.Key;
    _id: string;
    logId: string;
    rebuildStartDate: string;
    rebuildCompletedDate: string;
    status: string;
    statusLog: any[];
}

const logStatus = {
    successful: <Tag color="green">Successful</Tag>,
    error: <Tag color="red">Error</Tag>,
    started: <Tag color="gold">Started</Tag>,
    'files-written': <Tag color="gold">Files-written</Tag>,
};

const columns: ColumnsType<DataType> = [
    { title: 'Log id', dataIndex: 'logId', key: 'logId' },
    { title: 'Start date', dataIndex: 'rebuildStartDate', key: 'rebuildStartDate' },
    { title: 'End date', dataIndex: 'rebuildCompletedDate', key: 'rebuildCompletedDate' },
    {
        title: 'Status',
        key: 'status',
        render: (log) => logStatus[log.status],
    },
    {
        title: 'Error',
        key: 'error',
        render: (log) => (
            <>{!log.error ? 'None' : typeof log.error === 'string' ? log.error : JSON.stringify(log.error)}</>
        ),
    },
    Table.EXPAND_COLUMN,
    {
        title: 'Details',
        key: 'operation',
        width: 100,
        render: () => <>view</>,
    },
];

interface IProps {
    data: any;
}

const LogsTable = ({ data }: IProps) => {
    return (
        <Table
            size="small"
            scroll={{ x: true }}
            pagination={false}
            columns={columns}
            rowKey={(log) => log._id}
            expandable={{
                expandedRowRender: (log) => (
                    <div>
                        {log.statusLog?.length
                            ? log.statusLog.map((text, index) => (
                                  <p key={index} style={{ margin: 0 }}>
                                      {text}
                                  </p>
                              ))
                            : 'None'}
                    </div>
                ),
            }}
            dataSource={data}
        />
    );
};

export default LogsTable;
