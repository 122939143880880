import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { formTreeService } from 'data/services/formTreeService';
import { EyeOutlined, UserOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { User } from './user';
import { Item } from './item';
import { ADMIN_PAGE, ANALYZE_PAGE } from 'data/consts/routes';
import { FORM_TREE_VIEW, DOCUMENT_TREE_VIEW } from 'data/consts/apiQuery';
import { Logo } from 'components/svg/logo';
import { LogoNoText } from 'components/svg/logo-no-text';
import { FormTree } from './form-tree';
import { DocumentTree } from './document-tree';
import { RoleGuard } from '../../roles';
import { EnvironmentSwitcher } from './environment-switcher';

import styles from './sidebar.module.scss';

export function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const formRes = useQuery({
        queryKey: [FORM_TREE_VIEW],
        queryFn: () => formTreeService.getFormTreeList(),
        retry: 0,
    });

    const documentRes = useQuery({
        queryKey: [DOCUMENT_TREE_VIEW],
        queryFn: () => formTreeService.getDocumentList(),
        retry: 0,
    });

    const isLoading = formRes.isLoading || documentRes.isLoading;

    const formData = formRes.isLoading ? [] : formRes.data;
    const documentData = documentRes.isLoading ? [] : documentRes.data;

    return (
        <div id={'sidebar'} className={`${styles.container} ${isCollapsed ? `${styles['container-collapsed']}` : ''}`}>
            <div className={styles.top}>
                <div className={`${styles.logo} ${isCollapsed ? `${styles['logo-collapsed']}` : ''}`}>
                    {!isCollapsed && <Logo className={styles.image} onClick={() => setIsCollapsed(true)} />}

                    {isCollapsed && (
                        <LogoNoText className={styles['image-collapsed']} onClick={() => setIsCollapsed(false)} />
                    )}

                    {!isCollapsed && (
                        <div className={styles['icon-container']} onClick={() => setIsCollapsed(true)}>
                            <MenuFoldOutlined className={styles.icon} />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.center}>
                <div className={styles.pages}>
                    <EnvironmentSwitcher isCollapsed={isCollapsed} />
                    <Item
                        text={'Analyze'}
                        icon={EyeOutlined}
                        onClick={() => navigate(ANALYZE_PAGE)}
                        isActive={location.pathname === ANALYZE_PAGE}
                        isCollapsed={isCollapsed}
                    />
                    <RoleGuard allowedRoles={['admin']}>
                        <Item
                            text={'Admin'}
                            icon={UserOutlined}
                            onClick={() => navigate(ADMIN_PAGE)}
                            isActive={location.pathname === ADMIN_PAGE}
                            isCollapsed={isCollapsed}
                        />
                    </RoleGuard>
                </div>
                <RoleGuard allowedRoles={['admin', 'trainer', 'row-admin']}>
                    <div className={styles.trees}>
                        <FormTree isCollapsed={isCollapsed} isLoading={isLoading} data={formData} />
                        <DocumentTree isCollapsed={isCollapsed} isLoading={isLoading} data={documentData} />
                    </div>
                </RoleGuard>
            </div>
            <div className={styles.bottom}>
                <User isCollapsed={isCollapsed} />
            </div>
        </div>
    );
}
