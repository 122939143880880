import React from 'react';
import { Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import styles from './item.module.scss';

type Props = {
    icon?: any;
    text?: string;
    onClick?: () => void;
    isActive?: boolean;
    isCollapsed?: boolean;
};

export function Item({
    icon: Icon = UserOutlined,
    text = 'text',
    onClick = () => {},
    isActive = false,
    isCollapsed = false,
}: Props) {
    return (
        <div className={`${styles.container} ${isActive ? styles['container-active'] : ''}`} onClick={onClick}>
            <div
                className={`${styles['icon-container']} ${isCollapsed ? `${styles['icon-container-collapsed']}` : ''}`}
            >
                <Icon className={styles.icon} />
            </div>

            <div
                className={`${styles['text-container']} ${isCollapsed ? `${styles['text-container-collapsed']}` : ''}`}
            >
                <Typography className={styles.text}>{text}</Typography>
            </div>
        </div>
    );
}
