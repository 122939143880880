import React, { useContext, createContext } from 'react';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

interface IProps {
    children: React.ReactNode | React.ReactNode[];
}

const InitialContext = createContext({
    notificationApi: {} as NotificationInstance,
});

export const MainProvider = ({ children }: IProps) => {
    const [api, contextHolder] = notification.useNotification();
    const contextValue = {
        notificationApi: api,
    };

    return (
        <InitialContext.Provider value={contextValue}>
            {children}
            {contextHolder}
        </InitialContext.Provider>
    );
};

export const useContextValues = () => {
    return useContext(InitialContext);
};
