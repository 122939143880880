export const getAuthorizedUsersFromStr = (obj) => {
    if (Array.isArray(obj)) {
        return obj;
    }

    const authorizedUsers = [];

    const commaParts = typeof obj === 'string' ? obj.split(',') : [];

    const emails = commaParts
        .filter((part) => !!part)
        .filter((part) => /@/.test(part))
        .map((part) => part.trim());

    for (const email of emails) {
        // @ts-ignore
        authorizedUsers.push({
            email: email,
            permissions: {
                create: true,
                read: true,
                update: true,
                delete: true,
            },
        });
    }

    return authorizedUsers;
};
