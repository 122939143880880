import React from 'react';

import { useRoles } from '../../hooks';
import { roles } from '../../types';

type Props = {
    allowedRoles: roles;
    children: React.ReactNode;
};

export const RoleGuard = ({ allowedRoles, children }: Props) => {
    const { userRoles } = useRoles();

    let canDisplay = false;

    for (const role of allowedRoles) {
        if (userRoles.includes(role)) {
            canDisplay = true;
        }
    }

    if (canDisplay) {
        // return children;
        // Sigh - TS2786
        return <>{children}</>;
    }

    return null;
};
