import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';

interface IProps {
    disabled: boolean;
    analyzeFile: UploadFile | null;
    setFile: (file: UploadFile | null) => void;
}

export function SingleFileUpload({ setFile, analyzeFile, disabled }: IProps) {
    const props: UploadProps = {
        itemRender: () => <></>,
        accept: '.pdf,.jpeg,.jpg,.png,.tiff,.tif',
        onRemove: () => {
            setFile(null);
        },
        beforeUpload: (file) => {
            setFile(file);

            return false;
        },
        fileList: analyzeFile ? [analyzeFile] : [],
    };

    return (
        <Upload {...props}>
            <Button disabled={disabled} icon={<UploadOutlined />} style={{ marginTop: 10, marginLeft: 17 }}>
                Select File
            </Button>
        </Upload>
    );
}
