import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';

interface IProps {
    queryData: UseQueryResult;
    RenderSuccess: React.ElementType;
    renderLoading: React.ReactNode;
    renderError?: React.ReactNode;
    checkIsFetching?: boolean;
}

export function AsyncRender({
    queryData: { isLoading, isFetching, isSuccess, isError, data },
    RenderSuccess,
    renderLoading,
    renderError,
    checkIsFetching = false,
}: IProps) {
    if (isError && renderError) {
        return <>{renderError}</>;
    } else if (checkIsFetching && isFetching) {
        return <>{renderLoading}</>;
    } else if (isLoading) {
        return <>{renderLoading}</>;
    } else if (isSuccess && data) {
        return <RenderSuccess data={data} />;
    } else {
        return <></>;
    }
}
