import * as React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { RowAction } from './rowAction';

import { RowRuleActionStep, RowRule as RowRuleType } from 'types';

import styles from './rowRule.module.scss';

type DropdownItem = {
    value: string;
    label: string;
};

type Props = {
    ruleIndex: number;
    description: RowRuleType;
    onRuleDelete: (ruleIndex: number) => void;
    onRuleMoveUp: (ruleIndex: number) => void;
    onRuleMoveDown: (ruleIndex: number) => void;
    onActionBooleanOperatorClick: (item: DropdownItem, ruleIndex: number, actionIndex: number) => void;
    onActionStepsChange: (steps: RowRuleActionStep[], ruleIndex: number, actionIndex: number) => void;
    onActionDelete: (ruleIndex: number, actionIndex: number) => void;
    onActionMoveUp: (ruleIndex: number, actionIndex: number) => void;
    onActionMoveDown: (ruleIndex: number, actionIndex: number) => void;
    onActionCreate: (ruleIndex: number) => void;
    onConditionOperandChange: (
        item: DropdownItem,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionOperandDetailChange: (
        text: string,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionOperatorChange: (
        item: DropdownItem,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionValueChange: (text: string, ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onConditionDelete: (ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onConditionCreate: (ruleIndex: number, actionIndex: number) => void;
};

export class RowRule extends React.PureComponent<Props> {
    private getRowRule = () => {
        const {
            ruleIndex,
            description,
            onRuleDelete,
            onRuleMoveUp,
            onRuleMoveDown,
            onActionBooleanOperatorClick,
            onActionStepsChange,
            onActionDelete,
            onActionMoveUp,
            onActionMoveDown,
            onActionCreate,
            onConditionOperandChange,
            onConditionOperandDetailChange,
            onConditionOperatorChange,
            onConditionValueChange,
            onConditionDelete,
            onConditionCreate,
        } = this.props;

        const { actions } = description;

        return (
            <>
                <div className={styles['row']}>
                    <div className={styles['name-container']}>
                        <span className={styles['name']}>{`Rule ${ruleIndex + 1}`}</span>
                    </div>
                    <div className={styles['button-container']}>
                        <div className={styles['order-button-container']}>
                            <Button
                                className={styles['button']}
                                icon={<CaretUpOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onRuleMoveUp(ruleIndex)}
                            />
                            <Button
                                className={styles['button']}
                                icon={<CaretDownOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onRuleMoveDown(ruleIndex)}
                            />
                        </div>
                        <Button
                            className={styles['button']}
                            icon={<MinusOutlined style={{ fontSize: 10 }} />}
                            onClick={() => onRuleDelete(ruleIndex)}
                        />
                    </div>
                </div>
                <div className={styles['row']}>
                    {actions.length ? (
                        <>
                            <span>Perform the following action(s)</span>
                            <Button
                                className={styles['button']}
                                icon={<PlusOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onActionCreate(ruleIndex)}
                            />
                        </>
                    ) : (
                        <>
                            <Button type={'default'} onClick={() => onActionCreate(ruleIndex)}>
                                Add action(s)
                            </Button>
                        </>
                    )}
                </div>
                {!!actions.length && (
                    <>
                        {actions.map((action, actionIndex) => (
                            <RowAction
                                key={`row-rule-${ruleIndex}-action-${actionIndex}`}
                                ruleIndex={ruleIndex}
                                actionIndex={actionIndex}
                                description={action}
                                onBooleanOperatorClick={onActionBooleanOperatorClick}
                                onStepsChange={onActionStepsChange}
                                onDelete={onActionDelete}
                                onMoveUp={onActionMoveUp}
                                onMoveDown={onActionMoveDown}
                                onConditionOperandChange={onConditionOperandChange}
                                onConditionOperandDetailChange={onConditionOperandDetailChange}
                                onConditionOperatorChange={onConditionOperatorChange}
                                onConditionValueChange={onConditionValueChange}
                                onConditionDelete={onConditionDelete}
                                onConditionCreate={onConditionCreate}
                            />
                        ))}
                    </>
                )}
            </>
        );
    };

    public render = () => {
        return <div className={styles['container']}>{this.getRowRule()}</div>;
    };
}
