import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Input, Result, Skeleton, Typography } from 'antd';
import { ReloadOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formService } from '../data/formService';
import { IFormValues } from '../data/model';
import { useContextValues } from '../../../components/providers/MainProvider';
import { FORM_TREE_VIEW, FORM_TYPE_VERSION_EDIT } from '../../../data/consts/apiQuery';
import { AsyncRender } from '../../../components/common/async-render';

import styles from '../form-crud.module.scss';

export function FormTypeVersionEdit() {
    const queryClient = useQueryClient();
    const { notificationApi } = useContextValues();
    const [form] = Form.useForm();
    const { formId, formTypeId, formTypeVersionId } = useParams();

    const queryData = useQuery({
        queryKey: [FORM_TYPE_VERSION_EDIT, formTypeVersionId],
        queryFn: () =>
            formService.getFormTypeVersion(formId as string, formTypeId as string, formTypeVersionId as string),
        retry: 0,
        onSuccess: (res) => {
            form.setFieldsValue({
                title: res.title,
                description: res.description,
            });
        },
    });

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
        mutationFn: (values: IFormValues) => {
            return formService.updateFormTypeVersion(
                values,
                formId as string,
                formTypeId as string,
                formTypeVersionId as string
            );
        },
        onSuccess: () => {
            notificationApi.success({
                message: 'Edited version',
                description: 'The version has been edited.',
                placement: 'topRight',
            });

            queryClient.invalidateQueries({ queryKey: [FORM_TREE_VIEW] });
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Failed to edit version',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    const handleFormSubmit = (values) => {
        mutateUpdate(values);
    };

    return (
        <AsyncRender
            queryData={queryData}
            RenderSuccess={() => (
                <>
                    <Typography.Paragraph className={styles.card__title}>Edit version</Typography.Paragraph>

                    <Form form={form} layout="vertical" size="large" onFinish={handleFormSubmit}>
                        <Form.Item
                            label="Title"
                            name="title"
                            required
                            rules={[{ required: true, message: 'Title cannot be empty' }]}
                        >
                            <Input style={{ width: '50%' }} placeholder="Please enter a title" size="middle" />
                        </Form.Item>

                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={4} placeholder="Please enter a description" size="middle" />
                        </Form.Item>

                        <Button size={'middle'} loading={isLoadingUpdate} type="primary" htmlType="submit">
                            {isLoadingUpdate ? 'Updating' : 'Update'}
                        </Button>
                    </Form>
                </>
            )}
            renderLoading={
                <>
                    <Skeleton active />
                    <Skeleton active className="mt-8" />
                </>
            }
            renderError={
                <Result
                    title="Failed to load form"
                    subTitle="Please click on `Reload` to try again."
                    status="error"
                    icon={<CloseCircleFilled style={{ fontSize: 32 }} />}
                    extra={[
                        <Button
                            size={'middle'}
                            danger
                            type="primary"
                            key="reload"
                            icon={<ReloadOutlined rev="true" />}
                            onClick={() => {
                                queryClient.invalidateQueries({
                                    queryKey: [FORM_TYPE_VERSION_EDIT, formTypeVersionId],
                                });
                            }}
                        >
                            Reload
                        </Button>,
                    ]}
                />
            }
        />
    );
}
