import React from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { formService } from '../data/formService';
import { IFormTypeValues } from '../data/model';
import { useContextValues } from '../../../components/providers/MainProvider';
import { FORM_TREE_VIEW } from '../../../data/consts/apiQuery';

import styles from '../form-crud.module.scss';

export function FormTypeCreate() {
    const queryClient = useQueryClient();
    const { notificationApi } = useContextValues();
    const [form] = Form.useForm();
    const { formId } = useParams();

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation({
        mutationFn: (values: IFormTypeValues) => {
            return formService.createFormType(values, formId as string);
        },
        onSuccess: () => {
            notificationApi.success({
                message: 'Created type',
                description: 'A new type has been created.',
                placement: 'topRight',
            });

            queryClient.invalidateQueries({ queryKey: [FORM_TREE_VIEW] });
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Failed to create type',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    const handleFormSubmit = (values) => {
        mutateCreate(values);
    };

    return (
        <>
            <Typography.Paragraph className={styles.card__title}>Add type</Typography.Paragraph>

            <Form form={form} layout="vertical" size="large" onFinish={handleFormSubmit}>
                <Form.Item
                    label="Title"
                    name="title"
                    required
                    rules={[{ required: true, message: 'Title cannot be empty' }]}
                >
                    <Input style={{ width: '50%' }} placeholder="Please enter a title" size="middle" />
                </Form.Item>

                <Form.Item label="Description" name="description">
                    <Input.TextArea rows={4} placeholder="Please enter a description" size="middle" />
                </Form.Item>

                <Button size={'middle'} loading={isLoadingCreate} type="primary" htmlType="submit">
                    {isLoadingCreate ? 'Creating' : 'Create'}
                </Button>
            </Form>
        </>
    );
}
