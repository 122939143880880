import * as React from 'react';
import { Select } from './select';
import { Input } from './input';
import { hasWhiteSpaceAtEdges, isOnlyWhiteSpace } from 'helpers';

import { RowRuleActionStep } from 'types';

import styles from './columnSteps.module.scss';

type Props = {
    ruleIndex: number;
    actionIndex: number;
    description: RowRuleActionStep[];
    onStepsChange: (steps: RowRuleActionStep[], ruleIndex: number, actionIndex: number) => void;
};

export class RowSteps extends React.PureComponent<Props> {
    private getDropdownItems = (value) => {
        if (value === 'Remove row' || value === 'Merge row') {
            return [
                { value: 'Remove row', label: 'Remove row' },
                { value: 'Merge row', label: 'Merge row' },
            ];
        }

        return [];
    };

    private getNextStep = (currentStep) => {
        if (currentStep.type !== 'Dropdown') {
            return undefined;
        }

        if (currentStep.value === 'Remove row') {
            return undefined;
        }

        if (currentStep.value === 'Merge row') {
            return undefined;
        }

        return undefined;
    };

    private createSteps = (currentSteps) => {
        if (!currentSteps.length) {
            return [];
        }

        const newSteps = currentSteps.filter((step) => !!step);

        const currentStep = newSteps[newSteps.length - 1];
        const nextStep = this.getNextStep(currentStep);

        if (nextStep) {
            newSteps.push(nextStep);
        }

        return newSteps;
    };

    private renderSteps = (steps, ruleIndex, actionIndex, onStepsChange) => {
        const cleanedSteps = steps.filter((step) => !!step);
        const items: React.ReactNode[] = [];

        for (const [stepIndex, step] of cleanedSteps.entries()) {
            if (step.type === 'Dropdown') {
                items.push(
                    <div
                        key={`row-rule-${ruleIndex}-action-${actionIndex}-step-${stepIndex}-dropdown`}
                        className={styles['item']}
                    >
                        <Select
                            className={styles['select']}
                            options={this.getDropdownItems(step.value)}
                            value={step.value}
                            onChange={(value) => {
                                // The slice will discard the steps after the current one and allow the steps component
                                // to regenerate with the new value in mind.
                                const newSteps = steps.slice(0, stepIndex + 1);

                                newSteps[stepIndex] = {
                                    ...newSteps[stepIndex],
                                    value: value,
                                };

                                onStepsChange(newSteps, ruleIndex, actionIndex);
                            }}
                        />
                    </div>
                );
            }

            if (step.type === 'Input') {
                items.push(
                    <div
                        key={`row-rule-${ruleIndex}-action-${actionIndex}-step-${stepIndex}-input`}
                        className={styles['item-flex']}
                    >
                        <Input
                            placeholder={'Some value'}
                            value={step.value}
                            onChange={(e) => {
                                const text = e.target.value;

                                const newSteps = steps.slice(0, stepIndex + 1);

                                newSteps[stepIndex] = {
                                    ...newSteps[stepIndex],
                                    value: text,
                                };

                                onStepsChange(newSteps, ruleIndex, actionIndex);
                            }}
                            validator={(text) => {
                                if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                    return 'Please enter a valid value';
                                }

                                return undefined;
                            }}
                        />
                    </div>
                );
            }
        }

        return items;
    };

    private getSteps = () => {
        const { description: currentSteps, ruleIndex, actionIndex, onStepsChange } = this.props;

        const renderedSteps = this.renderSteps(currentSteps, ruleIndex, actionIndex, onStepsChange);

        return renderedSteps;
    };

    private isSameSteps = (steps1, steps2) => {
        if (steps1.length !== steps2.length) {
            return false;
        }

        for (const [stepIndex] of steps1.entries()) {
            if (steps1[stepIndex]?.type !== steps2[stepIndex]?.type) {
                return false;
            }

            if (steps1[stepIndex]?.value !== steps2[stepIndex]?.value) {
                return false;
            }
        }

        return true;
    };

    public componentDidUpdate(prevProps) {
        const { description: steps, ruleIndex, actionIndex, onStepsChange } = this.props;

        const { description: prevSteps } = prevProps;

        if (this.isSameSteps(prevSteps, steps)) {
            return;
        }

        const newSteps = this.createSteps(steps);

        onStepsChange(newSteps, ruleIndex, actionIndex);
    }

    public componentDidMount() {
        const { description: steps, ruleIndex, actionIndex, onStepsChange } = this.props;

        if (steps.length) {
            return;
        }

        const newSteps = [...steps];

        newSteps[0] = { type: 'Dropdown', value: 'Remove row' };

        onStepsChange(newSteps, ruleIndex, actionIndex);
    }

    public render = () => {
        return <div className={styles['row']}>{this.getSteps()}</div>;
    };
}
