export const LAYER_NAME = 'name';
export const IMAGE_LAYER_NAME = 'imageLayer';
export const TEXT_VECTOR_LAYER_NAME = 'textVectorLayer';
export const POD_VECTOR_LAYER_NAME = 'podVectorLayer';
export const TABLE_BORDER_VECTOR_LAYER_NAME = 'tableBorderVectorLayer';
export const TABLE_ICON_VECTOR_LAYER_NAME = 'tableIconVectorLayer';
export const TABLE_ICON_BORDER_VECTOR_LAYER_NAME = 'tableIconBorderVectorLayer';
export const CHECKBOX_VECTOR_LAYER_NAME = 'checkboxBorderVectorLayer';
export const LABEL_VECTOR_LAYER_NAME = 'labelledVectorLayer';
export const DRAWN_REGION_LABEL_VECTOR_LAYER_NAME = 'drawnRegionLabelledVectorLayer';
export const DRAWN_REGION_VECTOR_LAYER_NAME = 'drawnRegionVectorLayer';

export const SELECTED_PROPERTY = 'selected';
export const SELECTION_MARK_STATE = 'selectionMarkState';
export const HIGHLIGHTED_PROPERTY = 'highlighted';
export const FIELD_PROPERTY = 'field';
export const COLOR_PROPERTY = 'color';
export const BACKGROUND_COLOR_PROPERTY = 'backgroundColor';
export const DASHED_PROPERTY = 'dashed';
export const DISABLEHIGHLIGHT_PROPERTY = 'disableHighlight';
