import * as React from 'react';

import { Dropdown, Button, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import styles from './select.module.scss';

type Props = {
    className?: string;
    options: { value: string; label: string }[];
    onChange: (value: string, item: any) => void;
    value: string;
};

export class Select extends React.PureComponent<Props> {
    private getItemsFromOptions = (options) => {
        return options.map((option, index) => ({
            key: index,
            label: option.label,
            value: option.value,
        }));
    };

    private onChange = (e) => {
        const { options } = this.props;

        const itemIndex = e?.key;

        const item = this.getItemsFromOptions(options)[itemIndex];

        this.props.onChange(item.value, item);
    };

    private getMenuFromOptions = (options) => {
        return {
            items: this.getItemsFromOptions(options),
            onClick: this.onChange,
        };
    };

    public render = () => {
        const { className, options, value } = this.props;

        return (
            <Dropdown
                menu={this.getMenuFromOptions(options)}
                trigger={['click']}
                className={className || styles['container']}
            >
                <Button>
                    <Space>
                        {value}
                        <DownOutlined className={styles['icon']} />
                    </Space>
                </Button>
            </Dropdown>
        );
    };
}
