import * as AjvModule from 'ajv';
import AjvErrorsModule from 'ajv-errors';
import { Ruleset } from 'types';

const Ajv = AjvModule.default;
const ajv = new Ajv({ allErrors: true });

AjvErrorsModule(ajv);

const rulesetSchema = {
    type: 'object',
    required: [
        'name',
        'identificationRules',
        'columnRules',
        'rowRules',
        'outputHeaderPosition',
        'shouldCreateHeadersOnNoMatch',
    ],
    properties: {
        name: {
            type: 'string',
            pattern: '^(?=\\S)[\\w\\s]+(?<=\\S)$',
            errorMessage: 'Ruleset name should have a minimum length of 1 with no whitespaces on the sides',
        },
        identificationRules: {
            type: 'object',
            required: ['searchMode', 'booleanOperator', 'conditions'],
            properties: {
                searchMode: {
                    type: 'string',
                    pattern: 'Column names|Table structure',
                    errorMessage: "Identification search mode should either be 'Column names' or 'Table structure'",
                },
                booleanOperator: {
                    type: 'string',
                    pattern: 'All|Any',
                    errorMessage: "Identification boolean operators should either be 'All' or 'Any'",
                },
                conditions: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: ['operand', 'operator', 'value'],
                        properties: {
                            operand: {
                                type: 'string',
                                pattern: 'Row count|Column count|Table index|Content|Column name \\d',
                                errorMessage:
                                    "Identification condition operands should either be 'Row count', 'Column count', 'Table index', 'Content', or 'Column name <number>'",
                            },
                            operator: {
                                type: 'string',
                                pattern: 'Equals|Matches',
                                errorMessage:
                                    "Identification condition operators should either be 'Equals' or 'Matches'",
                            },
                            value: {
                                type: 'string',
                                pattern: '^(?=\\S).*(?<=\\S)$',
                                errorMessage:
                                    'Identification condition values should have a minimum length of 1 with no whitespaces on the sides',
                            },
                        },
                        additionalProperties: false,
                    },
                    minItems: 1,
                    errorMessage: 'Identification rules should have a minimum of 1 condition',
                },
            },
            additionalProperties: false,
        },
        columnRules: {
            type: 'array',
            items: {
                type: 'object',
                required: ['mapping', 'actions'],
                properties: {
                    mapping: {
                        type: 'object',
                        required: ['type', 'nameFrom', 'nameTo'],
                        properties: {
                            type: {
                                type: 'string',
                                pattern: 'RegExp|String|Placeholder|Coordinates',
                                errorMessage:
                                    "Column rule mapping types should be 'RegExp', 'String', 'Placeholder' or 'Coordinates'",
                            },
                            nameFrom: {
                                type: 'string',
                                pattern: '^(?=\\S).*(?<=\\S)$',
                                errorMessage:
                                    'Column rule mapping names should have a minimum length of 1 with no whitespaces on the sides',
                            },
                            nameTo: {
                                type: 'string',
                                pattern: '^(?=\\S).*(?<=\\S)$',
                                errorMessage:
                                    'Column rule mapping names should have a minimum length of 1 with no whitespaces on the sides',
                            },
                            nameFromCoordinates: {
                                type: 'object',
                                properties: {
                                    rowIndex: {
                                        type: 'string',
                                        pattern: '^(?=\\S)[\\d]+(?<=\\S)$',
                                        errorMessage:
                                            'Column rule mapping coordinates rowIndex should have a minimum length of 1 with no whitespaces on the sides and must be a integer',
                                    },
                                    columnIndex: {
                                        type: 'string',
                                        pattern: '^(?=\\S)[\\d]+(?<=\\S)$',
                                        errorMessage:
                                            'Column rule mapping coordinates columnIndex should have a minimum length of 1 with no whitespaces on the sides and must be a integer',
                                    },
                                },
                            },
                            offsetLookup: {
                                type: 'object',
                                properties: {
                                    x: {
                                        type: 'string',
                                        pattern: '^(?=\\S)[\\d.-]+(?<=\\S)$',
                                        errorMessage:
                                            'Column rule mapping offset lookup.x should have a minimum length of 1 with no whitespaces on the sides and must be a number',
                                    },
                                    y: {
                                        type: 'string',
                                        pattern: '^(?=\\S)[\\d.-]+(?<=\\S)$',
                                        errorMessage:
                                            'Column rule mapping offset lookup.y should have a minimum length of 1 with no whitespaces on the sides and must be a number',
                                    },
                                },
                                additionalProperties: false,
                            },
                        },
                        additionalProperties: false,
                    },
                    actions: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: ['booleanOperator', 'details', 'conditions'],
                            properties: {
                                booleanOperator: {
                                    type: 'string',
                                    pattern: 'All|Any',
                                    errorMessage:
                                        "Column rule action boolean operators should either be 'All' or 'Any'",
                                },
                                details: {
                                    type: 'object',
                                    required: ['type'],
                                    properties: {
                                        type: {
                                            type: 'string',
                                            pattern: 'Split cell content|Convert cell|Merge in columns|Remove column',
                                            errorMessage:
                                                "Column rule action detail type should be 'Split cell content', 'Convert cell', 'Merge in columns' or 'Remove column'",
                                        },
                                        splitOn: {
                                            type: 'string',
                                            pattern: '^(?=\\S).*(?<=\\S)$',
                                            errorMessage:
                                                'Column rule action detail splitOn value should have a minimum length of 1 with no whitespaces on the sides',
                                        },
                                        slice: {
                                            type: 'object',
                                            required: ['ifLengthGreaterThan', 'start', 'end'],
                                            properties: {
                                                ifLengthGreaterThan: {
                                                    type: 'string',
                                                    pattern: '^(?=\\S)[\\d]+(?<=\\S)$',
                                                    errorMessage:
                                                        'Column rule action detail slice ifLengthGreaterThan should have a minimum length of 1 with no whitespaces on the sides and must be a positive integer',
                                                },
                                                start: {
                                                    type: 'string',
                                                    pattern: '^(?=\\S)[\\d]+(?<=\\S)$',
                                                    errorMessage:
                                                        'Column rule action detail slice start should have a minimum length of 1 with no whitespaces on the sides and must be a positive integer',
                                                },
                                                end: {
                                                    type: 'string',
                                                    pattern: '^(?=\\S)[\\d]+(?<=\\S)$',
                                                    errorMessage:
                                                        'Column rule action detail slice end should have a minimum length of 1 with no whitespaces on the sides and must be a positive integer',
                                                },
                                            },
                                        },
                                        to: {
                                            type: 'string',
                                            pattern: 'Number|Date',
                                            errorMessage:
                                                "Column rule action detail to should either be 'Number' or 'Date'",
                                        },
                                        columnNames: {
                                            type: 'string',
                                            pattern: '^(?=\\S)[^,].+[^,](?<=\\S)$',
                                            errorMessage:
                                                'Column rule action detail columnNames should be comma delimited text',
                                        },
                                        joinString: {
                                            type: 'string',
                                            pattern: '^.+$',
                                            errorMessage:
                                                'Column rule action detail joinString should have a minimum length',
                                        },
                                        skipDuplicates: {
                                            type: 'string',
                                            pattern: 'Yes|No',
                                            errorMessage:
                                                "Column rule action detail skipDuplicates should either be 'Yes' or 'No'",
                                        },
                                    },
                                },
                                conditions: {
                                    type: 'array',
                                    items: {
                                        type: 'object',
                                        required: ['operand', 'operator'],
                                        properties: {
                                            operand: {
                                                type: 'string',
                                                pattern: 'Cell',
                                                errorMessage:
                                                    "Column rule action condition operands should only be 'Cell'",
                                            },
                                            operator: {
                                                type: 'string',
                                                pattern: 'Equals|Matches|Has a value',
                                                errorMessage:
                                                    "Column rule action condition operators should either be 'Equals', 'Matches' or 'Has a value'",
                                            },
                                            value: {
                                                type: 'string',
                                                pattern: '^(?=\\S).*(?<=\\S)$',
                                                errorMessage:
                                                    'Column rule action condition values should have a minimum length of 1 with no whitespaces on the sides',
                                            },
                                        },
                                        additionalProperties: false,
                                    },
                                },
                            },
                            additionalProperties: false,
                        },
                    },
                },
                additionalProperties: false,
            },
        },
        rowRules: {
            type: 'array',
            items: {
                type: 'object',
                required: ['actions'],
                properties: {
                    actions: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: ['booleanOperator', 'steps', 'conditions'],
                            properties: {
                                booleanOperator: {
                                    type: 'string',
                                    pattern: 'All|Any',
                                    errorMessage: "Row rule action boolean operators should either be 'All' or 'Any'",
                                },
                                steps: {
                                    type: 'array',
                                    items: {
                                        type: 'object',
                                        required: ['type', 'value'],
                                        properties: {
                                            type: {
                                                type: 'string',
                                                pattern: 'Dropdown|Input',
                                                errorMessage:
                                                    "Row rule action step types should either be 'Dropdown' or 'Input'",
                                            },
                                            value: {
                                                type: 'string',
                                                pattern: '^(?=\\S).*(?<=\\S)$',
                                                errorMessage:
                                                    'Row rule action step values should have a minimum length of 1 with no whitespaces on the sides',
                                            },
                                        },
                                        additionalProperties: false,
                                    },
                                    minItems: 1,
                                    errorMessage: 'Row rule actions should have a minimum of 1 step',
                                },
                                conditions: {
                                    type: 'array',
                                    items: {
                                        type: 'object',
                                        required: ['operand', 'operator'],
                                        properties: {
                                            operand: {
                                                type: 'string',
                                                pattern: 'Cell',
                                                errorMessage:
                                                    "Row rule action condition operands should only be 'Cell'",
                                            },
                                            operandDetail: {
                                                type: 'string',
                                                pattern: '^(?=\\S).*(?<=\\S)$',
                                                errorMessage:
                                                    'Row rule action condition operand details should have a minimum length of 1 with no whitespaces on the sides',
                                            },
                                            operator: {
                                                type: 'string',
                                                pattern: 'Equals|Matches|Has a value|Does not equal|Does not match',
                                                errorMessage:
                                                    "Row rule action condition operators should either be 'Equals', 'Matches', 'Has a value', 'Does not equal' or 'Does not match'",
                                            },
                                            value: {
                                                type: 'string',
                                                pattern: '^(?=\\S).*(?<=\\S)$',
                                                errorMessage:
                                                    'Row rule action condition values should have a minimum length of 1 with no whitespaces on the sides',
                                            },
                                        },
                                        additionalProperties: false,
                                    },
                                },
                            },
                            additionalProperties: false,
                        },
                    },
                },
                additionalProperties: false,
            },
        },
        outputHeaderPosition: {
            type: 'string',
            pattern: 'Top row|Left column',
            errorMessage: "Ruleset header output position should either be 'Top row' or 'Left column'",
        },
        shouldCreateHeadersOnNoMatch: {
            type: 'boolean',
        },
        additionalProperties: false,
    },
};

const ruleSetValidate = ajv.compile(rulesetSchema);

export const getRulesetErrors = (ruleset: Ruleset) => {
    const uniqueErrors = {};

    ruleSetValidate(ruleset);

    const errorText = ajv.errorsText(ruleSetValidate.errors, { separator: ',', dataVar: '*Ruleset' });
    const errorsFlat = errorText.split(',').map((string) => string.trim());

    errorsFlat.forEach((text) => (uniqueErrors[text] = true));

    const errors = Object.keys(uniqueErrors);

    if (errors.includes('No errors')) {
        return [];
    }

    return errors;
};
