import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomModelLabelPage from 'pages/label/containers/customModelLabelPage/customModelLabelPage';

export default function LabelPage() {
    const location = useLocation();
    const path = location.pathname;

    const split = path.split('/');
    const key = split[split.length - 1];

    return <CustomModelLabelPage key={key} />;
}
