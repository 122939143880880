export type SplitPaneSizes = {
    analyzeSplitPaneSize: number[];
    labelSplitPaneSize: number[];
    labelTableSplitPaneSize: number[];
};

export type LabelRouterParams = {
    pageType: 'page' | 'document';
    formId: string;
    formTypeId: string;
    formTypeVersionId: string;
    formTypeVersionPageId: string;
};

export type RouterProps = {
    router: {
        params: LabelRouterParams;
    };
};

export enum AssetLabelingState {
    ManuallyLabeled = 1,
    Trained = 2,
    AutoLabeled = 3,
    AutoLabeledAndAdjusted = 4,
}

export enum PageType {
    page = 'page',
    document = 'document',
}
