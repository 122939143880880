import { FeatureCategory } from 'pages/label/components/imageMap/contracts';
import { Polygon } from './analyzeResult';
import { DocumentMimeType } from '../utils/documentLoader';

export type CustomModel = {
    modelId: string;
    description: string;
    createdDateTime: string;
    apiVersion?: string;
    tags?: {
        modelType?: string;
        [key: string]: string | undefined;
    };
};

export type PrimitiveField = {
    fieldKey: string;
    fieldType: FieldType;
    fieldFormat: FieldFormat;
};

export type ObjectField = {
    fieldKey: string;
    fieldType: FieldType;
    fields: Field[];
    fieldFormat: FieldFormat;
    visualizationHint?: VisualizationHint;
};

export type ArrayField = {
    fieldKey: string;
    fieldType: FieldType;
    itemType: string;
    visualizationHint?: VisualizationHint;
};

export type Field = PrimitiveField | ObjectField | ArrayField;

export type FieldFromDoc = {
    fieldKey: string;
    fieldType: 'string' | 'number' | 'date' | 'time' | 'integer' | 'selectionMark' | 'signature' | 'array' | 'object';
    fieldFormat?:
        | 'not-specified'
        | 'currency'
        | 'decimal'
        | 'decimal-comma-separated'
        | 'no-white-spaces'
        | 'alphanumeric'
        | 'dmy'
        | 'mdy'
        | 'ymd';
    fields?: FieldFromDoc[];
    visualizationHint?: 'horizontal' | 'vertical';
    confidence?: number;
    value: any;
};

export type FieldsWithOrder = Field & { order: number };

export enum FieldType {
    String = 'string',
    Number = 'number',
    Date = 'date',
    Time = 'time',
    Integer = 'integer',
    SelectionMark = 'selectionMark',
    Signature = 'signature',
    Array = 'array',
    Object = 'object',
}

export enum FieldFormat {
    NotSpecified = 'not-specified',
    Currency = 'currency',
    Decimal = 'decimal',
    DecimalCommaSeparated = 'decimal-comma-separated',
    NoWhiteSpaces = 'no-white-spaces',
    Alphanumeric = 'alphanumeric',
    DMY = 'dmy',
    MDY = 'mdy',
    YMD = 'ymd',
}

export enum VisualizationHint {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
}

export type Labels = {
    [documentName: string]: Label[];
};

export type Label = {
    label: string;
    value: LabelValue[];
    labelType?: LabelType;
};

export type LabelValue = {
    boundingBoxes: Polygon[];
    page: number;
    text: string;
};

export type LabelValueCandidate = {
    boundingBoxes: Polygon[];
    page: number;
    text: string;
    category: FeatureCategory;
    alreadyAssignedLabelName?: string;
};

export enum LabelType {
    Words = 'words',
    Region = 'region',
}

export type Definitions = {
    [objectName: string]: ObjectField;
};

export enum TableType {
    dynamic = 'dynamic',
    fixed = 'fixed',
}

export enum HeaderType {
    row = 'row',
    column = 'column',
}

export type IRawFormFile = {
    blobName: string;
    blobUrl: string;
    containerName: string;
    containerPrefix: string;
    extension: string;
    fileGuid: string;
    filename: string;
    formId: string;
    ocrExtract: any;
    ocrExtractResponse: any;
    labels?: { labels: never[] };
    mimetype: DocumentMimeType;
    _dateUpdated: string;
    _id: string;
    uid?: string;
};

export type IFormFieldsRes = {
    fields: Field[];
    definitions: Definitions;
};
