import React from 'react';
import { Skeleton, Typography } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { Menu } from 'components/menu';
import { useNavigate } from 'react-router-dom';
import { FORM_CREATE } from 'data/consts/routes';
import { useMutation } from '@tanstack/react-query';
import { formService } from 'pages/form-crud/data/formService';
import { useContextValues } from 'components/providers/MainProvider';
import { IFormData } from 'data/models/formModel';

import { useRoles } from '../../../hooks';
import { isValuesInArray } from '../../../helpers';

import styles from './form-tree.module.scss';

type Props = {
    isCollapsed?: boolean;
    isLoading?: boolean;
    data: IFormData[] | undefined;
};

const getOptions = ({ navigate, userRoles }) => {
    return [
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography key={'1'} className={styles['option']} onClick={() => navigate(FORM_CREATE)}>
                Create paged form
            </Typography>
        ) : null,
    ].filter((option) => !!option);
};

const getPagedFormOptions = ({ navigate, form, mutators, userRoles }) => {
    return [
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography key={'1'} className={styles['option']} onClick={() => navigate(`/form/${form._id}/edit`)}>
                Edit paged form
            </Typography>
        ) : null,
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'2'}
                className={styles['option']}
                onClick={() => navigate(`/form/${form._id}/types/create`)}
            >
                Add type
            </Typography>
        ) : null,
        <Typography key={'3'} className={styles['option']} onClick={() => mutators.rebuildModel(form._id)}>
            Rebuild model
        </Typography>,
        <Typography key={'4'} className={styles['option']} onClick={() => navigate(`/${form._id}/rebuild-logs`)}>
            Rebuild logs
        </Typography>,
        <Typography key={'5'} className={styles['option']} onClick={() => mutators.trainAllPages(form._id)}>
            Train all pages
        </Typography>,
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'6'}
                className={styles['option']}
                onClick={() => navigate(`/form/${form._id}/model-settings`)}
            >
                Model settings
            </Typography>
        ) : null,
    ].filter((option) => !!option);
};

const getTypeOptions = ({ navigate, form, type, userRoles }) => {
    return [
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'1'}
                className={styles['option']}
                onClick={() => navigate(`/form/${form._id}/types/${type._id}/edit`)}
            >
                Edit type
            </Typography>
        ) : null,
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'2'}
                className={styles['option']}
                onClick={() => navigate(`/form/${form._id}/types/${type._id}/versions/create`)}
            >
                Add versions
            </Typography>
        ) : null,
    ].filter((option) => !!option);
};

const getVersionOptions = ({ navigate, form, type, version, userRoles }) => {
    return [
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'1'}
                className={styles['option']}
                onClick={() => navigate(`/form/${form._id}/types/${type._id}/versions/${version._id}/edit`)}
            >
                Edit version
            </Typography>
        ) : null,
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'2'}
                className={styles['option']}
                onClick={() => navigate(`/form/${form._id}/types/${type._id}/versions/${version._id}/pages/create`)}
            >
                Add page
            </Typography>
        ) : null,
    ].filter((option) => !!option);
};

const getPageOptions = ({ navigate, form, type, version, page, mutators, userRoles }) => {
    return [
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'1'}
                className={styles['option']}
                onClick={() =>
                    navigate(`/form/${form._id}/types/${type._id}/versions/${version._id}/pages/${page._id}/edit`)
                }
            >
                Edit page
            </Typography>
        ) : null,
        <Typography
            key={'2'}
            className={styles['option']}
            onClick={() => navigate(`/label/page/${form._id}/${type._id}/${version._id}/${page._id}`)}
        >
            Label page
        </Typography>,
        <Typography
            key={'3'}
            className={styles['option']}
            onClick={() =>
                mutators.trainPage({
                    formId: form._id,
                    formTypeId: type._id,
                    formTypeVersionId: version._id,
                    formTypeVersionPageId: page._id,
                })
            }
        >
            Train page
        </Typography>,
        <Typography
            key={'4'}
            className={styles['option']}
            onClick={() =>
                navigate(`/${form._id}/types/${type._id}/versions/${version._id}/pages/${page._id}/rebuild-logs`)
            }
        >
            Training logs
        </Typography>,
        isValuesInArray(['admin', 'row-admin'], userRoles) ? (
            <Typography
                key={'5'}
                className={styles['option']}
                onClick={() =>
                    navigate(
                        `/form/${form._id}/types/${type._id}/versions/${version._id}/pages/${page._id}/model-settings`
                    )
                }
            >
                Model settings
            </Typography>
        ) : null,
    ].filter((option) => !!option);
};

export function FormTree({ isCollapsed = false, isLoading = true, data = [] }: Props) {
    const navigate = useNavigate();
    const { notificationApi } = useContextValues();
    const { userRoles } = useRoles();

    const { mutate: mutateRebuildModel } = useMutation({
        mutationFn: (formId: string) => {
            return formService.rebuildModel(formId);
        },
        onSuccess: () => {
            notificationApi.success({
                message: 'Model rebuilding',
                description:
                    "The selected model is rebuilding, if you'd like to view the build logs, you can do so by navigating to 'Rebuild logs'.",
                placement: 'topRight',
            });
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Failed to rebuild model',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    const { mutate: mutateTrainPage } = useMutation({
        mutationFn: (ids: {
            formId: string;
            formTypeId: string;
            formTypeVersionId: string;
            formTypeVersionPageId: string;
        }) => {
            return formService.trainPage(ids);
        },
        onSuccess: () => {
            notificationApi.success({
                message: 'Page training',
                description:
                    "The selected page is training, if you'd like to view the training logs, you can do so by navigating to 'Training logs'.",
                placement: 'topRight',
            });
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Train page',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });
    const { mutate: mutateTrainAllPages } = useMutation({
        mutationFn: (formId: string) => {
            return formService.trainAllPages(formId);
        },
        onSuccess: () => {
            notificationApi.success({
                message: 'Retrain all pages submitted successfully',
                description: 'You can see the status of the retraining in each pages logs',
                placement: 'topRight',
            });
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Error retraining all pages',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    if (isLoading) {
        return <Skeleton.Input active className={`f-width bg-primary br-primary ${styles.skeleton}`} />;
    }

    const pagedForms = data || [];

    const mutators = {
        rebuildModel: mutateRebuildModel,
        trainPage: mutateTrainPage,
        trainAllPages: mutateTrainAllPages,
    };

    return (
        <div className={`${styles.container} ${isCollapsed ? `${styles['container-collapsed']}` : ''}`}>
            <Menu
                id={'/form/create'}
                title={'Paged forms'}
                options={getOptions({ navigate, userRoles })}
                isCollapsed={isCollapsed}
                defaultOpen={true}
            >
                {pagedForms?.map?.((form) => (
                    <Menu
                        key={form._id}
                        id={form._id}
                        title={form.title}
                        options={getPagedFormOptions({ navigate, form, mutators, userRoles })}
                        isCollapsed={isCollapsed}
                        hasActiveHighlight={true}
                    >
                        {form.formTypes?.map?.((type) => (
                            <Menu
                                key={type._id}
                                id={type._id}
                                title={type.title}
                                options={getTypeOptions({ navigate, form, type, userRoles })}
                                isCollapsed={isCollapsed}
                                hasActiveHighlight={true}
                            >
                                {type.formTypeVersions?.map?.((version) => (
                                    <Menu
                                        key={version._id}
                                        id={version._id}
                                        title={version.title}
                                        options={getVersionOptions({ navigate, form, type, version, userRoles })}
                                        isCollapsed={isCollapsed}
                                        hasActiveHighlight={true}
                                    >
                                        {version.formTypeVersionPages?.map?.((page) => (
                                            <Menu
                                                key={page._id}
                                                id={page._id}
                                                title={page.title}
                                                onClick={() =>
                                                    navigate(
                                                        `/label/page/${form._id}/${type._id}/${version._id}/${page._id}`
                                                    )
                                                }
                                                options={getPageOptions({
                                                    navigate,
                                                    form,
                                                    type,
                                                    version,
                                                    page,
                                                    mutators,
                                                    userRoles,
                                                })}
                                                icon={FileOutlined}
                                                isCollapsed={isCollapsed}
                                                isActiveScrollEnabled={true}
                                                hasActiveHighlight={true}
                                            />
                                        ))}
                                    </Menu>
                                ))}
                            </Menu>
                        ))}
                    </Menu>
                ))}
            </Menu>
        </div>
    );
}
