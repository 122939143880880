import * as React from 'react';
import { Modal, Tabs } from 'antd';
import { TransformsTab, TransformTablesTab, TransformedOutput } from './tabs';

import { V3_0_3_AnalyzeResult } from 'pages/label/models/analyzeResult/v3_0_3';
import { IDocument } from 'pages/label/store/documents/documentsTypes';

type Props = {
    formId?: string;
    isOpen: boolean;
    currentDocument: IDocument;
    analyzeResult: V3_0_3_AnalyzeResult | undefined;
    currentFileName: string | undefined;
    onClose: () => void;
};

type State = {
    activeKey: string;
    tabs: any[];
    outputTables: any[];
};

// General
// TODO [Low]: Refactor / clean up. Add enums where applicable.
// TODO [Low]: Might need to add checkbox icons for :unselected: / :selected: content in tables.

// TS Pains
// TODO: Figure out why TS has a problem with differed types, e.g. undefined, then after load, Ruleset. If done,
// renable TS.

// Type Convertors
// TODO: in the column rules mappings, remember to account for multipleCellJoinString, offsetLookup since they're new.

export class TransformModal extends React.Component<Props, State> {
    state = {
        activeKey: 'transform-modal-tab-1',
        tabs: [],
        outputTables: [],
    };

    private onTabChange = (newActiveKey: string) => {
        this.setState({
            activeKey: newActiveKey,
        });
    };

    private onTabEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
        const { onClose } = this.props;

        if (action === 'add') {
            const tabs = this.getTabs();

            tabs.push({
                key: 'transform-modal-tab-3',
                label: 'Transformed output',
                children: <TransformedOutput tables={this.state.outputTables} onClose={onClose} />,
                closable: true,
            });

            this.setState({
                activeKey: 'transform-modal-tab-3',
                tabs: tabs,
            });
        }

        if (action === 'remove') {
            const tabs = this.getTabs();

            this.setState({
                activeKey: 'transform-modal-tab-2',
                tabs: tabs,
                outputTables: [],
            });
        }
    };

    private onTransformAllTables = (outputTables) => {
        this.setState(
            {
                outputTables: outputTables,
            },
            () => this.onTabEdit('', 'add')
        );
    };

    private getTabs = () => {
        const { onClose, currentFileName } = this.props;

        return [
            {
                key: 'transform-modal-tab-1',
                label: 'Transforms',
                children: <TransformsTab onClose={onClose} />,
                closable: false,
            },
            {
                key: 'transform-modal-tab-2',
                label: 'Transform tables',
                children: (
                    <TransformTablesTab
                        formId={this.props.formId}
                        currentDocument={this.props.currentDocument}
                        onClose={onClose}
                        currentFileName={currentFileName}
                        onTransformAllTables={this.onTransformAllTables}
                        onTransformTable={this.onTransformAllTables}
                    />
                ),
                closable: false,
            },
        ];
    };

    public componentDidMount() {
        const tabs = this.getTabs();
        const activeTabKey = tabs[0].key;

        this.setState({
            tabs: tabs,
            activeKey: activeTabKey,
        });
    }

    public render = () => {
        const { isOpen } = this.props;
        const { tabs, activeKey } = this.state;

        return (
            <Modal style={{ top: 20 }} width={1152} open={isOpen} closable={false} footer={null}>
                <Tabs
                    type={'editable-card'}
                    activeKey={activeKey}
                    items={tabs}
                    onChange={this.onTabChange}
                    hideAdd={true}
                    onEdit={this.onTabEdit}
                />
            </Modal>
        );
    };
}
