import { httpService, IHttpService, IHttpRequestConfig } from '../../core/services/httpService';
import { IFormData } from '../models/formModel';

export interface IFormTreeService {
    getFormTreeList: (config?: IHttpRequestConfig) => Promise<IFormData[]>;
    getDocumentList: (config?: IHttpRequestConfig) => Promise<IFormData[]>;
}

export const FormTreeService = (httpService: IHttpService): IFormTreeService => {
    const baseUrl = '/forms';

    return {
        getFormTreeList: async (config?: IHttpRequestConfig) => {
            const res = await httpService.get(`${baseUrl}/tree-view`, config);
            return res?.data;
        },
        getDocumentList: async (config?: IHttpRequestConfig) => {
            const res = await httpService.get(`${baseUrl}?rawQuery={'type': 'Document'}`, config);
            return res?.data;
        },
    };
};

export const formTreeService = FormTreeService(httpService);
