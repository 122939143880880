import React from 'react';
import { Typography } from 'antd';

import styles from './wall.module.scss';

export function Wall() {
    return (
        <Typography className={styles.text}>
            <span className={styles.word}>Optical</span>
            <span className={styles.word}>Character</span>
            <span className={styles.word}>Recognition</span>
            <span className={styles.word}>(OCR)</span>
            <span className={styles.word}>is</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>technology</span>
            <span className={styles.word}>that</span>
            <span className={styles.word}>allows</span>
            <span className={styles.word}>computers</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>interpret</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>convert</span>
            <span className={styles.word}>printed</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>handwritten</span>
            <span className={styles.word}>text</span>
            <span className={styles.word}>into</span>
            <span className={styles.word}>machine-readable</span>
            <span className={styles.word}>data.</span>
            <span className={styles.word}>It</span>
            <span className={styles.word}>enables</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>extraction</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>information</span>
            <span className={styles.word}>from</span>
            <span className={styles.word}>physical</span>
            <span className={styles.word}>documents,</span>
            <span className={styles.word}>such</span>
            <span className={styles.word}>as</span>
            <span className={styles.word}>scanned</span>
            <span className={styles.word}>images</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>photographs,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>converts</span>
            <span className={styles.word}>it</span>
            <span className={styles.word}>into</span>
            <span className={styles.word}>editable</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>searchable</span>
            <span className={styles.word}>text.</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>has</span>
            <span className={styles.word}>found</span>
            <span className={styles.word}>extensive</span>
            <span className={styles.word}>applications</span>
            <span className={styles.word}>in</span>
            <span className={styles.word}>various</span>
            <span className={styles.word}>fields,</span>
            <span className={styles.word}>including</span>
            <span className={styles.word}>document</span>
            <span className={styles.word}>digitization,</span>
            <span className={styles.word}>data</span>
            <span className={styles.word}>entry</span>
            <span className={styles.word}>automation,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>information</span>
            <span className={styles.word}>retrieval.</span>
            <span className={styles.word}>The</span>
            <span className={styles.word}>process</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>involves</span>
            <span className={styles.word}>several</span>
            <span className={styles.word}>stages.</span>
            <span className={styles.word}>First,</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>input</span>
            <span className={styles.word}>document</span>
            <span className={styles.word}>is</span>
            <span className={styles.word}>captured</span>
            <span className={styles.word}>using</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>scanning</span>
            <span className={styles.word}>device,</span>
            <span className={styles.word}>such</span>
            <span className={styles.word}>as</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>flatbed</span>
            <span className={styles.word}>scanner</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>camera.</span>
            <span className={styles.word}>The</span>
            <span className={styles.word}>resulting</span>
            <span className={styles.word}>image</span>
            <span className={styles.word}>is</span>
            <span className={styles.word}>then</span>
            <span className={styles.word}>pre-processed</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>enhance</span>
            <span className={styles.word}>its</span>
            <span className={styles.word}>quality</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>remove</span>
            <span className={styles.word}>any</span>
            <span className={styles.word}>imperfections</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>noise</span>
            <span className={styles.word}>that</span>
            <span className={styles.word}>could</span>
            <span className={styles.word}>hinder</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>recognition</span>
            <span className={styles.word}>process.</span>
            <span className={styles.word}>This</span>
            <span className={styles.word}>step</span>
            <span className={styles.word}>may</span>
            <span className={styles.word}>include</span>
            <span className={styles.word}>operations</span>
            <span className={styles.word}>like</span>
            <span className={styles.word}>noise</span>
            <span className={styles.word}>reduction,</span>
            <span className={styles.word}>image</span>
            <span className={styles.word}>rotation,</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>contrast</span>
            <span className={styles.word}>adjustment.</span>
            <span className={styles.word}>Next,</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>software</span>
            <span className={styles.word}>analyzes</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>image</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>performs</span>
            <span className={styles.word}>character</span>
            <span className={styles.word}>segmentation.</span>
            <span className={styles.word}>It</span>
            <span className={styles.word}>identifies</span>
            <span className={styles.word}>individual</span>
            <span className={styles.word}>characters</span>
            <span className={styles.word}>within</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>image</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>separates</span>
            <span className={styles.word}>them</span>
            <span className={styles.word}>from</span>
            <span className={styles.word}>one</span>
            <span className={styles.word}>another.</span>
            <span className={styles.word}>This</span>
            <span className={styles.word}>step</span>
            <span className={styles.word}>is</span>
            <span className={styles.word}>crucial,</span>
            <span className={styles.word}>especially</span>
            <span className={styles.word}>when</span>
            <span className={styles.word}>dealing</span>
            <span className={styles.word}>with</span>
            <span className={styles.word}>handwritten</span>
            <span className={styles.word}>text</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>documents</span>
            <span className={styles.word}>with</span>
            <span className={styles.word}>complex</span>
            <span className={styles.word}>layouts.</span>
            <span className={styles.word}>After</span>
            <span className={styles.word}>character</span>
            <span className={styles.word}>segmentation,</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>software</span>
            <span className={styles.word}>proceeds</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>recognition</span>
            <span className={styles.word}>stage.</span>
            <span className={styles.word}>It</span>
            <span className={styles.word}>compares</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>extracted</span>
            <span className={styles.word}>characters</span>
            <span className={styles.word}>with</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>database</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>known</span>
            <span className={styles.word}>characters</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>attempts</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>identify</span>
            <span className={styles.word}>each</span>
            <span className={styles.word}>one.</span>
            <span className={styles.word}>This</span>
            <span className={styles.word}>matching</span>
            <span className={styles.word}>process</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>be</span>
            <span className={styles.word}>based</span>
            <span className={styles.word}>on</span>
            <span className={styles.word}>various</span>
            <span className={styles.word}>algorithms,</span>
            <span className={styles.word}>including</span>
            <span className={styles.word}>template</span>
            <span className={styles.word}>matching,</span>
            <span className={styles.word}>statistical</span>
            <span className={styles.word}>analysis,</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>machine</span>
            <span className={styles.word}>learning</span>
            <span className={styles.word}>techniques.</span>
            <span className={styles.word}>In</span>
            <span className={styles.word}>template</span>
            <span className={styles.word}>matching,</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>software</span>
            <span className={styles.word}>compares</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>extracted</span>
            <span className={styles.word}>character</span>
            <span className={styles.word}>with</span>
            <span className={styles.word}>predefined</span>
            <span className={styles.word}>templates</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>known</span>
            <span className={styles.word}>characters.</span>
            <span className={styles.word}>It</span>
            <span className={styles.word}>calculates</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>similarity</span>
            <span className={styles.word}>between</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>character</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>each</span>
            <span className={styles.word}>template,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>closest</span>
            <span className={styles.word}>match</span>
            <span className={styles.word}>is</span>
            <span className={styles.word}>selected.</span>
            <span className={styles.word}>Statistical</span>
            <span className={styles.word}>analysis</span>
            <span className={styles.word}>involves</span>
            <span className={styles.word}>analyzing</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>features</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>patterns</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>characters</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>make</span>
            <span className={styles.word}>probabilistic</span>
            <span className={styles.word}>predictions</span>
            <span className={styles.word}>about</span>
            <span className={styles.word}>their</span>
            <span className={styles.word}>identity.</span>
            <span className={styles.word}>Machine</span>
            <span className={styles.word}>learning</span>
            <span className={styles.word}>techniques</span>
            <span className={styles.word}>employ</span>
            <span className={styles.word}>algorithms</span>
            <span className={styles.word}>that</span>
            <span className={styles.word}>learn</span>
            <span className={styles.word}>from</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>large</span>
            <span className={styles.word}>dataset</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>labeled</span>
            <span className={styles.word}>characters</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>recognize</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>classify</span>
            <span className={styles.word}>new</span>
            <span className={styles.word}>characters</span>
            <span className={styles.word}>accurately.</span>
            <span className={styles.word}>Once</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>characters</span>
            <span className={styles.word}>are</span>
            <span className={styles.word}>recognized,</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>software</span>
            <span className={styles.word}>converts</span>
            <span className={styles.word}>them</span>
            <span className={styles.word}>into</span>
            <span className={styles.word}>machine-readable</span>
            <span className={styles.word}>text.</span>
            <span className={styles.word}>The</span>
            <span className={styles.word}>output</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>be</span>
            <span className={styles.word}>in</span>
            <span className={styles.word}>various</span>
            <span className={styles.word}>formats,</span>
            <span className={styles.word}>such</span>
            <span className={styles.word}>as</span>
            <span className={styles.word}>plain</span>
            <span className={styles.word}>text,</span>
            <span className={styles.word}>rich</span>
            <span className={styles.word}>text</span>
            <span className={styles.word}>format</span>
            <span className={styles.word}>(RTF),</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>hypertext</span>
            <span className={styles.word}>markup</span>
            <span className={styles.word}>language</span>
            <span className={styles.word}>(HTML).</span>
            <span className={styles.word}>The</span>
            <span className={styles.word}>recognized</span>
            <span className={styles.word}>text</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>then</span>
            <span className={styles.word}>be</span>
            <span className={styles.word}>further</span>
            <span className={styles.word}>processed,</span>
            <span className={styles.word}>edited,</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>stored</span>
            <span className={styles.word}>in</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>database</span>
            <span className={styles.word}>for</span>
            <span className={styles.word}>future</span>
            <span className={styles.word}>retrieval.</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>technology</span>
            <span className={styles.word}>has</span>
            <span className={styles.word}>significantly</span>
            <span className={styles.word}>advanced</span>
            <span className={styles.word}>over</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>years,</span>
            <span className={styles.word}>thanks</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>improvements</span>
            <span className={styles.word}>in</span>
            <span className={styles.word}>computer</span>
            <span className={styles.word}>processing</span>
            <span className={styles.word}>power</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>machine</span>
            <span className={styles.word}>learning</span>
            <span className={styles.word}>algorithms.</span>
            <span className={styles.word}>Modern</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>systems</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>handle</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>wide</span>
            <span className={styles.word}>range</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>fonts,</span>
            <span className={styles.word}>languages,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>document</span>
            <span className={styles.word}>layouts.</span>
            <span className={styles.word}>They</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>accurately</span>
            <span className={styles.word}>recognize</span>
            <span className={styles.word}>text</span>
            <span className={styles.word}>from</span>
            <span className={styles.word}>various</span>
            <span className={styles.word}>sources,</span>
            <span className={styles.word}>including</span>
            <span className={styles.word}>printed</span>
            <span className={styles.word}>documents,</span>
            <span className={styles.word}>typewritten</span>
            <span className={styles.word}>text,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>even</span>
            <span className={styles.word}>handwritten</span>
            <span className={styles.word}>notes.</span>
            <span className={styles.word}>However,</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>is</span>
            <span className={styles.word}>not</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>perfect</span>
            <span className={styles.word}>technology,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>its</span>
            <span className={styles.word}>accuracy</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>vary</span>
            <span className={styles.word}>depending</span>
            <span className={styles.word}>on</span>
            <span className={styles.word}>several</span>
            <span className={styles.word}>factors.</span>
            <span className={styles.word}>The</span>
            <span className={styles.word}>quality</span>
            <span className={styles.word}>of</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>input</span>
            <span className={styles.word}>document,</span>
            <span className={styles.word}>such</span>
            <span className={styles.word}>as</span>
            <span className={styles.word}>resolution,</span>
            <span className={styles.word}>lighting</span>
            <span className={styles.word}>conditions,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>document</span>
            <span className={styles.word}>condition,</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>impact</span>
            <span className={styles.word}>the</span>
            <span className={styles.word}>recognition</span>
            <span className={styles.word}>results.</span>
            <span className={styles.word}>Complex</span>
            <span className={styles.word}>layouts,</span>
            <span className={styles.word}>stylized</span>
            <span className={styles.word}>fonts,</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>low-quality</span>
            <span className={styles.word}>scans</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>pose</span>
            <span className={styles.word}>challenges</span>
            <span className={styles.word}>for</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>algorithms.</span>
            <span className={styles.word}>To</span>
            <span className={styles.word}>enhance</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>accuracy,</span>
            <span className={styles.word}>there</span>
            <span className={styles.word}>are</span>
            <span className={styles.word}>techniques</span>
            <span className={styles.word}>that</span>
            <span className={styles.word}>can</span>
            <span className={styles.word}>be</span>
            <span className={styles.word}>employed,</span>
            <span className={styles.word}>such</span>
            <span className={styles.word}>as</span>
            <span className={styles.word}>using</span>
            <span className={styles.word}>intelligent</span>
            <span className={styles.word}>preprocessing</span>
            <span className={styles.word}>algorithms,</span>
            <span className={styles.word}>training</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>models</span>
            <span className={styles.word}>on</span>
            <span className={styles.word}>specific</span>
            <span className={styles.word}>domains</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>languages,</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>combining</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>with</span>
            <span className={styles.word}>other</span>
            <span className={styles.word}>technologies</span>
            <span className={styles.word}>like</span>
            <span className={styles.word}>natural</span>
            <span className={styles.word}>language</span>
            <span className={styles.word}>processing</span>
            <span className={styles.word}>(NLP)</span>
            <span className={styles.word}>for</span>
            <span className={styles.word}>context-based</span>
            <span className={styles.word}>recognition.</span>
            <span className={styles.word}>In</span>
            <span className={styles.word}>conclusion,</span>
            <span className={styles.word}>Optical</span>
            <span className={styles.word}>Character</span>
            <span className={styles.word}>Recognition</span>
            <span className={styles.word}>(OCR)</span>
            <span className={styles.word}>is</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>technology</span>
            <span className={styles.word}>that</span>
            <span className={styles.word}>enables</span>
            <span className={styles.word}>computers</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>interpret</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>convert</span>
            <span className={styles.word}>printed</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>handwritten</span>
            <span className={styles.word}>text</span>
            <span className={styles.word}>into</span>
            <span className={styles.word}>machine-readable</span>
            <span className={styles.word}>data.</span>
            <span className={styles.word}>It</span>
            <span className={styles.word}>plays</span>
            <span className={styles.word}>a</span>
            <span className={styles.word}>crucial</span>
            <span className={styles.word}>role</span>
            <span className={styles.word}>in</span>
            <span className={styles.word}>document</span>
            <span className={styles.word}>digitization,</span>
            <span className={styles.word}>data</span>
            <span className={styles.word}>entry</span>
            <span className={styles.word}>automation,</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>information</span>
            <span className={styles.word}>retrieval.</span>
            <span className={styles.word}>OCR</span>
            <span className={styles.word}>systems</span>
            <span className={styles.word}>have</span>
            <span className={styles.word}>evolved</span>
            <span className={styles.word}>significantly</span>
            <span className={styles.word}>and</span>
            <span className={styles.word}>continue</span>
            <span className={styles.word}>to</span>
            <span className={styles.word}>improve,</span>
            <span className={styles.word}>but</span>
            <span className={styles.word}>they</span>
            <span className={styles.word}>still</span>
            <span className={styles.word}>face</span>
            <span className={styles.word}>challenges</span>
            <span className={styles.word}>in</span>
            <span className={styles.word}>accurately</span>
            <span className={styles.word}>recognizing</span>
            <span className={styles.word}>text</span>
            <span className={styles.word}>from</span>
            <span className={styles.word}>complex</span>
            <span className={styles.word}>documents</span>
            <span className={styles.word}>or</span>
            <span className={styles.word}>under</span>
            <span className={styles.word}>challenging</span>
            <span className={styles.word}>conditions.</span>
        </Typography>
    );
}
