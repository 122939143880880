import * as React from 'react';
import { IList, List, mergeStyleSets, ScrollToMode } from '@fluentui/react';

import { IDocument } from 'pages/label/store/documents/documentsTypes';
import DocumentPreview from './documentPreview';

interface IDocumentPreviewListProps {
    currentDocument: IDocument | null;
    isReadonly: boolean;
    documents: Array<IDocument>;
    onDocumentClick: (index: number, id?: string) => void;
    onDocumentDelete: (name: string, id: string, index: number) => void;
}

export class DocumentPreviewList extends React.PureComponent<IDocumentPreviewListProps> {
    private listRef: React.RefObject<IList>;
    private itemHeight = 124;

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    public componentDidMount() {
        this.styleSets.listStyles.overflowY = 'overlay';
        this.classNames = mergeStyleSets(this.styleSets);
    }

    public componentDidUpdate(prevProps) {
        const { currentDocument } = this.props;

        if (prevProps.currentDocument !== currentDocument && currentDocument) {
            this.scrollCurrentDocToTop();
        }
    }

    private styleSets = {
        focusZone: {
            width: '100%',
            height: '100%',
        },
        listStyles: {
            width: '100%',
            height: '100%',
            overflowY: 'auto',
        },
        listItemStyles: {
            marginTop: 8,
        },
    };

    private classNames = mergeStyleSets(this.styleSets);

    public render() {
        return (
            <List
                componentRef={this.listRef}
                className={this.classNames.listStyles}
                items={this.props.documents}
                onRenderCell={this.onRenderCell}
            />
        );
    }

    private handleClick = (index: number, id?: string) => {
        this.props.onDocumentClick(index, id);
    };

    private handleDocumentDelete = (name: string, id: string, index: number) => {
        this.props.onDocumentDelete(name, id, index);
    };

    private onRenderCell = (item: IDocument | undefined, index: number | undefined) => {
        return item ? (
            <div className={this.classNames.listItemStyles}>
                <DocumentPreview
                    isReadonly={this.props.isReadonly}
                    selected={item.id === this.props.currentDocument?.id}
                    documentName={item.name}
                    documentType={item.type}
                    documentId={item.id}
                    documentUrl={item.url}
                    documentStates={item.states}
                    documentImageSrc={item.thumbnail}
                    onDocumentClick={(id) => this.handleClick(Number(index), id)}
                    onDocumentDelete={(name, id) => this.handleDocumentDelete(name, id, Number(index))}
                />
            </div>
        ) : null;
    };

    private scrollCurrentDocToTop = () => {
        const { currentDocument, documents } = this.props;
        const index = documents.findIndex((doc) => doc.id === currentDocument!.id);
        if (index !== -1) {
            this.listRef.current?.scrollToIndex(index, () => this.itemHeight, ScrollToMode.auto);
        }
    };
}
