import React, { useState, useEffect } from 'react';
import { Skeleton, Dropdown, Button, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { LocalStorageService } from 'core/services';

import styles from './environment-switcher.module.scss';

export function EnvironmentSwitcher({ isCollapsed = false }: { isCollapsed: Boolean }) {
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState('Production');

    const options = [
        { label: 'Production', value: 'Production' },
        { label: 'Staging', value: 'Staging' },
        { label: 'Development', value: 'Development' },
    ];

    const definedEnvironments = {
        Production: true,
        Staging: true,
        Development: true,
    };

    const getItemsFromOptions = () => {
        return options.map((option, index) => ({
            key: index,
            label: option.label,
            value: option.value,
        }));
    };

    const onSelect = (e) => {
        const itemIndex = e?.key;

        const item = getItemsFromOptions()[itemIndex];

        LocalStorageService.setItem('environment', item.value);

        window.location.reload();
    };

    const getMenuFromOptions = () => {
        return {
            items: getItemsFromOptions(),
            onClick: onSelect,
        };
    };

    useEffect(() => {
        const savedEnvironment = LocalStorageService.getItem('environment', 'Production');

        const hasDefinedEnvironment = definedEnvironments[savedEnvironment];

        if (!hasDefinedEnvironment) {
            LocalStorageService.setItem('environment', 'Production');

            window.location.reload();

            return;
        }

        setValue(definedEnvironments[savedEnvironment] ? savedEnvironment : 'Production');
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Skeleton.Input active className={`f-width bg-primary br-primary ${styles['skeleton']}`} />;
    }

    if (isCollapsed) {
        return <div className={styles['container-collapsed']} />;
    }

    return (
        <div className={styles['container']}>
            <Dropdown menu={getMenuFromOptions()} trigger={['click']} className={styles['dropdown']}>
                <Tooltip
                    placement={'rightTop'}
                    title={
                        "Change the OCR training portal environment. If you're unsure, choose production. This does NOT affect the model environments, only the OCR API that's interacted with."
                    }
                    arrow={false}
                    color={'#000'}
                    overlayInnerStyle={{ marginLeft: 20 }}
                    mouseEnterDelay={1}
                >
                    <Button size={'large'}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}>
                            <div>{value}</div>

                            <div style={{ display: 'flex' }}>
                                <DownOutlined className={styles['icon']} />
                            </div>
                        </div>
                    </Button>
                </Tooltip>
            </Dropdown>
        </div>
    );
}
