import React from 'react';

import { useEmail, useRoles } from '../../hooks';
import { roles } from '../../types';

type AuthorizedUser = {
    email: string;
    permissions: {
        create: boolean;
        read: boolean;
        update: boolean;
        delete: boolean;
    };
};

type Props = {
    allowedRoles: roles;
    authorizedUsers: AuthorizedUser[];
    children: React.ReactNode;
};

export const RowLevelGuard = ({ allowedRoles = [], authorizedUsers = [], children }: Props) => {
    const { userRoles } = useRoles();
    const { userEmail } = useEmail();

    for (const allowedRole of allowedRoles) {
        if (userRoles.includes(allowedRole)) {
            return <>{children}</>;
        }
    }

    const authorizedUser = authorizedUsers.find((user) => user.email === userEmail);

    // Should read permissions actually need to be checked, add the logic here. If we do care about reading,
    // authorizedUser.permissions.read should be true to allow children to be passed. Currently we only care that
    // a user has access.

    if (authorizedUser) {
        return <>{children}</>;
    }

    return null;
};
