import { httpService, IHttpService, IHttpRequestConfig } from '../../../core/services/httpService';
import { Definitions, Field, IFormFieldsRes, IRawFormFile, Label } from '../models/customModels';
import { AssetLabelingState } from '../models';

export interface IFormFileService {
    getDocumentFiles: (formId: string, config?: IHttpRequestConfig) => Promise<IRawFormFile[]>;
    getFormPageFiles: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        config?: IHttpRequestConfig
    ) => Promise<IRawFormFile[]>;
    getDocumentFields: (formId: string, config?: IHttpRequestConfig) => Promise<IFormFieldsRes>;
    getPageFields: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormFieldsRes>;
    setDocumentFields: (
        formId: string,
        fields: Field[],
        definitions: Definitions,
        config?: IHttpRequestConfig
    ) => Promise<IFormFieldsRes>;
    setPageFields: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        fields: Field[],
        definitions: Definitions,
        config?: IHttpRequestConfig
    ) => Promise<IFormFieldsRes>;
    extractDocument: (formId: string, fileId: string, config?: IHttpRequestConfig) => Promise<any>;
    extractPage: (
        fileId: string,
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        config?: IHttpRequestConfig
    ) => Promise<any>;
    getDocumentLabels: (formId: string, fileId: string, config?: IHttpRequestConfig) => Promise<any>;
    getPageLabels: (
        fileId: string,
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        config?: IHttpRequestConfig
    ) => Promise<any>;
    setDocumentLabels: (
        formId: string,
        fileId: string,
        data: { schema: string; labelingState?: AssetLabelingState; labels: Label[] },
        config?: IHttpRequestConfig
    ) => Promise<any>;
    setPageLabels: (
        fileId: string,
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        data: { schema: string; labelingState?: AssetLabelingState; labels: Label[] },
        config?: IHttpRequestConfig
    ) => Promise<any>;
    createDocumentFile: (formId: string, data: any, config?: IHttpRequestConfig) => Promise<IRawFormFile>;
    createPageFormFile: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        data: any,
        config?: IHttpRequestConfig
    ) => Promise<IRawFormFile>;
    deleteDocumentFile: (formId: string, fileId: string, config?: IHttpRequestConfig) => Promise<any>;
    deletePageFormFile: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        fileId: string,
        config?: IHttpRequestConfig
    ) => Promise<any>;
    analyzeFormFile: (formId: string, base64: string) => Promise<any>;
    getFile: (url: string, config?: IHttpRequestConfig) => Promise<any>;
}

export const FormFilesService = (httpService: IHttpService): IFormFileService => {
    const baseUrl = '/forms';

    return {
        getDocumentFiles: async (formId: string, config?: IHttpRequestConfig) => {
            const res = await httpService.get(`${baseUrl}/${formId}/files`, config);
            return res?.data;
        },
        getFormPageFiles: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.get(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/files`,
                config
            );
            return res?.data;
        },
        getDocumentFields: async (formId: string, config?: IHttpRequestConfig) => {
            const res = await httpService.get(`${baseUrl}/${formId}/fields`, config);
            return res?.data;
        },
        getPageFields: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.get(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/fields`,
                config
            );
            return res?.data;
        },
        setDocumentFields: async (
            formId: string,
            fields: Field[],
            definitions: Definitions,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.put(`${baseUrl}/${formId}/fields`, { fields, definitions }, config);
            return res?.data;
        },
        setPageFields: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            fields: Field[],
            definitions: Definitions,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.put(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/fields`,
                { fields, definitions },
                config
            );
            return res?.data;
        },
        extractDocument: async (formId: string, fileId, config?: IHttpRequestConfig) => {
            const res = await httpService.post(
                `${baseUrl}/${formId}/files/${fileId}/extract`,
                {
                    model: 'prebuilt-document',
                    forceRebuild: false,
                    useLegacy: false,
                    useResponse: true,
                },
                config
            );
            return res?.data;
        },
        extractPage: async (
            fileId: string,
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.post(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/files/${fileId}/extract`,
                {
                    model: 'prebuilt-document',
                    forceRebuild: false,
                    useLegacy: false,
                    useResponse: true,
                },
                config
            );
            return res?.data;
        },
        getDocumentLabels: async (formId: string, fileId: string, config?: IHttpRequestConfig) => {
            const res = await httpService.get(`${baseUrl}/${formId}/files/${fileId}/labels`, config);
            return res?.data;
        },
        getPageLabels: async (
            fileId: string,
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.get(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/files/${fileId}/labels`,
                config
            );
            return res?.data;
        },
        setDocumentLabels: async (formId: string, fileId: string, data, config?: IHttpRequestConfig) => {
            const res = await httpService.put(`${baseUrl}/${formId}/files/${fileId}/labels`, data, config);
            return res?.data;
        },
        setPageLabels: async (
            fileId: string,
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            data,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.put(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/files/${fileId}/labels`,
                data,
                config
            );
            return res?.data;
        },
        createDocumentFile: async (formId: string, data, config?: IHttpRequestConfig) => {
            const res = await httpService.post(`${baseUrl}/${formId}/files`, data, config);
            return res?.data;
        },
        createPageFormFile: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            data: any,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.post(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/files`,
                data,
                config
            );
            return res?.data;
        },
        deleteDocumentFile: async (formId: string, fileId: string, config?: IHttpRequestConfig) => {
            return await httpService.delete(`${baseUrl}/${formId}/files/${fileId}`, config);
        },
        deletePageFormFile: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            fileId: string,
            config?: IHttpRequestConfig
        ) => {
            return await httpService.delete(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/files/${fileId}`,
                config
            );
        },
        analyzeFormFile: async (formId: string, base64: string) => {
            const newBaseUrl = formId.startsWith('prebuilt-') ? '/models' : baseUrl;
            const res = await httpService.post(`${newBaseUrl}/${formId}/analyze`, base64, {
                headers: {
                    'Content-Type': 'text/plain',
                    Accept: '*/*',
                },
            });
            return res?.data;
        },
        getFile: async (url: string, config?: IHttpRequestConfig) => {
            return await httpService.get(url, config);
        },
    };
};

export const formFilesService = FormFilesService(httpService);
