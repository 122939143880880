import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton, Typography, Select, Tag, Popconfirm } from 'antd';
import { validate as validateUuid } from 'uuid';
import { useContextValues } from 'components/providers/MainProvider';
import { Drawer } from 'components/svg/drawer';
import { ocrModelsService } from 'data/services/ocrModelsService';
import { ChangeLogModal } from './changeLogModal';

import { ModelEnvironment, Model, Models, ModelsInUse, ModelsChangeLog } from 'types';

import styles from './page-model-settings.module.scss';

type State = {
    isLoading: boolean;
    isDevelopmentDeploying: boolean;
    isStagingDeploying: boolean;
    isProductionDeploying: boolean;
    isRebuildEnvironmentApplying: boolean;
    models: Models;
    modelsInUse: ModelsInUse;
    modelsChangeLog: ModelsChangeLog;
    selectedDevelopmentModel: Model;
    selectedStagingModel: Model;
    selectedProductionModel: Model;
    selectedRebuildEnvironment: ModelEnvironment | string;
    isChangeLogModalOpen: boolean;
    shouldAutoSelect: boolean;
};

export function PageModelSettings() {
    const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = useParams();

    const { notificationApi } = useContextValues();

    const [state, setState] = useState<State>({
        isLoading: true,
        isDevelopmentDeploying: false,
        isStagingDeploying: false,
        isProductionDeploying: false,
        isRebuildEnvironmentApplying: false,
        models: [],
        modelsInUse: {},
        modelsChangeLog: [],
        selectedDevelopmentModel: {
            id: '',
            dateCreated: '',
        },
        selectedStagingModel: {
            id: '',
            dateCreated: '',
        },
        selectedProductionModel: {
            id: '',
            dateCreated: '',
        },
        selectedRebuildEnvironment: '',
        isChangeLogModalOpen: false,
        shouldAutoSelect: false,
    });

    const getModelFromModelsById = (modelId, models) => {
        return models.find((model) => model.id === modelId);
    };

    const getModelSelectOptionsFromModels = (
        models: Models,
        changeLog: ModelsChangeLog,
        environment: ModelEnvironment
    ) => {
        const previousEnvironmentModel = getPreviousEnvironmentModel(models, environment, changeLog);
        const latestModel = getLatestModel(models);

        const modelsMapped = models.map((model) => {
            const option = {
                label: getFormattedOptionLabel(model),
                value: model.id,
            };

            if (previousEnvironmentModel && model.id === previousEnvironmentModel.id) {
                option.label = `${getFormattedOptionLabel(model)} (previously deployed)`;
            }

            if (latestModel && model.id === latestModel.id) {
                option.label = `${getFormattedOptionLabel(model)} (latest trained)`;
            }

            if (
                previousEnvironmentModel &&
                model.id === previousEnvironmentModel.id &&
                latestModel &&
                model.id === latestModel.id
            ) {
                option.label = `${getFormattedOptionLabel(model)} (previously deployed) (latest trained)`;
            }

            return option;
        });

        const newestFirst = [...modelsMapped].reverse();

        return newestFirst;
    };

    const getPreviousEnvironmentModel = (
        models: Models,
        environment: ModelEnvironment,
        changeLog: ModelsChangeLog
    ): Model | undefined => {
        if (!Array.isArray(changeLog)) {
            return;
        }

        // Has no previous yet.
        if (changeLog.length <= 1) {
            return;
        }

        const modelIdsByEnvironment = changeLog.map((entry) => entry.modelsInUse[environment]);
        const newestFirst = [...modelIdsByEnvironment].reverse();

        const currentDeployedModelId = newestFirst[0];
        const previousDeployedModelId = newestFirst.find((modelId) => modelId !== currentDeployedModelId);

        return models.find((model) => model.id === previousDeployedModelId);
    };

    const getLatestModel = (models: Models): Model | undefined => {
        if (!Array.isArray(models)) {
            return;
        }

        // Has no latest yet.
        if (models.length <= 1) {
            return;
        }

        return models[models.length - 1];
    };

    const getFormattedOptionLabel = (model: Model) => {
        const id = model.id;
        const date = model.dateCreated;

        const isName = !validateUuid(id);

        if (isName) {
            return id;
        }

        return `${id.substring(0, 8)}... ${date}`;
    };

    const onDevelopmentModelSelect = (value) => {
        const selectedModel = getModelFromModelsById(value, state.models);

        setState({
            ...state,
            selectedDevelopmentModel: selectedModel,
        });
    };

    const onStagingModelSelect = (value) => {
        const selectedModel = getModelFromModelsById(value, state.models);

        setState({
            ...state,
            selectedStagingModel: selectedModel,
        });
    };

    const onProductionModelSelect = (value) => {
        const selectedModel = getModelFromModelsById(value, state.models);

        setState({
            ...state,
            selectedProductionModel: selectedModel,
        });
    };

    const onDevelopmentModelDeploy = async () => {
        try {
            setState({
                ...state,
                isDevelopmentDeploying: true,
            });

            await ocrModelsService.updatePageModelsInUse(
                formId!,
                formTypeId!,
                formTypeVersionId!,
                formTypeVersionPageId!,
                {
                    development: state.selectedDevelopmentModel.id,
                    staging: state.modelsInUse.staging,
                    production: state.modelsInUse.production,
                }
            );

            notificationApi.success({
                message: 'Successfully deployed model to Development',
                description: `The model with id ${state.selectedDevelopmentModel.id} has successfully been deployed to the Development environment.`,
                placement: 'topRight',
            });

            load();
        } catch (e) {
            setState({
                ...state,
                isDevelopmentDeploying: false,
            });

            notificationApi.error({
                message: 'Failed to deploy model to Development',
                // @ts-ignore
                description: e?.response?.data?.message || e?.message,
                placement: 'topRight',
            });
        }
    };

    const onStagingModelDeploy = async () => {
        try {
            setState({
                ...state,
                isStagingDeploying: true,
            });

            await ocrModelsService.updatePageModelsInUse(
                formId!,
                formTypeId!,
                formTypeVersionId!,
                formTypeVersionPageId!,
                {
                    development: state.modelsInUse.development,
                    staging: state.selectedStagingModel.id,
                    production: state.modelsInUse.production,
                }
            );

            notificationApi.success({
                message: 'Successfully deployed model to Staging',
                description: `The model with id ${state.selectedStagingModel.id} has successfully been deployed to the Staging environment.`,
                placement: 'topRight',
            });

            load();
        } catch (e) {
            setState({
                ...state,
                isStagingDeploying: false,
            });

            notificationApi.error({
                message: 'Failed to deploy model to Staging',
                // @ts-ignore
                description: e?.response?.data?.message || e?.message,
                placement: 'topRight',
            });
        }
    };

    const onProductionModelDeploy = async () => {
        try {
            setState({
                ...state,
                isProductionDeploying: true,
            });

            await ocrModelsService.updatePageModelsInUse(
                formId!,
                formTypeId!,
                formTypeVersionId!,
                formTypeVersionPageId!,
                {
                    development: state.modelsInUse.development,
                    staging: state.modelsInUse.staging,
                    production: state.selectedProductionModel.id,
                }
            );

            notificationApi.success({
                message: 'Successfully deployed model to Production',
                description: `The model with id ${state.selectedProductionModel.id} has successfully been deployed to the Production environment.`,
                placement: 'topRight',
            });

            load();
        } catch (e) {
            setState({
                ...state,
                isProductionDeploying: false,
            });

            notificationApi.error({
                message: 'Failed to deploy model to Production',
                // @ts-ignore
                description: e?.response?.data?.message || e?.message,
                placement: 'topRight',
            });
        }
    };

    const onChangeLogModalClick = () => {
        setState({
            ...state,
            isChangeLogModalOpen: !state.isChangeLogModalOpen,
        });
    };

    const autoSelectModels = () => {
        const model = getLatestModel(state.models) || state.models[0];

        if (!state.shouldAutoSelect) {
            return;
        }

        if (!model) {
            setState({
                ...state,
                shouldAutoSelect: false,
            });

            return;
        }

        setState({
            ...state,
            selectedDevelopmentModel: {
                id: model.id,
                dateCreated: model.dateCreated,
            },
            selectedStagingModel: {
                id: model.id,
                dateCreated: model.dateCreated,
            },
            selectedProductionModel: {
                id: model.id,
                dateCreated: model.dateCreated,
            },
            shouldAutoSelect: false,
        });
    };

    const load = async () => {
        setState({
            ...state,
            isLoading: true,
        });

        const page = await ocrModelsService.getPage(formId!, formTypeId!, formTypeVersionId!, formTypeVersionPageId!);

        setState({
            ...state,
            isLoading: false,
            isDevelopmentDeploying: false,
            isStagingDeploying: false,
            isProductionDeploying: false,
            isRebuildEnvironmentApplying: false,
            models: page.models,
            modelsInUse: page.modelsInUse,
            modelsChangeLog: page.modelsChangeLog,
            selectedDevelopmentModel: {
                id: '',
                dateCreated: '',
            },
            selectedStagingModel: {
                id: '',
                dateCreated: '',
            },
            selectedProductionModel: {
                id: '',
                dateCreated: '',
            },
            selectedRebuildEnvironment: '',
            isChangeLogModalOpen: false,
            shouldAutoSelect: true,
        });
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formId, formTypeId, formTypeVersionId, formTypeVersionPageId]);

    useEffect(() => {
        autoSelectModels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.shouldAutoSelect]);

    if (state.isLoading) {
        return (
            <>
                <Skeleton active />
                <Skeleton active className="mt-8" />
                <Skeleton active className="mt-8" />
            </>
        );
    }

    if (!state.models.length) {
        return (
            <div>
                <div className={styles['heading-container']}>
                    <Typography.Paragraph className={styles.heading}>Page model settings</Typography.Paragraph>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 16,
                            }}
                        >
                            <Drawer />
                        </div>
                        <div>
                            <span>There are currently no trained models to configure</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className={styles['heading-container']}>
                <div>
                    <Typography.Paragraph className={styles.heading}>Page model settings</Typography.Paragraph>
                </div>
                <Button onClick={onChangeLogModalClick}>View Changelog</Button>
            </div>

            <div className={styles.section}>
                <div className={styles['section-heading-container']}>
                    <Tag className={styles['section-heading-tag']} color={'green'}>
                        Development
                    </Tag>
                    <span className={styles['section-heading-text']}>Environment</span>
                </div>

                <div className={styles['info']}>
                    <div style={{ marginBottom: 32 }}>
                        {getModelFromModelsById(state.modelsInUse.development, state.models) ? (
                            <>
                                <div className={styles['details-heading']}>Deployed model details</div>
                                <div className={styles['details']}>
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>Id:</span>
                                        <span className={styles['detail-text']}>{state.modelsInUse.development}</span>
                                    </div>
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>Date created:</span>
                                        <span className={styles['detail-text']}>
                                            {
                                                getModelFromModelsById(state.modelsInUse.development, state.models)
                                                    .dateCreated
                                            }
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>
                                <span>No model is deployed currently.</span>
                            </div>
                        )}
                    </div>

                    <div style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}>
                        <span className={styles['detail-title']}>Model to deploy</span>
                        <Select
                            style={{ flex: 1 }}
                            placeholder={'None selected'}
                            value={state.selectedDevelopmentModel.id || undefined}
                            onSelect={onDevelopmentModelSelect}
                            options={getModelSelectOptionsFromModels(
                                state.models,
                                state.modelsChangeLog,
                                'development'
                            )}
                        />
                    </div>

                    {!!state.selectedDevelopmentModel.id && (
                        <div className={styles['details-container']}>
                            <div className={styles['details-heading']}>Details</div>
                            <div className={styles['details']}>
                                <div className={styles['detail']}>
                                    <span className={styles['detail-title']}>Id:</span>
                                    <span className={styles['detail-text']}>{state.selectedDevelopmentModel.id}</span>
                                </div>
                                <div className={styles['detail']}>
                                    <span className={styles['detail-title']}>Date created:</span>
                                    <span className={styles['detail-text']}>
                                        {state.selectedDevelopmentModel.dateCreated}
                                    </span>
                                </div>
                                {state.selectedDevelopmentModel.id === state.modelsInUse.development && (
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>State:</span>
                                        <span className={styles['detail-text']}>Currently deployed</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles['button-container']}>
                    <Button
                        type={'primary'}
                        onClick={onDevelopmentModelDeploy}
                        disabled={
                            !state.selectedDevelopmentModel.id ||
                            state.selectedDevelopmentModel.id === state.modelsInUse.development
                        }
                        loading={state.isDevelopmentDeploying}
                    >
                        Deploy
                    </Button>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles['section-heading-container']}>
                    <Tag className={styles['section-heading-tag']} color={'gold'}>
                        Staging
                    </Tag>
                    <span className={styles['section-heading-text']}>Environment</span>
                </div>

                <div className={styles['info']}>
                    <div style={{ marginBottom: 32 }}>
                        {getModelFromModelsById(state.modelsInUse.staging, state.models) ? (
                            <>
                                <div className={styles['details-heading']}>Deployed model details</div>
                                <div className={styles['details']}>
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>Id:</span>
                                        <span className={styles['detail-text']}>{state.modelsInUse.staging}</span>
                                    </div>
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>Date created:</span>
                                        <span className={styles['detail-text']}>
                                            {
                                                getModelFromModelsById(state.modelsInUse.staging, state.models)
                                                    .dateCreated
                                            }
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>
                                <span>No model is deployed currently.</span>
                            </div>
                        )}
                    </div>

                    <div style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}>
                        <span className={styles['detail-title']}>Model to deploy</span>
                        <Select
                            style={{ flex: 1 }}
                            placeholder={'None selected'}
                            value={state.selectedStagingModel.id || undefined}
                            onSelect={onStagingModelSelect}
                            options={getModelSelectOptionsFromModels(state.models, state.modelsChangeLog, 'staging')}
                        />
                    </div>

                    {!!state.selectedStagingModel.id && (
                        <div className={styles['details-container']}>
                            <div className={styles['details-heading']}>Details</div>
                            <div className={styles['details']}>
                                <div className={styles['detail']}>
                                    <span className={styles['detail-title']}>Id:</span>
                                    <span className={styles['detail-text']}>{state.selectedStagingModel.id}</span>
                                </div>
                                <div className={styles['detail']}>
                                    <span className={styles['detail-title']}>Date created:</span>
                                    <span className={styles['detail-text']}>
                                        {state.selectedStagingModel.dateCreated}
                                    </span>
                                </div>
                                {state.selectedStagingModel.id === state.modelsInUse.staging && (
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>State:</span>
                                        <span className={styles['detail-text']}>Currently deployed</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles['button-container']}>
                    <Button
                        type={'primary'}
                        onClick={onStagingModelDeploy}
                        disabled={
                            !state.selectedStagingModel.id ||
                            state.selectedStagingModel.id === state.modelsInUse.staging
                        }
                        loading={state.isStagingDeploying}
                    >
                        Deploy
                    </Button>
                </div>
            </div>

            <div className={styles.section}>
                <div className={styles['section-heading-container']}>
                    <Tag className={styles['section-heading-tag']} color={'red'}>
                        Production
                    </Tag>
                    <span className={styles['section-heading-text']}>Environment</span>
                </div>

                <div className={styles['info']}>
                    <div style={{ marginBottom: 32 }}>
                        {getModelFromModelsById(state.modelsInUse.production, state.models) ? (
                            <>
                                <div className={styles['details-heading']}>Deployed model details</div>
                                <div className={styles['details']}>
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>Id:</span>
                                        <span className={styles['detail-text']}>{state.modelsInUse.production}</span>
                                    </div>
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>Date created:</span>
                                        <span className={styles['detail-text']}>
                                            {
                                                getModelFromModelsById(state.modelsInUse.production, state.models)
                                                    .dateCreated
                                            }
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>
                                <span>No model is deployed currently.</span>
                            </div>
                        )}
                    </div>

                    <div style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}>
                        <span className={styles['detail-title']}>Model to deploy</span>
                        <Select
                            style={{ flex: 1 }}
                            placeholder={'None selected'}
                            value={state.selectedProductionModel.id || undefined}
                            onSelect={onProductionModelSelect}
                            options={getModelSelectOptionsFromModels(state.models, state.modelsChangeLog, 'production')}
                        />
                    </div>

                    {!!state.selectedProductionModel.id && (
                        <div className={styles['details-container']}>
                            <div className={styles['details-heading']}>Details</div>
                            <div className={styles['details']}>
                                <div className={styles['detail']}>
                                    <span className={styles['detail-title']}>Id:</span>
                                    <span className={styles['detail-text']}>{state.selectedProductionModel.id}</span>
                                </div>
                                <div className={styles['detail']}>
                                    <span className={styles['detail-title']}>Date created:</span>
                                    <span className={styles['detail-text']}>
                                        {state.selectedProductionModel.dateCreated}
                                    </span>
                                </div>
                                {state.selectedProductionModel.id === state.modelsInUse.production && (
                                    <div className={styles['detail']}>
                                        <span className={styles['detail-title']}>State:</span>
                                        <span className={styles['detail-text']}>Currently deployed</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles['button-container']}>
                    <Popconfirm
                        title={'Deploy model to Production'}
                        description={'Are you sure you want to deploy this model to the Production environment?'}
                        onConfirm={onProductionModelDeploy}
                        okText={'Yes'}
                        cancelText={'No'}
                    >
                        <Button
                            type={'primary'}
                            disabled={
                                !state.selectedProductionModel.id ||
                                state.selectedProductionModel.id === state.modelsInUse.production
                            }
                            loading={state.isProductionDeploying}
                        >
                            Deploy
                        </Button>
                    </Popconfirm>
                </div>
            </div>

            <ChangeLogModal
                log={state.modelsChangeLog}
                isOpen={state.isChangeLogModalOpen}
                onClose={onChangeLogModalClick}
            />
        </div>
    );
}
