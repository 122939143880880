import { constants } from 'data/consts/constants';
import { Definitions, Field, Labels } from 'pages/label/models/customModels';
import { StorageProvider } from 'pages/label/providers/storageProvider';
import { IStorageProvider } from 'pages/label/providers/storageProvider';
import { IAssetService } from './assetService';
import { AssetLabelingState, LabelRouterParams } from '../../models';
import { formFilesService } from '../formFilesService';

export class CustomModelAssetService implements IAssetService {
    private storageProvider: IStorageProvider;
    private fieldsSchema: string = constants.fieldsSchema;
    private labelsSchema: string = constants.labelsSchema;

    constructor() {
        this.storageProvider = new StorageProvider();
    }

    /**
     * Fetch all documents labels.json.
     * @param documents - The documents to be fetched.
     */
    public async fetchAllDocumentLabels(params: LabelRouterParams): Promise<Labels> {
        let res;

        if (params.pageType === 'page') {
            res = await formFilesService.getFormPageFiles(
                params.formId,
                params.formTypeId as string,
                params.formTypeVersionId as string,
                params.formTypeVersionPageId as string
            );
        }

        if (params.pageType === 'document') {
            res = await formFilesService.getDocumentFiles(params.formId);
        }

        const result = {};

        res.forEach((document) => {
            result[document._id] = document?.labels?.labels || [];
        });

        return result;
    }

    /**
     * Update fields.json.
     * @param fields - The updated fields.
     * @param definitions - The updated definitions.
     */
    public async updateFields(fields: Field[], definitions: Definitions): Promise<void> {
        const urlWithoutSearch = window.location.href.split('?')?.[0];
        const params = urlWithoutSearch.split('/').splice(4);

        if (params[0] === 'document') {
            await formFilesService.setDocumentFields(params[1], fields, definitions);
        } else if (params[0] === 'page') {
            await formFilesService.setPageFields(params[1], params[2], params[3], params[4], fields, definitions);
        }
    }

    /**
     * Update labels.json for target documents.
     * @param labels - A mapping of document name and its labels.
     */
    public async updateDocumentLabels(
        labels: Labels,
        fileId: string,
        params: LabelRouterParams,
        labelingState?: AssetLabelingState
    ): Promise<void> {
        const writePromises = Object.entries(labels).map(([documentName, labels]) => {
            if (params) {
                const { pageType, formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = params;

                if (pageType === 'document') {
                    return formFilesService.setDocumentLabels(formId, fileId!, {
                        labels,
                        labelingState: labelingState!,
                        schema: this.labelsSchema,
                    });
                } else if (pageType === 'page') {
                    return formFilesService.setPageLabels(
                        fileId!,
                        formId,
                        formTypeId,
                        formTypeVersionId,
                        formTypeVersionPageId,
                        {
                            labels,
                            labelingState: labelingState!,
                            schema: this.labelsSchema,
                        }
                    );
                } else {
                    return null;
                }
            }
            return null;
        });
        await Promise.all(writePromises);
    }

    public async updateAllDocumentLables(labelsMap: Labels, params: LabelRouterParams): Promise<void> {
        const { pageType, formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = params;

        let promises: any[] = [];

        if (pageType === 'document') {
            promises = Object.entries(labelsMap).map(([fileId, labels]) => {
                return formFilesService.setDocumentLabels(formId, fileId!, {
                    labels,
                    schema: this.labelsSchema,
                });
            });
        }

        if (pageType === 'page') {
            promises = Object.entries(labelsMap).map(([fileId, labels]) => {
                return formFilesService.setPageLabels(
                    fileId!,
                    formId,
                    formTypeId,
                    formTypeVersionId,
                    formTypeVersionPageId,
                    {
                        labels,
                        schema: this.labelsSchema,
                    }
                );
            });
        }

        await Promise.all(promises);
    }
}
