import UTIF from 'utif';
import { DocumentStatus, IDocument, IRawDocument } from 'pages/label/store/documents/documentsTypes';
import { loadCanvasToBlob, loadUrlToArrayBuffer } from 'pages/label/utils/index';
import { IDocumentLoader } from './index';
import { ICanvas } from 'pages/label/store/canvas/canvas';

export class TiffLoader implements IDocumentLoader {
    private doc: IRawDocument;
    private tiffs: any[] = [];
    constructor(doc: IRawDocument) {
        this.doc = doc;
    }

    public async setup(): Promise<void> {
        if (this.doc.url) {
            this.tiffs = this.parseTiffData(await loadUrlToArrayBuffer(this.doc.url as string));
        } else {
            this.tiffs = this.parseTiffData(this.base64ToArrayBuffer(this.doc.file2Base64));
        }
    }

    private base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes.buffer;
    }

    public async loadDocumentMeta(): Promise<IDocument> {
        const firstPage = await this.loadTiffPage(this.tiffs, 1);
        return {
            ...this.doc,
            thumbnail: firstPage.url,
            numPages: this.tiffs.length,
            currentPage: 1,
            states: { loadingStatus: DocumentStatus.Loaded },
        };
    }

    public async loadDocumentPage(pageNumber: number): Promise<ICanvas> {
        const tiffImage = this.tiffs[pageNumber - 1];
        const canvas = this.renderTiffToCanvas(tiffImage);
        const blob = await loadCanvasToBlob(canvas);
        return { imageUrl: window.URL.createObjectURL(blob), width: canvas.width, height: canvas.height, angle: 0 };
    }

    private parseTiffData(tiffData: ArrayBuffer): any[] {
        const tiffImages = UTIF.decode(tiffData);
        for (const tiffImage of tiffImages) {
            UTIF.decodeImage(tiffData, tiffImage);
        }

        return tiffImages;
    }

    private renderTiffToCanvas(tiffImage): HTMLCanvasElement {
        const rgbData = new Uint8ClampedArray(UTIF.toRGBA8(tiffImage).buffer);
        const imageData = new ImageData(rgbData, tiffImage.width, tiffImage.height);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = tiffImage.height;
        canvas.width = tiffImage.width;
        context?.putImageData(imageData, 0, 0);

        return canvas;
    }

    private async loadTiffPage(tiffs: any[], page: number) {
        const tiffImage = tiffs[page - 1];
        const canvas = this.renderTiffToCanvas(tiffImage);
        const blob = await loadCanvasToBlob(canvas);
        return { url: window.URL.createObjectURL(blob), width: canvas.width, height: canvas.height };
    }
}
