import { httpService, IHttpService, IHttpRequestConfig } from '../../../core/services/httpService';
import {
    IFormValues,
    IFormData,
    IFormTypeValues,
    IFormTypeData,
    IFormTypeVersionValues,
    IFormTypeVersionData,
    IFormTypeVersionPageValues,
    IFormTypeVersionPageData,
} from './model';
import { getAuthorizedUsersFromStr } from '../../../helpers';

export interface IFormService {
    rebuildModel: (formId: string, config?: IHttpRequestConfig) => Promise<{ rebuildLogId: string }>;
    trainPage: (
        ids: {
            formId: string;
            formTypeId: string;
            formTypeVersionId: string;
            formTypeVersionPageId: string;
        },
        config?: IHttpRequestConfig
    ) => Promise<{ trainingLogId: string }>;
    trainAllPages: (formId: string, config?: IHttpRequestConfig) => Promise<void>;
    createForm: (values: IFormValues, config?: IHttpRequestConfig) => Promise<IFormData>;
    updateForm: (values: IFormValues, id: string, config?: IHttpRequestConfig) => Promise<IFormData>;
    getForm: (id?: string, config?: IHttpRequestConfig) => Promise<IFormData>;
    createFormType: (values: IFormTypeValues, id: string, config?: IHttpRequestConfig) => Promise<IFormTypeData>;
    updateFormType: (
        values: IFormTypeValues,
        formId: string,
        formTypeId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormTypeData>;
    getFormType: (formId: string, formTypeId: string, config?: IHttpRequestConfig) => Promise<IFormTypeData>;
    createFormTypeVersion: (
        values: IFormTypeVersionValues,
        formId: string,
        formTypeId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormTypeVersionData>;
    updateFormTypeVersion: (
        values: IFormTypeVersionValues,
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormTypeVersionData>;
    getFormTypeVersion: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormTypeVersionData>;
    createFormTypeVersionPage: (
        values: IFormTypeVersionPageValues,
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormTypeVersionPageData>;
    updateFormTypeVersionPage: (
        values: IFormTypeVersionPageValues,
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormTypeVersionPageData>;
    getFormTypeVersionPage: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        config?: IHttpRequestConfig
    ) => Promise<IFormTypeVersionPageData>;
}

export const FormService = (httpService: IHttpService): IFormService => {
    const baseUrl = '/forms';

    return {
        rebuildModel: async (formId: string, config?: IHttpRequestConfig) => {
            const res = await httpService.post(
                `${baseUrl}/${formId}/rebuild-model`,
                {
                    forceExtract: false,
                    rebuildModels: false,
                },
                config
            );
            return res?.data;
        },
        trainPage: async (
            { formId, formTypeId, formTypeVersionId, formTypeVersionPageId },
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.post(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/train`,
                {
                    forceExtract: false,
                },
                config
            );
            return res?.data;
        },
        trainAllPages: async (formId: string, config?: IHttpRequestConfig) => {
            await httpService.post(
                `${baseUrl}/${formId}/train-all`,
                {
                    forceExtract: false,
                },
                config
            );
        },
        createForm: async (values: IFormValues, config?: IHttpRequestConfig) => {
            const res = await httpService.post(baseUrl, values, config);
            return res?.data;
        },
        updateForm: async (values: IFormValues, id: string, config?: IHttpRequestConfig) => {
            values.authorizedUsers = getAuthorizedUsersFromStr(values.authorizedUsers);

            const res = await httpService.put(`${baseUrl}/${id}`, values, config);

            return res?.data;
        },
        getForm: async (id?: string, config?: IHttpRequestConfig) => {
            const res = await httpService.get(`${baseUrl}/${id}`, config);
            return res?.data;
        },
        createFormType: async (values: IFormTypeValues, id: string, config?: IHttpRequestConfig) => {
            const res = await httpService.post(`${baseUrl}/${id}/types`, values, config);
            return res?.data;
        },
        updateFormType: async (
            values: IFormTypeValues,
            formId: string,
            formTypeId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.put(`${baseUrl}/${formId}/types/${formTypeId}`, values, config);
            return res?.data;
        },
        getFormType: async (formId: string, formTypeId: string, config?: IHttpRequestConfig) => {
            const res = await httpService.get(`${baseUrl}/${formId}/types/${formTypeId}`, config);
            return res?.data;
        },
        createFormTypeVersion: async (
            values: IFormTypeVersionValues,
            formId: string,
            formTypeId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.post(`${baseUrl}/${formId}/types/${formTypeId}/versions`, values, config);
            return res?.data;
        },
        updateFormTypeVersion: async (
            values: IFormTypeVersionValues,
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.put(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}`,
                values
            );
            return res?.data;
        },
        getFormTypeVersion: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.get(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}`,
                config
            );
            return res?.data;
        },
        createFormTypeVersionPage: async (
            values: IFormTypeVersionValues,
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.post(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages`,
                values,
                config
            );
            return res?.data;
        },
        updateFormTypeVersionPage: async (
            values: IFormTypeVersionValues,
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.put(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}`,
                values,
                config
            );
            return res?.data;
        },
        getFormTypeVersionPage: async (
            formId: string,
            formTypeId: string,
            formTypeVersionId: string,
            formTypeVersionPageId: string,
            config?: IHttpRequestConfig
        ) => {
            const res = await httpService.get(
                `${baseUrl}/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}`,
                config
            );
            return res?.data;
        },
    };
};

export const formService = FormService(httpService);
