import * as React from 'react';
import { Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { Select } from './select';
import { Input } from './input';
import { hasWhiteSpaceAtEdges, isOnlyWhiteSpace } from 'helpers';

import { IdentificationCondition, SearchMode } from 'types';

import styles from './idCondition.module.scss';

type DropdownItem = {
    value: string;
    label: string;
};

type Props = {
    index: number;
    searchMode: SearchMode;
    description: IdentificationCondition;
    columnAmount: number;
    onOperandChange: (item: DropdownItem, index: number) => void;
    onOperatorChange: (item: DropdownItem, index: number) => void;
    onValueChange: (text: string, index: number) => void;
    onDelete: (index: number) => void;
};

export class IdCondition extends React.PureComponent<Props> {
    private getOperandDropdown = (searchMode: SearchMode, columnAmount: number) => {
        if (searchMode === 'Column names') {
            const items: { value: string; label: string }[] = [];

            for (let i = 1; i <= columnAmount; i++) {
                items.push({ value: `Column name ${i}`, label: `Column name ${i}` });
            }

            return items;
        }

        if (searchMode === 'Table structure') {
            return [
                { value: 'Row count', label: 'Row count' },
                { value: 'Column count', label: 'Column count' },
                { value: 'Table index', label: 'Table index' },
                { value: 'Content', label: 'Content' },
            ];
        }

        return [];
    };

    private getOperatorDropdown = () => {
        return [
            { value: 'Equals', label: 'Equals' },
            { value: 'Matches', label: 'Matches' },
        ];
    };

    private getIdCondition = () => {
        const {
            index,
            searchMode,
            description,
            columnAmount,
            onOperandChange,
            onOperatorChange,
            onValueChange,
            onDelete,
        } = this.props;

        const { operand, operator, value } = description;

        const items: React.ReactNode[] = [];

        if (operand) {
            items.push(
                <div key={`id-condition-operand-${index}`} className={styles['item']}>
                    <Select
                        className={styles['select']}
                        options={this.getOperandDropdown(searchMode, columnAmount)}
                        onChange={(value) => {
                            const item = this.getOperandDropdown(searchMode, columnAmount).find(
                                (option) => option.value === value
                            );

                            return onOperandChange(item!, index);
                        }}
                        value={operand}
                    />
                </div>
            );
        }

        if (operator) {
            items.push(
                <div key={`id-condition-operator-${index}`} className={styles['item']}>
                    <Select
                        className={styles['select']}
                        options={this.getOperatorDropdown()}
                        onChange={(value) => {
                            const item = this.getOperatorDropdown().find((option) => option.value === value);

                            return onOperatorChange(item!, index);
                        }}
                        value={operator}
                    />
                </div>
            );
        }

        items.push(
            <div key={`id-condition-value-${index}`} className={styles['item-flex']}>
                {typeof value === 'string' && (
                    <Input
                        placeholder={'Some value'}
                        value={value}
                        isValueRegex={operator === 'Matches'}
                        onChange={(e) => onValueChange(e.target.value, index)}
                        validator={(text) => {
                            if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                return 'Please enter a valid value';
                            }

                            return undefined;
                        }}
                    />
                )}
                {!value && typeof value !== 'string' && (
                    <Input
                        disabled={true}
                        placeholder={''}
                        value={value}
                        isValueRegex={operator === 'Matches'}
                        onChange={(e) => onValueChange(e.target.value, index)}
                    />
                )}
            </div>
        );

        items.push(
            <div key={`id-condition-delete-${index}`} className={styles['button-container']}>
                <Button
                    className={styles['button']}
                    icon={<MinusOutlined style={{ fontSize: 10 }} />}
                    onClick={() => onDelete(index)}
                />
            </div>
        );

        return items;
    };

    public render = () => {
        return <div className={styles['row']}>{this.getIdCondition()}</div>;
    };
}
