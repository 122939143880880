export function file2Base64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {};
        reader.onloadend = () => {
            //@ts-ignore
            const allData = reader.result.toString();
            if (!allData) {
                reject(new Error('reader.result was empty'));
            }
            //resolve(allData);
            const startIndex = allData.indexOf(',') + 1;
            const fileData = allData.substring(startIndex);
            resolve(fileData);
        };
        reader.onerror = (error) => reject(error);
    });
}

export function getFileFromBlob(blob: any, fileName: string): File {
    return new File([blob], fileName);
}
