import * as React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { ColumnAction } from './columnAction';
import { ColumnOffsetLookup } from './columnOffsetLookup';
import { ColumnMapping } from './columnMapping';

import { ColumnRule as ColumnRuleType } from 'types';

import styles from './columnRule.module.scss';

type DropdownItem = {
    value: string;
    label: string;
};

type Props = {
    ruleIndex: number;
    description: ColumnRuleType;
    onRuleDelete: (ruleIndex: number) => void;
    onRuleMoveUp: (ruleIndex: number) => void;
    onRuleMoveDown: (ruleIndex: number) => void;
    onMappingTypeClick: (item: DropdownItem, ruleIndex: number) => void;
    onMappingNameFromChange: (text: string, ruleIndex: number) => void;
    onMappingNameToChange: (text: string, ruleIndex: number) => void;
    onMappingNameFromRowIndexChange: (text: string, ruleIndex: number) => void;
    onMappingNameFromColumnIndexChange: (text: string, ruleIndex: number) => void;
    onActionBooleanOperatorClick: (item: DropdownItem, ruleIndex: number, actionIndex: number) => void;
    onActionDelete: (ruleIndex: number, actionIndex: number) => void;
    onActionMoveUp: (ruleIndex: number, actionIndex: number) => void;
    onActionMoveDown: (ruleIndex: number, actionIndex: number) => void;
    onActionCreate: (ruleIndex: number) => void;

    onActionTypeChange: (value: string, ruleIndex: number, actionIndex: number) => void;
    onActionSplitOnChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onActionSliceCreate: (ruleIndex: number, actionIndex: number) => void;
    onActionSliceDelete: (ruleIndex: number, actionIndex: number) => void;
    onActionSliceGreaterThanChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onActionSliceStartChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onActionSliceEndChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onActionToChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onActionColumnNamesChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onActionJoinStringChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onActionSkipDuplicatesChange: (value: string, ruleIndex: number, actionIndex: number) => void;

    onConditionOperandChange: (
        item: DropdownItem,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionOperatorChange: (
        item: DropdownItem,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionValueChange: (text: string, ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onConditionDelete: (ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onConditionCreate: (ruleIndex: number, actionIndex: number) => void;
    onColumnOffsetLookupCreate: (ruleIndex: number) => void;
    onColumnOffsetLookupXCreate: (ruleIndex: number) => void;
    onColumnOffsetLookupXDelete: (ruleIndex: number) => void;
    onColumnOffsetLookupXChange: (text: string, ruleIndex: number) => void;
    onColumnOffsetLookupYCreate: (ruleIndex: number) => void;
    onColumnOffsetLookupYDelete: (ruleIndex: number) => void;
    onColumnOffsetLookupYChange: (text: string, ruleIndex: number) => void;
};

export class ColumnRule extends React.PureComponent<Props> {
    private getColumnRule = () => {
        const {
            ruleIndex,
            description,
            onRuleDelete,
            onRuleMoveUp,
            onRuleMoveDown,
            onMappingTypeClick,
            onMappingNameFromChange,
            onMappingNameToChange,
            onMappingNameFromRowIndexChange,
            onMappingNameFromColumnIndexChange,
            onActionBooleanOperatorClick,
            onActionDelete,
            onActionMoveUp,
            onActionMoveDown,
            onActionCreate,
            onActionTypeChange,
            onActionSplitOnChange,
            onActionSliceCreate,
            onActionSliceDelete,
            onActionSliceGreaterThanChange,
            onActionSliceStartChange,
            onActionSliceEndChange,
            onActionToChange,
            onActionColumnNamesChange,
            onActionJoinStringChange,
            onActionSkipDuplicatesChange,
            onConditionOperandChange,
            onConditionOperatorChange,
            onConditionValueChange,
            onConditionDelete,
            onConditionCreate,
            onColumnOffsetLookupCreate,
            onColumnOffsetLookupXCreate,
            onColumnOffsetLookupXDelete,
            onColumnOffsetLookupXChange,
            onColumnOffsetLookupYCreate,
            onColumnOffsetLookupYDelete,
            onColumnOffsetLookupYChange,
        } = this.props;
        const { mapping, actions } = description;
        const { offsetLookup } = mapping;

        return (
            <>
                <div className={styles['row']}>
                    <div className={styles['name-container']}>
                        <span className={styles['name']}>{`Rule ${ruleIndex + 1}`}</span>
                    </div>
                    <div className={styles['button-container']}>
                        <div className={styles['order-button-container']}>
                            <Button
                                className={styles['button']}
                                icon={<CaretUpOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onRuleMoveUp(ruleIndex)}
                            />
                            <Button
                                className={styles['button']}
                                icon={<CaretDownOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onRuleMoveDown(ruleIndex)}
                            />
                        </div>
                        <Button
                            className={styles['button']}
                            icon={<MinusOutlined style={{ fontSize: 10 }} />}
                            onClick={() => onRuleDelete(ruleIndex)}
                        />
                    </div>
                </div>
                <div className={styles['row']}>
                    <ColumnMapping
                        key={`column-rule-${ruleIndex}-mapping`}
                        ruleIndex={ruleIndex}
                        description={mapping}
                        onMappingTypeChange={onMappingTypeClick}
                        onMappingNameFromChange={onMappingNameFromChange}
                        onMappingNameToChange={onMappingNameToChange}
                        onMappingNameFromRowIndexChange={onMappingNameFromRowIndexChange}
                        onMappingNameFromColumnIndexChange={onMappingNameFromColumnIndexChange}
                    />
                </div>
                <div className={styles['row']}>
                    <ColumnOffsetLookup
                        key={`column-rule-${ruleIndex}-offset`}
                        ruleIndex={ruleIndex}
                        description={offsetLookup}
                        onCreate={onColumnOffsetLookupCreate}
                        onXCreate={onColumnOffsetLookupXCreate}
                        onXDelete={onColumnOffsetLookupXDelete}
                        onXChange={onColumnOffsetLookupXChange}
                        onYCreate={onColumnOffsetLookupYCreate}
                        onYDelete={onColumnOffsetLookupYDelete}
                        onYChange={onColumnOffsetLookupYChange}
                    />
                </div>
                <div className={styles['row']}>
                    {actions.length ? (
                        <>
                            <span>Perform the following action(s)</span>
                            <Button
                                className={styles['button']}
                                icon={<PlusOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onActionCreate(ruleIndex)}
                            />
                        </>
                    ) : (
                        <>
                            <Button type={'default'} onClick={() => onActionCreate(ruleIndex)}>
                                Add action(s)
                            </Button>
                        </>
                    )}
                </div>
                {!!actions.length && (
                    <>
                        {actions.map((action, actionIndex) => (
                            <ColumnAction
                                key={`column-rule-${ruleIndex}-action-${actionIndex}`}
                                ruleIndex={ruleIndex}
                                actionIndex={actionIndex}
                                description={action}
                                onBooleanOperatorClick={onActionBooleanOperatorClick}
                                onDelete={onActionDelete}
                                onMoveUp={onActionMoveUp}
                                onMoveDown={onActionMoveDown}
                                onConditionOperandChange={onConditionOperandChange}
                                onConditionOperatorChange={onConditionOperatorChange}
                                onConditionValueChange={onConditionValueChange}
                                onConditionDelete={onConditionDelete}
                                onConditionCreate={onConditionCreate}
                                onActionTypeChange={onActionTypeChange}
                                onSplitOnChange={onActionSplitOnChange}
                                onSliceCreate={onActionSliceCreate}
                                onSliceDelete={onActionSliceDelete}
                                onSliceGreaterThanChange={onActionSliceGreaterThanChange}
                                onSliceStartChange={onActionSliceStartChange}
                                onSliceEndChange={onActionSliceEndChange}
                                onToChange={onActionToChange}
                                onColumnNamesChange={onActionColumnNamesChange}
                                onJoinStringChange={onActionJoinStringChange}
                                onSkipDuplicatesChange={onActionSkipDuplicatesChange}
                            />
                        ))}
                    </>
                )}
            </>
        );
    };

    public render = () => {
        return <div className={styles['container']}>{this.getColumnRule()}</div>;
    };
}
