import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './pages/label/store/browserHistory';
import { store } from 'pages/label/store';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import App from './app';

import './index.scss';

initializeIcons();

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
