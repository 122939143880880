import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import styles from './user.module.scss';

type Props = {
    isCollapsed: boolean;
};

export function User({ isCollapsed }: Props) {
    const { user, logout } = useAuth0();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const name = user?.nickname || user?.name || user?.email || 'User';
    const initial = name
        .split('')
        .find((c) => /\w/.test(c))
        ?.toUpperCase();

    const content = (
        <div className={styles.menu}>
            <Typography className={styles['menu-item']} onClick={() => logout()}>
                Logout
            </Typography>
        </div>
    );

    const hide = () => {
        setIsPopoverOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setIsPopoverOpen(newOpen);
    };

    return (
        <Popover
            placement={'right'}
            trigger={'click'}
            content={<div onClick={hide}>{content}</div>}
            overlayInnerStyle={{ backgroundColor: '#001f42', padding: '4px', marginLeft: '12px', overflow: 'hidden' }}
            arrow={false}
            open={isPopoverOpen}
            onOpenChange={handleOpenChange}
        >
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={`${styles.avatar} ${isCollapsed ? `${styles['avatar-collapsed']}` : ''}`}>
                        <Typography className={styles.initial}>{initial}</Typography>
                    </div>
                    {!isCollapsed && (
                        <div className={styles.name}>
                            <Typography className={styles.text}>{name}</Typography>
                        </div>
                    )}
                </div>
                {!isCollapsed && (
                    <div className={styles.right}>
                        <div className={styles.icon}>
                            <MoreOutlined className={styles.more} />
                        </div>
                    </div>
                )}
            </div>
        </Popover>
    );
}
