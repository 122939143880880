import { LocalStorageService } from 'core/services';

export const getApiUrl = () => {
    const definedEnvironments = {
        Production: 'Production',
        Staging: 'Staging',
        Development: 'Development',
    };

    const environment = definedEnvironments[LocalStorageService.getItem('environment', 'Production')] || 'Production';

    const apiUrlMap = {
        Production: process.env.REACT_APP_API_URL,
        Staging: process.env.REACT_APP_API_URL_STAGING,
        Development: process.env.REACT_APP_API_URL_DEVELOPMENT,
    };

    return apiUrlMap[environment];
};
