import React from 'react';
import { Input } from './input';
import { Select } from './select';
import { isOnlyWhiteSpace, hasWhiteSpaceAtEdges } from 'helpers';
import $check from 'check-types';

import { Mapping } from 'types';

import styles from './columnMapping.module.scss';

type DropdownItem = {
    value: string;
    label: string;
};

type Props = {
    ruleIndex: number;
    description: Mapping;
    onMappingTypeChange: (item: DropdownItem, ruleIndex: number) => void;
    onMappingNameFromChange: (text: string, ruleIndex: number) => void;
    onMappingNameToChange: (text: string, ruleIndex: number) => void;
    onMappingNameFromRowIndexChange: (text: string, ruleIndex: number) => void;
    onMappingNameFromColumnIndexChange: (text: string, ruleIndex: number) => void;
};

export class ColumnMapping extends React.PureComponent<Props> {
    private getMappingTypeDropdown = () => {
        return [
            { value: 'String', label: 'String' },
            { value: 'RegExp', label: 'RegExp' },
            { value: 'Coordinates', label: 'Coordinates' },
            { value: 'Placeholder', label: 'Placeholder' },
        ];
    };

    public render = () => {
        const {
            ruleIndex,
            description,
            onMappingTypeChange,
            onMappingNameFromChange,
            onMappingNameToChange,
            onMappingNameFromRowIndexChange,
            onMappingNameFromColumnIndexChange,
        } = this.props;

        const { type, nameFrom, nameTo, nameFromCoordinates } = description;

        return (
            <div className={styles['container']}>
                <div className={styles['text']}>
                    <span>Map column from</span>
                </div>
                <Select
                    options={this.getMappingTypeDropdown()}
                    value={type}
                    onChange={(value) => {
                        const item = this.getMappingTypeDropdown().find((option) => option.value === value);

                        onMappingTypeChange(item!, ruleIndex);
                    }}
                />
                {(type === 'String' || type === 'RegExp') && (
                    <>
                        <Input
                            className={styles['input']}
                            placeholder={'Some name'}
                            value={nameFrom}
                            isValueRegex={type === 'RegExp'}
                            onChange={(e) => {
                                const text = e.target.value;

                                onMappingNameFromChange(text, ruleIndex);
                            }}
                            validator={(text) => {
                                if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                    return 'Please enter a valid name';
                                }

                                return undefined;
                            }}
                        />
                        <div className={styles['text']}>
                            <span>to</span>
                        </div>
                        <Input
                            className={styles['input']}
                            placeholder={'Some name'}
                            value={nameTo}
                            onChange={(e) => {
                                const text = e.target.value;

                                onMappingNameToChange(text, ruleIndex);
                            }}
                            validator={(text) => {
                                if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                    return 'Please enter a valid name';
                                }

                                return undefined;
                            }}
                        />
                    </>
                )}

                {type === 'Placeholder' && (
                    <>
                        <Input
                            className={styles['input']}
                            placeholder={'Placeholder'}
                            disabled={true}
                            value={nameFrom}
                            isValueRegex={false}
                            onChange={() => {}}
                        />
                        <div className={styles['text']}>
                            <span>to</span>
                        </div>
                        <Input
                            className={styles['input']}
                            placeholder={'Some name'}
                            value={nameTo}
                            onChange={(e) => {
                                const text = e.target.value;

                                onMappingNameToChange(text, ruleIndex);
                            }}
                            validator={(text) => {
                                if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                    return 'Please enter a valid name';
                                }

                                return undefined;
                            }}
                        />
                    </>
                )}

                {type === 'Coordinates' && (
                    <>
                        <div className={styles['text']}>
                            <span>Row index</span>
                        </div>
                        <Input
                            className={styles['input']}
                            placeholder={'Some name'}
                            value={nameFromCoordinates?.rowIndex}
                            onChange={(e) => {
                                const text = e.target.value;

                                onMappingNameFromRowIndexChange(text, ruleIndex);
                            }}
                            validator={(text) => {
                                if (
                                    !text?.length ||
                                    isOnlyWhiteSpace(text) ||
                                    hasWhiteSpaceAtEdges(text) ||
                                    !$check.integer(Number(text))
                                ) {
                                    return 'Please enter a valid integer';
                                }

                                return undefined;
                            }}
                        />
                        <div className={styles['text']}>
                            <span>Column index</span>
                        </div>
                        <Input
                            className={styles['input']}
                            placeholder={'Some name'}
                            value={nameFromCoordinates?.columnIndex}
                            onChange={(e) => {
                                const text = e.target.value;

                                onMappingNameFromColumnIndexChange(text, ruleIndex);
                            }}
                            validator={(text) => {
                                if (
                                    !text?.length ||
                                    isOnlyWhiteSpace(text) ||
                                    hasWhiteSpaceAtEdges(text) ||
                                    !$check.integer(Number(text))
                                ) {
                                    return 'Please enter a valid integer';
                                }

                                return undefined;
                            }}
                        />
                        <div className={styles['text']}>
                            <span>to</span>
                        </div>
                        <Input
                            className={styles['input']}
                            placeholder={'Some name'}
                            value={nameTo}
                            onChange={(e) => {
                                const text = e.target.value;

                                onMappingNameToChange(text, ruleIndex);
                            }}
                            validator={(text) => {
                                if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                    return 'Please enter a valid name';
                                }

                                return undefined;
                            }}
                        />
                    </>
                )}
            </div>
        );
    };
}
