import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { DOCUMENT_TREE_VIEW } from '../../data/consts/apiQuery';
import { formTreeService } from '../../data/services/formTreeService';
import CustomModelLabelPage from 'pages/label/containers/customModelLabelPage/customModelLabelPage';

interface ISelectOptions {
    label: string;
    value: string;
}

export default function Analyze() {
    const [pagedFormOptions, setPagedFormOptions] = useState<ISelectOptions[]>([]);
    const [documentOptions, setDocumentOptions] = useState<ISelectOptions[]>([]);
    const preBuiltModel: ISelectOptions[] = [
        { label: 'Read', value: 'prebuilt-read' },
        { label: 'Document', value: 'prebuilt-document' },
        { label: 'BusinessCard', value: 'prebuilt-businessCard' },
        { label: 'IDCard', value: 'prebuilt-idDocument' },
        { label: 'Invoice', value: 'prebuilt-invoice' },
        { label: 'Receipt', value: 'prebuilt-receipt' },
        { label: 'W2', value: 'prebuilt-tax.us.w2' },
        { label: 'HealthInsuranceCard', value: 'prebuilt-healthInsuranceCard.us' },
    ];
    const [selectedForm, setSelectedForm] = useState<string>();

    const { data: formAndDocumentList } = useQuery({
        queryKey: [DOCUMENT_TREE_VIEW],
        queryFn: () => formTreeService.getDocumentList(),
        retry: 0,
    });

    const commandBar = document.getElementById('label-canvas-command-bar-select');

    useEffect(() => {
        if (formAndDocumentList?.length) {
            const pagedForms: ISelectOptions[] = [];
            const documents: ISelectOptions[] = [];

            formAndDocumentList.forEach((item) => {
                if (item.type === 'Document') {
                    pagedForms.push({ label: item.title, value: item._id });
                } else {
                    documents.push({ label: item.title, value: item._id });
                }
            });

            setDocumentOptions(documents);
            setPagedFormOptions(pagedForms);
        }
    }, [formAndDocumentList]);

    return (
        <div className="f-height">
            {commandBar &&
                createPortal(
                    <Select
                        defaultValue={'Select model'}
                        value={selectedForm}
                        style={{ width: 250 }}
                        onSelect={(value) => setSelectedForm(value)}
                        options={[
                            {
                                label: 'Paged forms',
                                options: documentOptions,
                            },
                            {
                                label: 'Documents',
                                options: pagedFormOptions,
                            },
                            {
                                label: 'Pre-Built Forms',
                                options: preBuiltModel,
                            },
                        ]}
                    />,
                    commandBar
                )}

            <CustomModelLabelPage isReadonly formId={selectedForm} />
        </div>
    );
}
