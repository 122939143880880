import React from 'react';
import { Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { Input } from './input';
import { isOnlyWhiteSpace, hasWhiteSpaceAtEdges } from 'helpers';
import $check from 'check-types';

import { OffsetLookup } from 'types';

import styles from './columnOffsetLookup.module.scss';

type Props = {
    ruleIndex: number;
    description?: OffsetLookup;
    onCreate: (ruleIndex: number) => void;
    onXCreate: (ruleIndex: number) => void;
    onXDelete: (ruleIndex: number) => void;
    onXChange: (text: string, ruleIndex: number) => void;
    onYCreate: (ruleIndex: number) => void;
    onYDelete: (ruleIndex: number) => void;
    onYChange: (text: string, ruleIndex: number) => void;
};

export class ColumnOffsetLookup extends React.PureComponent<Props> {
    public render = () => {
        const { ruleIndex, description, onCreate, onXCreate, onXDelete, onYCreate, onYDelete, onXChange, onYChange } =
            this.props;

        if (!description) {
            return (
                <Button type={'default'} onClick={() => onCreate(ruleIndex)}>
                    Add offset lookup
                </Button>
            );
        }

        const { x, y } = description;

        return (
            <div className={styles['container']}>
                <div className={styles['heading']}>
                    <span>Offset lookup</span>
                </div>
                <div className={styles['row']}>
                    <div className={styles['left']}>
                        {typeof x !== 'string' ? (
                            <Button type={'default'} onClick={() => onXCreate(ruleIndex)}>
                                Add x value
                            </Button>
                        ) : (
                            <>
                                <div className={styles['text']}>
                                    <span>x</span>
                                </div>
                                <Input
                                    placeholder={'X value'}
                                    value={x}
                                    onChange={(e) => onXChange(e.target.value, ruleIndex)}
                                    validator={(text) => {
                                        if (
                                            !text?.length ||
                                            isOnlyWhiteSpace(text) ||
                                            hasWhiteSpaceAtEdges(text) ||
                                            !$check.number(Number(text))
                                        ) {
                                            return 'Please enter a valid number value';
                                        }

                                        return undefined;
                                    }}
                                />
                            </>
                        )}
                    </div>
                    <div className={styles['right']}>
                        {!!x && (
                            <div className={styles['button-container']}>
                                <Button
                                    className={styles['button']}
                                    icon={<MinusOutlined style={{ fontSize: 10 }} />}
                                    onClick={() => onXDelete(ruleIndex)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles['row']}>
                    <div className={styles['left']}>
                        {typeof y !== 'string' ? (
                            <Button type={'default'} onClick={() => onYCreate(ruleIndex)}>
                                Add y value
                            </Button>
                        ) : (
                            <>
                                <div className={styles['text']}>
                                    <span>y</span>
                                </div>
                                <Input
                                    placeholder={'Y value'}
                                    value={y}
                                    onChange={(e) => onYChange(e.target.value, ruleIndex)}
                                    validator={(text) => {
                                        if (
                                            !text?.length ||
                                            isOnlyWhiteSpace(text) ||
                                            hasWhiteSpaceAtEdges(text) ||
                                            !$check.number(Number(text))
                                        ) {
                                            return 'Please enter a valid number value';
                                        }

                                        return undefined;
                                    }}
                                />
                            </>
                        )}
                    </div>
                    <div className={styles['right']}>
                        {!!y && (
                            <div className={styles['button-container']}>
                                <Button
                                    className={styles['button']}
                                    icon={<MinusOutlined style={{ fontSize: 10 }} />}
                                    onClick={() => onYDelete(ruleIndex)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };
}
