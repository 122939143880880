import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Modal, Upload } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { DocumentMimeTypes } from '../../utils/documentLoader';
import { formFilesService } from '../../services/formFilesService';
import { IRawDocument } from '../../store/documents/documentsTypes';
import { IRawFormFile } from '../../models/customModels';

import styles from './fileUpload.module.scss';

const { Dragger } = Upload;

export function getBase64(file: RcFile, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        const result = reader?.result as string;
        cb(result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

interface IProps {
    isUploadModalOpen: boolean;
    setUploadedDocuments: (docs: IRawFormFile[]) => void;
    handleCloseModal: () => void;
    addDocuments: (docs: IRawDocument[]) => void;
}

export function FileUpload({ setUploadedDocuments, isUploadModalOpen, handleCloseModal, addDocuments }: IProps) {
    const [uploadedFiles, setUploadedFiles] = useState<IRawFormFile[]>([]);
    const [fileList, setFileList] = useState<RcFile[]>([]);
    const { pageType, formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = useParams();

    const beforeUpload = (file: RcFile) => {
        setFileList([...fileList, file]);
        const isValidExtension = DocumentMimeTypes.includes(file?.type);

        if (!isValidExtension) {
            message.error(`${file?.name} is incorrect format!`, 5);
        }
        const isLt5M = file.size / 1024 / 1024 < 6;
        if (!isLt5M) {
            message.error('Image must smaller than 5MB!', 5);
        }
        return (isValidExtension && isLt5M) || Upload.LIST_IGNORE;
    };

    function handleCustomRequest(options) {
        getBase64(options.file, async (result) => {
            const filename = options.file.name.split('.').slice(0, -1).join('.');
            if (pageType === 'document') {
                await formFilesService
                    .createDocumentFile(formId as string, {
                        filename,
                        mimetype: options.file.type,
                        file: result?.split(',')?.[1] || '',
                    })
                    .then((res) => {
                        setUploadedFiles((files) => [...files, { ...res, uid: options.file.uid }]);
                        options.onSuccess();
                    })
                    .catch((err) => {
                        options.onError(err);
                    });
            } else {
                await formFilesService
                    .createPageFormFile(
                        formId as string,
                        formTypeId as string,
                        formTypeVersionId as string,
                        formTypeVersionPageId as string,
                        {
                            filename,
                            mimetype: options.file.type,
                            file: result?.split(',')?.[1] || '',
                        }
                    )
                    .then((res) => {
                        setUploadedFiles((files) => [...files, { ...res, uid: options.file.uid }]);
                        options.onSuccess();
                    })
                    .catch((err) => {
                        options.onError(err);
                    });
            }
        });
    }

    const props: UploadProps = {
        beforeUpload,
        fileList,
        multiple: true,
        accept: '.pdf,.jpeg,.jpg,.png,.tiff,.tif',
        customRequest: handleCustomRequest,
        onChange(info) {
            setFileList(info.fileList as RcFile[]);
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: async (file) => {
            if (file.status === 'done') {
                const fileId = uploadedFiles.find((item) => item.uid === file.uid)?._id;
                if (pageType === 'document') {
                    const response = await formFilesService.deleteDocumentFile(formId as string, fileId as string);

                    if (response.status === 204) {
                        const filteredUploadFiles = uploadedFiles.filter((item) => item.uid !== file.uid);
                        setUploadedFiles(filteredUploadFiles);

                        return true;
                    }
                } else {
                    const response = await formFilesService.deletePageFormFile(
                        formId as string,
                        formTypeId as string,
                        formTypeVersionId as string,
                        formTypeVersionPageId as string,
                        fileId as string
                    );

                    if (response.status === 204) {
                        const filteredUploadFiles = uploadedFiles.filter((item) => item.uid !== file.uid);
                        setUploadedFiles(filteredUploadFiles);

                        return true;
                    }
                }
            } else if (file.status === 'error') {
                return true;
            }

            return false;
        },
    };

    const handleOnCancel = () => {
        handleCloseModal();
        setUploadedDocuments(uploadedFiles);
    };

    return (
        <Modal
            width={700}
            closable={false}
            title="Upload files"
            open={isUploadModalOpen}
            className={styles.upload_modal}
            footer={<Button onClick={handleOnCancel}>Close</Button>}
        >
            <div className="mt-4">
                <Dragger {...props}>
                    <div className={styles.upload_modal__content}>
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined className={styles.upload_modal__icon} rev="" />
                        </p>
                        <p className={`ant-upload-hint ${styles.upload_modal__text}`}>
                            <span color="blue">Select</span> or drag-and-drop files to upload. Only .pdf, .png, .jpg and
                            .jpeg file types are supported with a maximum size of 5MB each.
                        </p>
                    </div>
                </Dragger>
            </div>
        </Modal>
    );
}
