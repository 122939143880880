import * as React from 'react';
import { Input as AntInput } from 'antd';

import styles from './input.module.scss';

type Props = {
    className?: string;
    placeholder: string;
    initialValue?: string;
    value: string | number | undefined;
    isValueRegex?: boolean;
    onChange: (e: any) => void;
    disabled?: boolean;
    label?: string;
    validator?: (text: string) => string | undefined;
};

type State = {
    hasTyped: boolean;
};

export class Input extends React.Component<Props, State> {
    state = {
        hasTyped: false,
    };

    private onChange = (e) => {
        this.props.onChange(e);
    };

    public componentDidUpdate(prevProps) {
        const { value } = this.props;

        if (prevProps.value === value) {
            return;
        }

        this.setState({
            hasTyped: true,
        });
    }

    public render = () => {
        const { className, placeholder, value, isValueRegex, label, disabled, validator } = this.props;

        const valueValidator = typeof validator === 'function' ? validator : () => {};

        return (
            <div className={className || styles['container']}>
                {!!label && (
                    <div className={styles['label-container']}>
                        <span className={styles['input-label']}>{label}</span>
                    </div>
                )}
                <div className={styles['input-container']}>
                    <AntInput
                        className={isValueRegex ? styles['ant-input-blue'] : styles['ant-input']}
                        placeholder={placeholder}
                        value={value}
                        onChange={this.onChange}
                        disabled={disabled}
                        status={valueValidator(String(value)) ? 'error' : undefined}
                    />
                    {!!valueValidator(String(value)) && (
                        <div className={styles['message-container']}>
                            <span className={styles['message']}>{valueValidator(String(value)) || ''}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    };
}
