// @ts-nocheck
import React from 'react';
import { Button, Popconfirm, Result, Spin, Checkbox } from 'antd';
import { PlusOutlined, ReloadOutlined, LoadingOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useContextValues } from 'components/providers/MainProvider';
import { rulesetService } from 'data/services/rulesetService';
import { ColumnRule, IdCondition, Input, Select, RowRule } from '../components';
import { isValidName, hasWhiteSpaceAtEdges, getFormIdFromLocation } from 'helpers';

import { Ruleset } from 'types';
import { getRulesetErrors } from '../components/helpers';

import styles from './transforms.module.scss';

type Props = {
    onClose: () => void;
    notificationApi: any;
};

type State = {
    isLoading: boolean;
    isCreatingOrSavingRuleset: boolean;
    isDeletingRuleset: boolean;
    shouldRetryLoad: boolean;
    selectedRuleset: Ruleset | undefined;
    selectedRulesetIndex: number | undefined;
    rulesets: Ruleset[];
};

class TransformsTab extends React.Component<Props, State> {
    state = {
        isLoading: true,
        isCreatingOrSavingRuleset: false,
        isDeletingRuleset: false,
        shouldRetryLoad: false,
        selectedRuleset: undefined,
        selectedRulesetIndex: undefined,
        rulesets: [],
    };

    private loadRulesets = async (targetIndex?) => {
        const { notificationApi } = this.props;

        this.setState(
            {
                isLoading: true,
                shouldRetryLoad: false,
            },
            async () => {
                // Try-catch is required here since the error in the callback passed to setState does not bubble
                // correctly.
                try {
                    const formId = getFormIdFromLocation();
                    const rulesets = await rulesetService.getRulesets(formId);
                    const selectedRuleset =
                        typeof targetIndex === 'number' ? { ...rulesets[targetIndex] } : rulesets[0];

                    this.setState({
                        isLoading: false,
                        isCreatingOrSavingRuleset: false,
                        isDeletingRuleset: false,
                        shouldRetryLoad: false,
                        selectedRuleset: selectedRuleset,
                        selectedRulesetIndex: typeof targetIndex === 'number' ? targetIndex : 0,
                        rulesets: rulesets,
                    });
                } catch (e) {
                    this.setState({
                        shouldRetryLoad: true,
                    });

                    notificationApi.error({
                        message: 'Failed to load rulesets',
                        description: e?.response?.data?.message,
                        placement: 'topRight',
                    });
                }
            }
        );
    };

    private onCreateRuleset = async () => {
        const { notificationApi } = this.props;

        const rulesets = this.state.rulesets.filter((ruleset) => ruleset.name !== 'New');
        const ruleset = this.state.selectedRuleset;
        const formId = getFormIdFromLocation();

        this.setState(
            {
                isCreatingOrSavingRuleset: true,
            },
            async () => {
                try {
                    if (rulesets?.length) {
                        rulesets.push(ruleset!);

                        await rulesetService.updateRulesets(rulesets, formId);
                    } else {
                        await rulesetService.createRuleset(ruleset!, formId);
                    }

                    notificationApi.success({
                        message: 'Created ruleset',
                        description: 'A new ruleset has been created.',
                        placement: 'topRight',
                    });

                    this.loadRulesets();
                } catch (e) {
                    notificationApi.error({
                        message: 'Failed to create ruleset',
                        description: e?.response?.data?.message,
                        placement: 'topRight',
                    });

                    this.setState({
                        isCreatingOrSavingRuleset: false,
                    });
                }
            }
        );
    };

    private onSaveRuleset = () => {
        const { notificationApi } = this.props;

        const rulesets = this.state.rulesets.filter((ruleset) => ruleset?.name !== 'New');
        const ruleset = this.state.selectedRuleset;
        const rulesetIndex = this.state.selectedRulesetIndex;
        const formId = getFormIdFromLocation();

        const updatedRulesets = JSON.parse(JSON.stringify(rulesets));
        const updateIndex = updatedRulesets.findIndex((ur) => ur?.id === ruleset?.id);

        updatedRulesets[updateIndex] = {
            ...(ruleset || {}),
        };

        this.setState(
            {
                isCreatingOrSavingRuleset: true,
            },
            async () => {
                try {
                    await rulesetService.updateRulesets(updatedRulesets, formId);

                    notificationApi.success({
                        message: 'Updated ruleset',
                        description: 'The selected ruleset has been updated.',
                        placement: 'topRight',
                    });

                    this.loadRulesets(rulesetIndex);
                } catch (e) {
                    notificationApi.error({
                        message: 'Failed to update ruleset',
                        description: e?.response?.data?.message,
                        placement: 'topRight',
                    });

                    this.setState({
                        isCreatingOrSavingRuleset: false,
                    });
                }
            }
        );
    };

    private onDeleteRuleset = () => {
        const { notificationApi } = this.props;

        const rulesets = this.state.rulesets;
        const ruleset = this.state.selectedRuleset;
        const formId = getFormIdFromLocation();

        const updatedRulesets = rulesets.filter((ur) => ur?.id !== ruleset?.id).filter((ur) => ur?.name !== 'New');

        this.setState(
            {
                isDeletingRuleset: true,
            },
            async () => {
                try {
                    await rulesetService.updateRulesets(updatedRulesets, formId);

                    notificationApi.success({
                        message: 'Deleted ruleset',
                        description: 'The selected ruleset has been deleted.',
                        placement: 'topRight',
                    });

                    this.loadRulesets();
                } catch (e) {
                    notificationApi.error({
                        message: 'Failed to delete ruleset',
                        description: e?.response?.data?.message,
                        placement: 'topRight',
                    });
                }
            }
        );
    };

    private getSavedRulesetFromSelectedRulesetIndex = () => {
        const { selectedRulesetIndex } = this.state;

        return this.state.rulesets[selectedRulesetIndex];
    };

    private isSelectedRulesetValid = () => {
        const { selectedRuleset, rulesets } = this.state;
        const { name: selectedRulesetName } = selectedRuleset;

        const errors = getRulesetErrors(selectedRuleset) || [];

        const existingRuleset = rulesets.find((ruleset) => ruleset.name === selectedRulesetName);

        const doesExist = !!existingRuleset;

        const isNameSameAsSaved = selectedRulesetName === this.getSavedRulesetFromSelectedRulesetIndex()?.name;

        return !errors.length && (isNameSameAsSaved ? true : !doesExist);
    };

    private isNewRulesetValid = () => {
        const { selectedRuleset, rulesets } = this.state;
        const { name: selectedRulesetName } = selectedRuleset;

        const errors = getRulesetErrors(selectedRuleset) || [];

        const existingRuleset = rulesets.find((ruleset) => ruleset.name === selectedRulesetName);

        const doesExist = !!existingRuleset;
        const isNameNew = selectedRulesetName === 'New';

        return !errors.length && !doesExist && !isNameNew;
    };

    private isAddingNewRuleset = () => {
        return this.getSavedRulesetFromSelectedRulesetIndex()?.name === 'New';
    };

    private onRulesetNameChange = (e) => {
        this.setState({
            selectedRuleset: { ...this.state.selectedRuleset, name: e.target.value },
        });
    };

    private getRulesetsDropdown = () => {
        return this.state.rulesets.map((ruleset) => ({
            value: ruleset.name,
            label: ruleset.name,
        }));
    };

    private onRulesetClick = (selectedName) => {
        const selectedRuleset = this.state.rulesets.find((ruleset) => ruleset.name === selectedName);
        const selectedRulesetIndex = this.state.rulesets.findIndex((ruleset) => ruleset.name === selectedName);

        this.setState({ selectedRuleset: { ...selectedRuleset }, selectedRulesetIndex: selectedRulesetIndex });
    };

    private onRulesetReload = (selectedRulesetIndex) => {
        const newRuleset = this.state.rulesets[selectedRulesetIndex];

        this.setState({
            selectedRuleset: { ...newRuleset },
        });
    };

    private getSearchByDropdown = () => {
        return [
            { value: 'Column names', label: 'Column names' },
            { value: 'Table structure', label: 'Table structure' },
        ];
    };

    private onSearchByClick = (value) => {
        const newSearchMode = value;

        const conditions = this.state.selectedRuleset.identificationRules.conditions.filter((condition) => {
            if (newSearchMode === 'Column names') {
                return /column name/gi.test(condition.operand);
            }

            if (newSearchMode === 'Table structure') {
                return !/column name/gi.test(condition.operand);
            }

            return true;
        });

        if (!conditions.length) {
            if (newSearchMode === 'Column names') {
                conditions.push({
                    operand: 'Column name 1',
                    operator: 'Equals',
                    value: 'Some value',
                });
            }

            if (newSearchMode === 'Table structure') {
                conditions.push({
                    operand: 'Row count',
                    operator: 'Equals',
                    value: 'Some value',
                });
            }
        }

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                identificationRules: {
                    ...this.state.selectedRuleset.identificationRules,
                    searchMode: newSearchMode,
                    conditions: conditions,
                },
            },
        });
    };

    private getBooleanOperatorDropdown = () => {
        return [
            { value: 'All', label: 'All' },
            { value: 'Any', label: 'Any' },
        ];
    };

    private onBooleanOperatorClick = (value) => {
        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                identificationRules: {
                    ...this.state.selectedRuleset.identificationRules,
                    booleanOperator: value,
                },
            },
        });
    };

    private getOutputHeaderDropdown = () => {
        return [
            { value: 'Top row', label: 'Top row' },
            { value: 'Left column', label: 'Left column' },
        ];
    };

    private onOutputHeaderClick = (value) => {
        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                outputHeaderPosition: value,
            },
        });
    };

    private onIdConditionOperandChange = (item, index) => {
        const conditions = [...this.state.selectedRuleset.identificationRules.conditions];

        conditions[index] = {
            ...conditions[index],
            operand: item.label,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                identificationRules: {
                    ...this.state.selectedRuleset.identificationRules,
                    conditions: conditions,
                },
            },
        });
    };

    private onIdConditionOperatorChange = (item, index) => {
        const conditions = [...this.state.selectedRuleset.identificationRules.conditions];

        conditions[index] = {
            ...conditions[index],
            operator: item.label,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                identificationRules: {
                    ...this.state.selectedRuleset.identificationRules,
                    conditions: conditions,
                },
            },
        });
    };

    private onIdConditionValueChange = (text, index) => {
        const conditions = [...this.state.selectedRuleset.identificationRules.conditions];

        conditions[index] = {
            ...conditions[index],
            value: text,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                identificationRules: {
                    ...this.state.selectedRuleset.identificationRules,
                    conditions: conditions,
                },
            },
        });
    };

    private onIdConditionDelete = (index) => {
        if (this.state.selectedRuleset.identificationRules.conditions.length === 1) {
            return;
        }

        const conditions = this.state.selectedRuleset.identificationRules.conditions.filter((item, i) => i !== index);

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                identificationRules: {
                    ...this.state.selectedRuleset.identificationRules,
                    conditions: conditions,
                },
            },
        });
    };

    private onIdConditionCreate = () => {
        const conditions = [...this.state.selectedRuleset.identificationRules.conditions];

        const { searchMode } = this.state.selectedRuleset.identificationRules;

        if (searchMode === 'Column names') {
            conditions.push({
                operand: `Column name ${conditions.length + 1}`,
                operator: 'Equals',
                value: 'Some value',
            });
        }

        if (searchMode === 'Table structure' && conditions.length < 4) {
            conditions.push({
                operand: 'Row count',
                operator: 'Equals',
                value: 'Some value',
            });
        }

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                identificationRules: {
                    ...this.state.selectedRuleset.identificationRules,
                    conditions: conditions,
                },
            },
        });
    };

    private onColumnRuleDelete = (ruleIndex) => {
        const newColumnRules = this.state.selectedRuleset.columnRules.filter((columnRule, i) => i !== ruleIndex);

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnRuleMoveUp = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        const index = ruleIndex;
        const previousIndex = ruleIndex - 1;

        if (index === 0) {
            return;
        }

        const rule = newColumnRules[index];
        const previousRule = newColumnRules[previousIndex];

        newColumnRules[previousIndex] = {
            ...rule,
        };

        newColumnRules[index] = {
            ...previousRule,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnRuleMoveDown = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        const index = ruleIndex;
        const nextIndex = ruleIndex + 1;

        if (index === newColumnRules.length - 1) {
            return;
        }

        const rule = newColumnRules[index];
        const nextRule = newColumnRules[nextIndex];

        newColumnRules[nextIndex] = {
            ...rule,
        };

        newColumnRules[index] = {
            ...nextRule,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnRuleCreate = () => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules.push({
            mapping: {
                type: 'String',
                nameFrom: 'Some name',
                nameFromCoordinates: {
                    rowIndex: '0',
                    columnIndex: '0',
                },
                nameTo: 'Some name',
            },
            actions: [],
        });

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnMappingTypeClick = (item, ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                type: item.label,
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnMappingNameFromChange = (text, ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                nameFrom: text,
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnMappingNameToChange = (text, ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                nameTo: text,
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onMappingNameFromRowIndexChange = (text, ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                nameFromCoordinates: {
                    ...newColumnRules[ruleIndex]?.mapping?.nameFromCoordinates,
                    rowIndex: text,
                },
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onMappingNameFromColumnIndexChange = (text, ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                nameFromCoordinates: {
                    ...newColumnRules[ruleIndex]?.mapping?.nameFromCoordinates,
                    columnIndex: text,
                },
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnActionBooleanOperatorClick = (item, ruleIndex, actionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            booleanOperator: item.label,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionTypeChange = (value: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            type: value,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionSplitOnChange = (text: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            splitOn: text,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionSliceCreate = (ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            slice: {
                ifLengthGreaterThan: '0',
                start: '0',
                end: '0',
            },
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionSliceDelete = (ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            slice: undefined,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionSliceGreaterThanChange = (text: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            slice: {
                ...newColumnRules[ruleIndex].actions[actionIndex].details.slice,
                ifLengthGreaterThan: text,
            },
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionSliceStartChange = (text: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            slice: {
                ...newColumnRules[ruleIndex].actions[actionIndex].details.slice,
                start: text,
            },
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionSliceEndChange = (text: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            slice: {
                ...newColumnRules[ruleIndex].actions[actionIndex].details.slice,
                end: text,
            },
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionToChange = (text: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            to: text,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionColumnNamesChange = (text: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            columnNames: text,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionJoinStringChange = (text: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            joinString: text,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onActionSkipDuplicatesChange = (value: string, ruleIndex: number, actionIndex: number) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const newDetails = {
            ...newColumnRules[ruleIndex].actions[actionIndex].details,
            skipDuplicates: value,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            details: newDetails,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnActionDelete = (ruleIndex, actionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = newColumnRules[ruleIndex].actions.filter((action, i) => i !== actionIndex);

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnActionMoveUp = (ruleIndex, actionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const index = actionIndex;
        const previousIndex = actionIndex - 1;

        if (index === 0) {
            return;
        }

        const action = newActions[index];
        const previousAction = newActions[previousIndex];

        newActions[previousIndex] = {
            ...action,
        };

        newActions[index] = {
            ...previousAction,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnActionMoveDown = (ruleIndex, actionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        const index = actionIndex;
        const nextIndex = actionIndex + 1;

        if (index === newActions.length - 1) {
            return;
        }

        const action = newActions[index];
        const nextAction = newActions[nextIndex];

        newActions[nextIndex] = {
            ...action,
        };

        newActions[index] = {
            ...nextAction,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnActionCreate = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];

        newActions.push({
            booleanOperator: 'All',
            details: {
                type: 'Convert cell',
                to: 'Number',
                splitOn: ',',
                slice: {
                    ifLengthGreaterThan: '0',
                    start: '0',
                    end: '0',
                },
                columnNames: 'Column 1, Column 2',
                joinString: ' ',
                skipDuplicates: 'No',
            },
            conditions: [],
        });

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnConditionOperandChange = (item, ruleIndex, actionIndex, conditionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        newConditions[conditionIndex] = {
            ...newConditions[conditionIndex],
            operand: item.label,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnConditionOperatorChange = (item, ruleIndex, actionIndex, conditionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        const hasNoValue = item.label === 'Has a value';

        newConditions[conditionIndex] = {
            ...newConditions[conditionIndex],
            operator: item.label,
            value: hasNoValue ? undefined : 'Some value',
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnConditionValueChange = (text, ruleIndex, actionIndex, conditionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        newConditions[conditionIndex] = {
            ...newConditions[conditionIndex],
            value: text,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnConditionDelete = (ruleIndex, actionIndex, conditionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];
        const newConditions = newActions[actionIndex].conditions.filter((condition, i) => i !== conditionIndex);

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnConditionCreate = (ruleIndex, actionIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];
        const newActions = [...newColumnRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        newConditions.push({
            operand: 'Cell',
            operator: 'Has a value',
            value: undefined,
        });

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnOffsetLookupCreate = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                offsetLookup: {
                    x: '0',
                    y: '0',
                },
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnOffsetLookupXCreate = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                offsetLookup: {
                    ...newColumnRules[ruleIndex]?.mapping?.offsetLookup,
                    x: '0',
                },
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnOffsetLookupXDelete = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                offsetLookup: {
                    ...newColumnRules[ruleIndex]?.mapping?.offsetLookup,
                    x: undefined,
                },
            },
        };

        const hasX = typeof newColumnRules[ruleIndex]?.mapping?.offsetLookup?.x === 'string';
        const hasY = typeof newColumnRules[ruleIndex]?.mapping?.offsetLookup?.y === 'string';

        if (!hasX && !hasY) {
            newColumnRules[ruleIndex] = {
                ...newColumnRules[ruleIndex],
                mapping: {
                    ...newColumnRules[ruleIndex]?.mapping,
                    offsetLookup: undefined,
                },
            };
        }

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnOffsetLookupXChange = (text, ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                offsetLookup: {
                    ...newColumnRules[ruleIndex]?.mapping?.offsetLookup,
                    x: text,
                },
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnOffsetLookupYCreate = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                offsetLookup: {
                    ...newColumnRules[ruleIndex]?.mapping?.offsetLookup,
                    y: '0',
                },
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnOffsetLookupYDelete = (ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                offsetLookup: {
                    ...newColumnRules[ruleIndex]?.mapping?.offsetLookup,
                    y: undefined,
                },
            },
        };

        const hasX = typeof newColumnRules[ruleIndex]?.mapping?.offsetLookup?.x === 'string';
        const hasY = typeof newColumnRules[ruleIndex]?.mapping?.offsetLookup?.y === 'string';

        if (!hasX && !hasY) {
            newColumnRules[ruleIndex] = {
                ...newColumnRules[ruleIndex],
                mapping: {
                    ...newColumnRules[ruleIndex]?.mapping,
                    offsetLookup: undefined,
                },
            };
        }

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onColumnOffsetLookupYChange = (text, ruleIndex) => {
        const newColumnRules = [...this.state.selectedRuleset.columnRules];

        newColumnRules[ruleIndex] = {
            ...newColumnRules[ruleIndex],
            mapping: {
                ...newColumnRules[ruleIndex]?.mapping,
                offsetLookup: {
                    ...newColumnRules[ruleIndex]?.mapping?.offsetLookup,
                    y: text,
                },
            },
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                columnRules: newColumnRules,
            },
        });
    };

    private onRowRuleCreate = () => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];

        newRowRules.push({
            actions: [],
        });

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowRuleDelete = (ruleIndex) => {
        const newRowRules = this.state.selectedRuleset.rowRules.filter((rowRule, i) => i !== ruleIndex);

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowRuleMoveUp = (ruleIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];

        const index = ruleIndex;
        const previousIndex = ruleIndex - 1;

        if (index === 0) {
            return;
        }

        const rule = newRowRules[index];
        const previousRule = newRowRules[previousIndex];

        newRowRules[previousIndex] = {
            ...rule,
        };

        newRowRules[index] = {
            ...previousRule,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowRuleMoveDown = (ruleIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];

        const index = ruleIndex;
        const nextIndex = ruleIndex + 1;

        if (index === newRowRules.length - 1) {
            return;
        }

        const rule = newRowRules[index];
        const nextRule = newRowRules[nextIndex];

        newRowRules[nextIndex] = {
            ...rule,
        };

        newRowRules[index] = {
            ...nextRule,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowActionBooleanOperatorClick = (item, ruleIndex, actionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            booleanOperator: item.label,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowActionStepsChange = (steps, ruleIndex, actionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];
        const newSteps = [...steps];

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            steps: newSteps,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowActionDelete = (ruleIndex, actionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = newRowRules[ruleIndex].actions.filter((action, i) => i !== actionIndex);

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowActionMoveUp = (ruleIndex, actionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];

        const index = actionIndex;
        const previousIndex = actionIndex - 1;

        if (index === 0) {
            return;
        }

        const action = newActions[index];
        const previousAction = newActions[previousIndex];

        newActions[previousIndex] = {
            ...action,
        };

        newActions[index] = {
            ...previousAction,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowActionMoveDown = (ruleIndex, actionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];

        const index = actionIndex;
        const nextIndex = actionIndex + 1;

        if (index === newActions.length - 1) {
            return;
        }

        const action = newActions[index];
        const nextAction = newActions[nextIndex];

        newActions[nextIndex] = {
            ...action,
        };

        newActions[index] = {
            ...nextAction,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowActionCreate = (ruleIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];

        newActions.push({
            booleanOperator: 'All',
            steps: [
                {
                    type: 'Dropdown',
                    value: 'Remove row',
                },
            ],
            conditions: [],
        });

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowConditionOperandChange = (item, ruleIndex, actionIndex, conditionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        newConditions[conditionIndex] = {
            ...newConditions[conditionIndex],
            operand: item.label,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowConditionOperandDetailChange = (text, ruleIndex, actionIndex, conditionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        newConditions[conditionIndex] = {
            ...newConditions[conditionIndex],
            operandDetail: text,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowConditionOperatorChange = (item, ruleIndex, actionIndex, conditionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        const hasNoValue = item.label === 'Has a value';

        newConditions[conditionIndex] = {
            ...newConditions[conditionIndex],
            operator: item.label,
            value: hasNoValue ? undefined : 'Some value',
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowConditionValueChange = (text, ruleIndex, actionIndex, conditionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        newConditions[conditionIndex] = {
            ...newConditions[conditionIndex],
            value: text,
        };

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowConditionDelete = (ruleIndex, actionIndex, conditionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];
        const newConditions = newActions[actionIndex].conditions.filter((condition, i) => i !== conditionIndex);

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onRowConditionCreate = (ruleIndex, actionIndex) => {
        const newRowRules = [...this.state.selectedRuleset.rowRules];
        const newActions = [...newRowRules[ruleIndex].actions];
        const newConditions = [...newActions[actionIndex].conditions];

        newConditions.push({
            operand: 'Cell with mapped column name',
            operandDetail: 'Some name',
            operator: 'Equals',
            value: 'Some value',
        });

        newActions[actionIndex] = {
            ...newActions[actionIndex],
            conditions: newConditions,
        };

        newRowRules[ruleIndex] = {
            ...newRowRules[ruleIndex],
            actions: newActions,
        };

        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                rowRules: newRowRules,
            },
        });
    };

    private onShouldCreateHeadersOnNoMatchChange = (e) => {
        this.setState({
            selectedRuleset: {
                ...this.state.selectedRuleset,
                shouldCreateHeadersOnNoMatch: e.target.checked,
            },
        });
    };

    public componentDidMount = async () => {
        this.loadRulesets();
    };

    public render = () => {
        const { onClose } = this.props;
        const { selectedRulesetIndex, isLoading, shouldRetryLoad } = this.state;

        return (
            <div key={`ruleset-${selectedRulesetIndex}`}>
                {!isLoading ? (
                    <>
                        <div className={styles['modal-row-item-16']}>
                            <div>
                                <span style={{ marginRight: 8 }}>Ruleset</span>
                                <Select
                                    options={this.getRulesetsDropdown()}
                                    onChange={this.onRulesetClick}
                                    value={this.getSavedRulesetFromSelectedRulesetIndex()?.name}
                                />
                                {!this.isAddingNewRuleset() && (
                                    <Button
                                        className={styles['modal-reload-button']}
                                        type={'default'}
                                        icon={<ReloadOutlined />}
                                        onClick={() => this.onRulesetReload(selectedRulesetIndex)}
                                    />
                                )}
                            </div>
                            <div>
                                <Input
                                    className={styles['modal-ruleset-name-input']}
                                    placeholder={'Some name'}
                                    initialValue={
                                        this.isAddingNewRuleset()
                                            ? undefined
                                            : this.getSavedRulesetFromSelectedRulesetIndex()?.name
                                    }
                                    value={this.state.selectedRuleset.name}
                                    onChange={this.onRulesetNameChange}
                                    label={'Name'}
                                    validator={(text) => {
                                        const isValid = isValidName(text) && !hasWhiteSpaceAtEdges(text);

                                        if (!isValid) {
                                            return 'Please enter a valid name';
                                        }

                                        if (text === 'New') {
                                            return 'New is not a valid name';
                                        }

                                        const existingRuleset = this.state.rulesets.find(
                                            (ruleset) => ruleset.name === text
                                        );
                                        const doesExist = !!existingRuleset;

                                        const isNameSameAsSaved =
                                            text === this.getSavedRulesetFromSelectedRulesetIndex()?.name;

                                        if (doesExist && !isNameSameAsSaved) {
                                            return 'Ruleset currently exists';
                                        }

                                        return undefined;
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles['modal-row-item-16']}>
                            <div className={styles['modal-rules-container']}>
                                <div className={styles['modal-row-item-16']}>
                                    <span style={{ fontWeight: 500 }}>Identification rules</span>
                                </div>
                                <div className={styles['modal-row-item-16']}>
                                    <div className={styles['modal-padding-left-container']}>
                                        <div className={styles['modal-row-item-12']}>
                                            <div>
                                                <span style={{ marginRight: 8 }}>Search by</span>
                                                <Select
                                                    options={this.getSearchByDropdown()}
                                                    onChange={this.onSearchByClick}
                                                    value={this.state.selectedRuleset.identificationRules.searchMode}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles['modal-row-item-12']}>
                                            <div>
                                                <span style={{ marginRight: 8 }}>
                                                    The table(s) can be identified by
                                                </span>
                                                <Select
                                                    options={this.getBooleanOperatorDropdown()}
                                                    onChange={this.onBooleanOperatorClick}
                                                    value={
                                                        this.state.selectedRuleset.identificationRules.booleanOperator
                                                    }
                                                />
                                                <span>of the following conditions</span>
                                            </div>
                                            <div>
                                                <Button
                                                    className={styles['modal-button-small']}
                                                    icon={<PlusOutlined style={{ fontSize: 10 }} />}
                                                    onClick={this.onIdConditionCreate}
                                                />
                                            </div>
                                        </div>
                                        {this.state.selectedRuleset.identificationRules.conditions.map(
                                            (condition, index) => (
                                                <IdCondition
                                                    key={`id-condition-${index}`}
                                                    index={index}
                                                    description={condition}
                                                    searchMode={
                                                        this.state.selectedRuleset.identificationRules.searchMode
                                                    }
                                                    columnAmount={
                                                        this.state.selectedRuleset.identificationRules.conditions.length
                                                    }
                                                    onOperandChange={this.onIdConditionOperandChange}
                                                    onOperatorChange={this.onIdConditionOperatorChange}
                                                    onValueChange={this.onIdConditionValueChange}
                                                    onDelete={this.onIdConditionDelete}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles['modal-row-item-16']}>
                            <div className={styles['modal-rules-container']}>
                                <div className={styles['modal-row-item-16']}>
                                    <span style={{ fontWeight: 500 }}>Column rules</span>
                                    <Button
                                        className={styles['modal-button-small']}
                                        icon={<PlusOutlined style={{ fontSize: 10 }} />}
                                        onClick={this.onColumnRuleCreate}
                                    />
                                </div>

                                {!!this.state.selectedRuleset.columnRules.length && (
                                    <div className={styles['modal-row-item-16']}>
                                        <div className={styles['modal-padding-left-container']}>
                                            {this.state.selectedRuleset.columnRules.map((columnRule, index) => (
                                                <ColumnRule
                                                    key={`ruleset-${selectedRulesetIndex}-column-rule-${index}`}
                                                    ruleIndex={index}
                                                    description={columnRule}
                                                    onRuleDelete={this.onColumnRuleDelete}
                                                    onRuleMoveUp={this.onColumnRuleMoveUp}
                                                    onRuleMoveDown={this.onColumnRuleMoveDown}
                                                    onMappingTypeClick={this.onColumnMappingTypeClick}
                                                    onMappingNameFromChange={this.onColumnMappingNameFromChange}
                                                    onMappingNameToChange={this.onColumnMappingNameToChange}
                                                    onMappingNameFromRowIndexChange={
                                                        this.onMappingNameFromRowIndexChange
                                                    }
                                                    onMappingNameFromColumnIndexChange={
                                                        this.onMappingNameFromColumnIndexChange
                                                    }
                                                    onActionBooleanOperatorClick={
                                                        this.onColumnActionBooleanOperatorClick
                                                    }
                                                    onActionTypeChange={this.onActionTypeChange}
                                                    onActionSplitOnChange={this.onActionSplitOnChange}
                                                    onActionSliceCreate={this.onActionSliceCreate}
                                                    onActionSliceDelete={this.onActionSliceDelete}
                                                    onActionSliceGreaterThanChange={this.onActionSliceGreaterThanChange}
                                                    onActionSliceStartChange={this.onActionSliceStartChange}
                                                    onActionSliceEndChange={this.onActionSliceEndChange}
                                                    onActionToChange={this.onActionToChange}
                                                    onActionColumnNamesChange={this.onActionColumnNamesChange}
                                                    onActionJoinStringChange={this.onActionJoinStringChange}
                                                    onActionSkipDuplicatesChange={this.onActionSkipDuplicatesChange}
                                                    onActionDelete={this.onColumnActionDelete}
                                                    onActionMoveUp={this.onColumnActionMoveUp}
                                                    onActionMoveDown={this.onColumnActionMoveDown}
                                                    onActionCreate={this.onColumnActionCreate}
                                                    onConditionOperandChange={this.onColumnConditionOperandChange}
                                                    onConditionOperatorChange={this.onColumnConditionOperatorChange}
                                                    onConditionValueChange={this.onColumnConditionValueChange}
                                                    onConditionDelete={this.onColumnConditionDelete}
                                                    onConditionCreate={this.onColumnConditionCreate}
                                                    onColumnOffsetLookupCreate={this.onColumnOffsetLookupCreate}
                                                    onColumnOffsetLookupXCreate={this.onColumnOffsetLookupXCreate}
                                                    onColumnOffsetLookupXDelete={this.onColumnOffsetLookupXDelete}
                                                    onColumnOffsetLookupXChange={this.onColumnOffsetLookupXChange}
                                                    onColumnOffsetLookupYCreate={this.onColumnOffsetLookupYCreate}
                                                    onColumnOffsetLookupYDelete={this.onColumnOffsetLookupYDelete}
                                                    onColumnOffsetLookupYChange={this.onColumnOffsetLookupYChange}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles['modal-row-item-16']}>
                            <div className={styles['modal-rules-container']}>
                                <div className={styles['modal-row-item-16']}>
                                    <span style={{ fontWeight: 500 }}>Row rules</span>
                                    <Button
                                        className={styles['modal-button-small']}
                                        icon={<PlusOutlined style={{ fontSize: 10 }} />}
                                        onClick={this.onRowRuleCreate}
                                    />
                                </div>

                                {!!this.state.selectedRuleset.rowRules.length && (
                                    <div className={styles['modal-row-item-16']}>
                                        <div className={styles['modal-padding-left-container']}>
                                            {this.state.selectedRuleset.rowRules.map((rowRule, index) => (
                                                <RowRule
                                                    key={`ruleset-${selectedRulesetIndex}-row-rule-${index}`}
                                                    ruleIndex={index}
                                                    description={rowRule}
                                                    onRuleDelete={this.onRowRuleDelete}
                                                    onRuleMoveUp={this.onRowRuleMoveUp}
                                                    onRuleMoveDown={this.onRowRuleMoveDown}
                                                    onActionBooleanOperatorClick={this.onRowActionBooleanOperatorClick}
                                                    onActionStepsChange={this.onRowActionStepsChange}
                                                    onActionDelete={this.onRowActionDelete}
                                                    onActionMoveUp={this.onRowActionMoveUp}
                                                    onActionMoveDown={this.onRowActionMoveDown}
                                                    onActionCreate={this.onRowActionCreate}
                                                    onConditionOperandChange={this.onRowConditionOperandChange}
                                                    onConditionOperandDetailChange={
                                                        this.onRowConditionOperandDetailChange
                                                    }
                                                    onConditionOperatorChange={this.onRowConditionOperatorChange}
                                                    onConditionValueChange={this.onRowConditionValueChange}
                                                    onConditionDelete={this.onRowConditionDelete}
                                                    onConditionCreate={this.onRowConditionCreate}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles['modal-row-item-16']}>
                            <div>
                                <span style={{ marginRight: 8 }}>
                                    The transformed table headers can be found in the
                                </span>
                                <Select
                                    options={this.getOutputHeaderDropdown()}
                                    onChange={this.onOutputHeaderClick}
                                    value={this.state.selectedRuleset.outputHeaderPosition}
                                />
                            </div>
                        </div>
                        <div className={styles['modal-row-item-16']}>
                            <div>
                                <Checkbox
                                    onChange={this.onShouldCreateHeadersOnNoMatchChange}
                                    checked={this.state.selectedRuleset.shouldCreateHeadersOnNoMatch}
                                />
                                <span style={{ marginLeft: 8 }}>
                                    Should create top row table headers if their &apos;from name&apos; values do not
                                    match.
                                </span>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {shouldRetryLoad ? (
                            <div className={styles['modal-retry-container']}>
                                <Result
                                    title="Failed to load rulesets"
                                    subTitle="Please click on `Reload` to try again."
                                    status="error"
                                    icon={<CloseCircleFilled style={{ fontSize: 32 }} />}
                                    extra={[
                                        <Button
                                            size={'middle'}
                                            danger={true}
                                            type={'primary'}
                                            key={'reload'}
                                            icon={<ReloadOutlined rev={'true'} />}
                                            onClick={() => this.loadRulesets()}
                                        >
                                            Reload
                                        </Button>,
                                    ]}
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 32,
                                    height: 256,
                                }}
                            >
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} />
                            </div>
                        )}
                    </>
                )}
                <div className={styles['modal-footer-row']}>
                    <div className={styles['modal-footer-row-left']}>
                        {!isLoading && !this.isAddingNewRuleset() && (
                            <Popconfirm
                                title={'Delete the current ruleset'}
                                description={'Are you sure you want to delete the current ruleset?'}
                                onConfirm={this.onDeleteRuleset}
                                okText={'Yes'}
                                cancelText={'No'}
                            >
                                <Button
                                    key={'modal-delete-button'}
                                    danger={true}
                                    className={styles['modal-footer-row-button']}
                                    loading={this.state.isDeletingRuleset}
                                    disabled={this.state.isCreatingOrSavingRuleset}
                                >
                                    Delete
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                    <div className={styles['modal-footer-row-right']}>
                        <Button key={'Close'} onClick={onClose} className={styles['modal-footer-row-button']}>
                            Close
                        </Button>
                        {!isLoading && (
                            <Button
                                key={'modal-create-button'}
                                type={'primary'}
                                onClick={this.isAddingNewRuleset() ? this.onCreateRuleset : this.onSaveRuleset}
                                className={styles['modal-footer-row-button']}
                                loading={this.state.isCreatingOrSavingRuleset}
                                disabled={
                                    (this.isAddingNewRuleset()
                                        ? !this.isNewRulesetValid()
                                        : !this.isSelectedRulesetValid()) || this.state.isDeletingRuleset
                                }
                            >
                                {this.isAddingNewRuleset() ? 'Create' : 'Save'}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    };
}

function withHooks(Component) {
    return function WrappedComponent(props) {
        const { notificationApi } = useContextValues();
        return <Component {...props} notificationApi={notificationApi} />;
    };
}

export default withHooks(TransformsTab);
