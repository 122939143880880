import React from 'react';
import { Outlet } from 'react-router-dom';
import { Card } from 'antd';

import styles from './form-crud.module.scss';

export default function FormCrud() {
    return (
        <div className={styles['form-crud']}>
            <Card>
                <Outlet />
            </Card>
        </div>
    );
}
