import React from 'react';
import { Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { Select } from './select';
import { Input } from './input';
import { hasWhiteSpaceAtEdges, isOnlyWhiteSpace, isCommaDelimitedText } from 'helpers';
import $check from 'check-types';

import styles from './columnActionDetails.module.scss';

type Props = {
    ruleIndex: number;
    actionIndex: number;
    details: any;
    onActionTypeChange: (value: string, ruleIndex: number, actionIndex: number) => void;

    // type === 'Split cell content'
    onSplitOnChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onSliceCreate: (ruleIndex: number, actionIndex: number) => void;
    onSliceDelete: (ruleIndex: number, actionIndex: number) => void;
    onSliceGreaterThanChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onSliceStartChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onSliceEndChange: (text: string, ruleIndex: number, actionIndex: number) => void;

    // type === 'Convert cell'
    onToChange: (text: string, ruleIndex: number, actionIndex: number) => void;

    // type === 'Remove column' does not have any specifics.

    // type === 'Merge in columns'
    onColumnNamesChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onJoinStringChange: (text: string, ruleIndex: number, actionIndex: number) => void;
    onSkipDuplicatesChange: (value: string, ruleIndex: number, actionIndex: number) => void;
};

export class ColumnActionDetails extends React.PureComponent<Props> {
    private getActionTypeItems = () => {
        return [
            { value: 'Split cell content', label: 'Split cell content' },
            { value: 'Convert cell', label: 'Convert cell' },
            { value: 'Remove column', label: 'Remove column' },
            { value: 'Merge in columns', label: 'Merge in columns' },
        ];
    };

    private getSkipDuplicatesItems = () => {
        return [
            { value: 'No', label: 'No' },
            { value: 'Yes', label: 'Yes' },
        ];
    };

    private getConvertIntoItems = () => {
        return [
            { value: 'Number', label: 'Number' },
            { value: 'Date', label: 'Date' },
        ];
    };

    public render = () => {
        const {
            ruleIndex,
            actionIndex,
            details,
            onActionTypeChange,
            onSplitOnChange,
            onSliceCreate,
            onSliceDelete,
            onSliceGreaterThanChange,
            onSliceStartChange,
            onSliceEndChange,
            onToChange,
            onColumnNamesChange,
            onJoinStringChange,
            onSkipDuplicatesChange,
        } = this.props;

        const { type, splitOn, to, columnNames, skipDuplicates, joinString, slice } = details;

        return (
            <div className={styles['container']}>
                <div className={styles['top']}>
                    <div className={`${type !== 'Remove column' ? styles['row-margin-bottom'] : styles['row']}`}>
                        <Select
                            options={this.getActionTypeItems()}
                            value={type}
                            onChange={(value, item) => {
                                onActionTypeChange(value, ruleIndex, actionIndex);
                            }}
                        />
                    </div>
                </div>
                <div className={styles['bottom']}>
                    {type === 'Split cell content' && (
                        <>
                            <div className={styles['row']}>
                                <div className={styles['text']}>
                                    <span>On</span>
                                </div>

                                <Input
                                    className={styles['input']}
                                    placeholder={'Some value'}
                                    value={splitOn}
                                    onChange={(e) => {
                                        const text = e.target.value;

                                        onSplitOnChange(text, ruleIndex, actionIndex);
                                    }}
                                    validator={(text) => {
                                        if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                            return 'Please enter a valid value';
                                        }

                                        return undefined;
                                    }}
                                />
                            </div>

                            <div className={styles['row']}>
                                {!slice && (
                                    <Button type={'default'} onClick={() => onSliceCreate(ruleIndex, actionIndex)}>
                                        Add slice
                                    </Button>
                                )}

                                {!!slice && (
                                    <div className={styles['container']}>
                                        <div className={styles['row']}>
                                            <div className={styles['text']}>
                                                <span>Slice details</span>
                                            </div>
                                            <div className={styles['button-container']}>
                                                <Button
                                                    className={styles['button']}
                                                    icon={<MinusOutlined style={{ fontSize: 10 }} />}
                                                    onClick={() => onSliceDelete(ruleIndex, actionIndex)}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles['row']}>
                                            <div className={styles['text']}>
                                                <span>If the length is greater than</span>
                                            </div>

                                            <Input
                                                className={styles['input']}
                                                placeholder={'Some value'}
                                                value={slice?.ifLengthGreaterThan}
                                                onChange={(e) => {
                                                    const text = e.target.value;

                                                    onSliceGreaterThanChange(text, ruleIndex, actionIndex);
                                                }}
                                                validator={(text) => {
                                                    if (
                                                        !text?.length ||
                                                        isOnlyWhiteSpace(text) ||
                                                        hasWhiteSpaceAtEdges(text) ||
                                                        !$check.integer(Number(text)) ||
                                                        Number(text) < 0
                                                    ) {
                                                        return 'Please enter a valid positive integer value';
                                                    }

                                                    return undefined;
                                                }}
                                            />
                                        </div>

                                        <div className={styles['row']}>
                                            <div className={styles['text']}>
                                                <span>Start</span>
                                            </div>

                                            <Input
                                                className={styles['input']}
                                                placeholder={'Some value'}
                                                value={slice?.start}
                                                onChange={(e) => {
                                                    const text = e.target.value;

                                                    onSliceStartChange(text, ruleIndex, actionIndex);
                                                }}
                                                validator={(text) => {
                                                    if (
                                                        !text?.length ||
                                                        isOnlyWhiteSpace(text) ||
                                                        hasWhiteSpaceAtEdges(text) ||
                                                        !$check.integer(Number(text)) ||
                                                        Number(text) < 0
                                                    ) {
                                                        return 'Please enter a valid positive integer value';
                                                    }

                                                    return undefined;
                                                }}
                                            />
                                        </div>

                                        <div className={styles['row']}>
                                            <div className={styles['text']}>
                                                <span>End</span>
                                            </div>

                                            <Input
                                                className={styles['input']}
                                                placeholder={'Some value'}
                                                value={slice?.end}
                                                onChange={(e) => {
                                                    const text = e.target.value;

                                                    onSliceEndChange(text, ruleIndex, actionIndex);
                                                }}
                                                validator={(text) => {
                                                    if (
                                                        !text?.length ||
                                                        isOnlyWhiteSpace(text) ||
                                                        hasWhiteSpaceAtEdges(text) ||
                                                        !$check.integer(Number(text)) ||
                                                        Number(text) < 0
                                                    ) {
                                                        return 'Please enter a valid positive integer value';
                                                    }

                                                    return undefined;
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {type === 'Convert cell' && (
                        <div className={styles['row-left']}>
                            <div className={styles['text']}>
                                <span>Into</span>
                            </div>

                            <Select
                                options={this.getConvertIntoItems()}
                                value={to}
                                onChange={(value, item) => {
                                    onToChange(value, ruleIndex, actionIndex);
                                }}
                            />
                        </div>
                    )}

                    {type === 'Merge in columns' && (
                        <>
                            <div className={styles['row']}>
                                <div className={styles['text']}>
                                    <span>From (CSV delimited string)</span>
                                </div>

                                <Input
                                    className={styles['input']}
                                    placeholder={'Some value'}
                                    value={columnNames}
                                    onChange={(e) => {
                                        const text = e.target.value;

                                        onColumnNamesChange(text, ruleIndex, actionIndex);
                                    }}
                                    validator={(text) => {
                                        if (!text?.length || isOnlyWhiteSpace(text) || hasWhiteSpaceAtEdges(text)) {
                                            return 'Please enter a valid value';
                                        }

                                        if (!isCommaDelimitedText(text)) {
                                            return 'Please enter a valid value';
                                        }

                                        return undefined;
                                    }}
                                />
                            </div>

                            <div className={styles['row']}>
                                <div className={styles['text']}>
                                    <span>String to join with (defaults to a space)</span>
                                </div>

                                <Input
                                    className={styles['input']}
                                    placeholder={'Some value'}
                                    value={joinString}
                                    onChange={(e) => {
                                        const text = e.target.value;

                                        onJoinStringChange(text, ruleIndex, actionIndex);
                                    }}
                                    validator={(text) => {
                                        if (!text?.length) {
                                            return 'Please enter a valid value';
                                        }

                                        return undefined;
                                    }}
                                />
                            </div>

                            <div className={styles['row-left']}>
                                <div className={styles['text']}>
                                    <span>Should skip duplicates (defaults to No)</span>
                                </div>

                                <Select
                                    options={this.getSkipDuplicatesItems()}
                                    value={skipDuplicates}
                                    onChange={(value, item) => {
                                        onSkipDuplicatesChange(value, ruleIndex, actionIndex);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };
}
