import { createSlice } from '@reduxjs/toolkit';
import { AnalyzeResponse } from 'pages/label/models/analyzeResult';
import { IDocument } from 'pages/label/store/documents/documentsTypes';

type ConfidenceTableField = {
    rows: {
        name?: string;
        words: {
            columnName: string;
            content: string;
            confidence: string | number;
        }[];
    }[];
};

export type ConfidenceTableFields = {
    [name: string]: ConfidenceTableField;
};

export interface IPrediction {
    id: string;
    analyzeResponse: AnalyzeResponse;
    confidenceTableFields?: ConfidenceTableFields;
}

export interface IPredictionPayload {
    targetDocument: IDocument;
    endpoint: string;
    key: string;
}

export type PredictionsState = {
    predictions: { [id: string]: IPrediction };
};

export const initialState: PredictionsState = {
    predictions: {},
};

const predictionsSlice = createSlice({
    name: 'predictions',
    initialState,
    reducers: {
        setDocumentPrediction(state, action) {
            const { id, analyzeResponse, confidenceTableFields } = action.payload;
            state.predictions[id] = { id, analyzeResponse };

            if (confidenceTableFields) {
                state.predictions[id].confidenceTableFields = confidenceTableFields;
            }
        },
        resetPredictions(state) {
            state.predictions = {};
        },
    },
});

export const { setDocumentPrediction, resetPredictions } = predictionsSlice.actions;

export const reducer = predictionsSlice.reducer;
