import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Typography, Select } from 'antd';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formService } from '../data/formService';
import { IFormValues } from '../data/model';
import { useContextValues } from '../../../components/providers/MainProvider';
import { DOCUMENT_TREE_VIEW, FORM_TREE_VIEW } from '../../../data/consts/apiQuery';
import { formTypes } from '../data/data';

import styles from '../form-crud.module.scss';

interface IProps {
    type: 'Document' | 'Paged Form';
}

export function FormCreate({ type }: IProps) {
    const queryClient = useQueryClient();
    const { notificationApi } = useContextValues();
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation({
        mutationFn: (values: IFormValues) => {
            return formService.createForm(values);
        },
        onSuccess: (data) => {
            notificationApi.success({
                message: type === formTypes.document ? 'Created document' : 'Created paged form',
                description:
                    type === formTypes.document
                        ? 'A new document has been created.'
                        : 'A new paged form has been created.',
                placement: 'topRight',
            });

            queryClient.invalidateQueries({
                queryKey: [type === formTypes.document ? DOCUMENT_TREE_VIEW : FORM_TREE_VIEW],
            });

            const isDocument = type?.toLowerCase?.() === 'document';

            if (isDocument) {
                const documentId = data?._id;

                navigate(`/label/document/${documentId}`);
            }
        },
        onError: (error: any) => {
            notificationApi.error({
                message: type === formTypes.document ? 'Failed to create document' : 'Failed to create paged form',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    const handleFormSubmit = (values) => {
        mutateCreate(values);
    };

    return (
        <>
            <Typography.Paragraph className={styles.card__title}>
                {type === formTypes.document ? 'Create document' : 'Create paged form'}
            </Typography.Paragraph>

            <Form form={form} layout="vertical" size="large" onFinish={(val) => handleFormSubmit({ ...val, type })}>
                <Form.Item
                    label="Title"
                    name="title"
                    required
                    rules={[{ required: true, message: 'Title cannot be empty' }]}
                >
                    <Input style={{ width: '50%' }} placeholder="Please enter a title" size="middle" />
                </Form.Item>

                <Form.Item label="Description" name="description">
                    <Input.TextArea rows={4} placeholder="Please enter a description" size="middle" />
                </Form.Item>

                <Form.Item label="Post script" name="postScript">
                    <CodeEditor
                        className={styles.form__script_field}
                        language="js"
                        placeholder="Please enter JS code."
                        padding={10}
                        minHeight={150}
                    />
                </Form.Item>

                <Form.Item label="Build Type" name="buildMode">
                    <Select
                        defaultValue="template"
                        style={{ width: 120 }}
                        options={[
                            { value: 'template', label: 'Template' },
                            { value: 'neural', label: 'Neural' },
                        ]}
                    />
                </Form.Item>

                <Button size={'middle'} loading={isLoadingCreate} type="primary" htmlType="submit">
                    {isLoadingCreate ? 'Creating' : 'Create'}
                </Button>
            </Form>
        </>
    );
}
