import * as React from 'react';
import { DefaultButton, IContextualMenuItem, Spinner } from '@fluentui/react';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from 'pages/label/store';
import { setAngle } from 'pages/label/store/canvas/canvas';
import { setCurrentPage } from 'pages/label/store/documents/documents';
import { DocumentStatus } from 'pages/label/store/documents/documentsTypes';
import { defaultStyler } from 'pages/label/utils/styler';
import { AnalyzeProgressBar } from 'pages/label/components/analyzeProgressBar/analyzeProgressBar';
import { DrawRegionButton } from 'pages/label/components/buttons/buttons';
import { ImageMap } from 'pages/label/components/imageMap/imageMap';
import ImageMapToolbar from 'pages/label/components/imageMapToolbar/imageMapToolbar';
import LayerFilter, { ILayerCheckStates } from 'pages/label/components/layerFilter/layerFilter';
import PageControl from 'pages/label/components/pageControl/pageControl';
import withOcr from 'pages/label/components/withOcr/withOcr';
import withTable from 'pages/label/components/withTable/withTable';
import withCustomModelLabel from 'pages/label/containers/withCustomModelLabel/withCustomModelLabel';

import { TransformModal } from '../../components/transformModal/transformModal';

import './labelCanvas.scss';

const WrappedImageMap = withCustomModelLabel(withTable(withOcr(ImageMap)));

interface ILabelCanvasState {
    progress: number | undefined;
    layerCheckStates: ILayerCheckStates;
    drawRegionMode: boolean;
    zoomRatio: number | undefined;
    showTransformsModal: boolean;
}

type LabelCanvasProps = ConnectedProps<typeof connector> & {
    formId?: string;
    isReadonly: boolean;
    isDisabledAnalyze: boolean;
    isLoadingExtract: boolean;
    isLoadingAnalyze: boolean;
    isDocumentExtracted: boolean;
    getAndSetOcr: () => void;
    handleAnalyzeButtonClick: () => void;
};

export class LabelCanvas extends React.PureComponent<LabelCanvasProps, ILabelCanvasState> {
    private imageMap: ImageMap | null = null;

    constructor(props) {
        super(props);
        this.state = {
            progress: undefined,
            layerCheckStates: {
                text: true,
                tables: true,
                selectionMarks: true,
            },
            drawRegionMode: false,
            zoomRatio: this.imageMap?.getZoom(),
            showTransformsModal: false,
        };
    }

    public componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentDocument !== this.props.currentDocument) {
            // Hack: force the progress of the Indeterminate ProgressIndicator reset.
            this.setState({ progress: 0 }, () => this.setState({ progress: undefined }));
        }
    }

    // Page Control
    private goToPage = (page: number) => {
        this.props.setCurrentPage(page);
    };

    private previousPage = () => {
        const { currentDocument, setCurrentPage } = this.props;
        if (currentDocument) {
            setCurrentPage(currentDocument.currentPage - 1);
        }
    };

    private nextPage = () => {
        const { currentDocument, setCurrentPage } = this.props;
        if (currentDocument) {
            setCurrentPage(currentDocument.currentPage + 1);
        }
    };

    // ImageMap Toolbar
    private setImageMap = (ref) => {
        this.imageMap = ref;
    };

    private handleImageZoomIn = () => {
        this.imageMap?.zoomIn();
        this.setState({
            zoomRatio: this.imageMap?.getZoom(),
        });
    };

    private handleImageZoomOut = () => {
        this.imageMap?.zoomOut();
        this.setState({
            zoomRatio: this.imageMap?.getZoom(),
        });
    };

    private handleImageZoomToFit = () => {
        this.imageMap?.resetZoom();
        this.imageMap?.resetCenter();
    };

    private handleImageRotate = () => {
        const { canvas, setAngle } = this.props;
        if (canvas.angle >= 0) {
            const newAngle = (canvas.angle + 90) % 360;
            setAngle(newAngle);
        }
    };

    private handleLayerFilterChange = (item: IContextualMenuItem) => {
        const featureVisibility = !item.checked;
        switch (item.key) {
            case 'text':
                this.imageMap?.toggleTextFeatureVisibility(featureVisibility);
                break;
            case 'tables':
                this.imageMap?.toggleTableFeatureVisibility(featureVisibility);
                break;
            case 'selectionMarks':
                this.imageMap?.toggleCheckboxFeatureVisibility(featureVisibility);
                break;
            default:
                break;
        }

        this.setState((prevState) => ({
            layerCheckStates: {
                ...prevState.layerCheckStates,
                [item.key]: featureVisibility,
            },
        }));
    };

    private noOp = () => {
        // no operation
    };

    private handleDrawRegion = () => {
        // Toggle draw region mode.
        this.setState({ drawRegionMode: !this.state.drawRegionMode });
    };

    public render() {
        const { currentDocument, canvas, predictions } = this.props;
        const { progress, layerCheckStates, drawRegionMode, zoomRatio } = this.state;
        const { imageUrl, width, height, angle } = canvas;
        const isAnalyzing = currentDocument?.states.analyzingStatus === DocumentStatus.Analyzing;
        const isButtonDisabled = isAnalyzing || !this.props.currentDocument;

        return (
            <div className="label-canvas">
                <div className="label-canvas-command-bar">
                    <div className="label-canvas-command-bar-inner">
                        <div className="label-canvas-command-bar-select" id="label-canvas-command-bar-select"></div>
                        {this.props.isReadonly ? (
                            <DefaultButton
                                iconProps={{ iconName: this.props.isLoadingAnalyze ? '' : 'DoubleBookmark' }}
                                onClick={this.props.handleAnalyzeButtonClick}
                                disabled={this.props.isDisabledAnalyze || this.props.isLoadingAnalyze}
                                styles={{ root: { border: 0 } }}
                            >
                                {this.props.isLoadingAnalyze ? 'Analyzing' : 'Analyze'}
                                {this.props.isLoadingAnalyze && <Spinner styles={{ root: { marginLeft: 10 } }} />}
                            </DefaultButton>
                        ) : (
                            <>
                                <DrawRegionButton
                                    onClick={this.handleDrawRegion}
                                    disabled={isButtonDisabled}
                                    checked={drawRegionMode}
                                />
                                {currentDocument?.name && currentDocument?.ocrExtractResponse?.analyzeResult && (
                                    <DefaultButton
                                        ariaLabel={'Transform'}
                                        onClick={() => {
                                            this.setState({ showTransformsModal: !this.state.showTransformsModal });
                                        }}
                                        styles={{ root: { border: 0 } }}
                                    >
                                        Table transforms
                                    </DefaultButton>
                                )}
                                <DefaultButton
                                    iconProps={{ iconName: this.props.isLoadingExtract ? '' : 'Label' }}
                                    ariaLabel="Extract Layout"
                                    onClick={() => this.props.getAndSetOcr()}
                                    disabled={this.props.isDocumentExtracted || this.props.isLoadingExtract}
                                    styles={{ root: { border: 0 } }}
                                >
                                    {this.props.isLoadingExtract ? 'Extracting' : 'Extract'}
                                    {this.props.isLoadingExtract && <Spinner styles={{ root: { marginLeft: 10 } }} />}
                                </DefaultButton>
                            </>
                        )}
                    </div>
                    <LayerFilter
                        disabled={isButtonDisabled}
                        onItemClick={this.handleLayerFilterChange}
                        checkStates={layerCheckStates}
                    />
                </div>
                <div className="label-canvas-image-map">
                    <WrappedImageMap
                        currentDocument={currentDocument}
                        predictions={predictions}
                        setImageMap={this.setImageMap}
                        imageUri={imageUrl}
                        imageWidth={width}
                        imageHeight={height}
                        imageAngle={angle}
                        initLayoutMap={true}
                        onMapReady={this.noOp}
                        featureStyler={defaultStyler}
                        drawRegionMode={drawRegionMode}
                    />
                    {currentDocument && isAnalyzing && (
                        <div className="label-canvas-overlay">
                            <AnalyzeProgressBar
                                title="Running analysis:"
                                subtitle={currentDocument.name}
                                percentComplete={progress}
                            />
                        </div>
                    )}
                </div>
                <div className="label-canvas-control-bar">
                    <div className="label-canvas-page-control">
                        <PageControl
                            disabled={isButtonDisabled}
                            currentPage={currentDocument?.currentPage}
                            numPages={currentDocument?.numPages}
                            onPageChange={this.goToPage}
                            onPreviousClick={this.previousPage}
                            onNextClick={this.nextPage}
                        />
                    </div>
                    <div className="label-canvas-tool-bar">
                        <ImageMapToolbar
                            disabled={isButtonDisabled}
                            zoomRatio={zoomRatio}
                            rotateAngle={angle}
                            onZoomInClick={this.handleImageZoomIn}
                            onZoomOutClick={this.handleImageZoomOut}
                            onZoomToFitClick={this.handleImageZoomToFit}
                            onRotateClick={this.handleImageRotate}
                        />
                    </div>
                </div>
                {currentDocument?.name && currentDocument?.ocrExtractResponse?.analyzeResult && (
                    <TransformModal
                        key={currentDocument?.name}
                        formId={this.props.formId}
                        isOpen={this.state.showTransformsModal}
                        currentDocument={currentDocument}
                        analyzeResult={currentDocument?.ocrExtractResponse?.analyzeResult}
                        currentFileName={currentDocument?.name}
                        onClose={() => this.setState({ showTransformsModal: false })}
                    />
                )}
            </div>
        );
    }
}

const mapState = (state: ApplicationState) => ({
    currentDocument: state.documents.currentDocument,
    predictions: state.predictions.predictions,
    canvas: state.canvas.canvas,
});
const mapDispatch = { setCurrentPage, setAngle };

const connector = connect(mapState, mapDispatch);

export default connector(LabelCanvas);
