import { getRegExpObjFromString, getRegExpObjPattern, isRegExpObj } from 'helpers/regex';
import $check from 'check-types';

import {
    ColumnCondition,
    ColumnMappingRule,
    ColumnTransformation,
    TableMappingRules,
    TableSearchRules,
    ColumnNameRule,
    RegExpObj,
    MappingCoordinate,
} from '@synatic/ocr-table-actions';

import {
    ColumnRule,
    ColumnRuleAction,
    ColumnRuleActionCondition,
    IdentificationCondition,
    IdentificationRules,
    OffsetLookup,
    RowRule,
    RowRuleAction,
    RowRuleActionCondition,
    RowRuleActionStep,
    Ruleset,
} from 'types';

import { RowCondition, RowTransformation } from '@synatic/ocr-table-actions';

export type TableAction = {
    rulesetName: string;
    searchRule: TableSearchRules;
    transformationRules: TableMappingRules;
};

const booleanOperatorMap = {
    All: 'and',
    Any: 'or',
};

const outputHeaderPositionMap = {
    'Top row': 'Top Row',
    'Left column': 'Left Col',
};

// TODO: 'Merge row' needs to have a better FE name, e.g. 'Merge row above' or 'Merge row below'?
const rowActionTypeMap = {
    'Remove row': 'Remove Row',
    'Merge row': 'Merge Row',
};

const getTableStructureRulesFromConditions = (conditions: IdentificationCondition[]) => {
    const columnCount = conditions.find((condition) => condition.operand === 'Column count');
    const rowCount = conditions.find((condition) => condition.operand === 'Row count');
    const tableIndex = conditions.find((condition) => condition.operand === 'Table index');
    const containsContent = conditions.find((condition) => condition.operand === 'Content');

    return {
        columnCount: $check.number(Number(columnCount)) ? Number(columnCount) : undefined,
        rowCount: $check.number(Number(rowCount)) ? Number(rowCount) : undefined,
        tableIndex: $check.number(Number(tableIndex)) ? Number(tableIndex) : undefined,
        containsContent: containsContent ? getRegExpObjFromString(containsContent) : undefined,
    };
};

// FE -> BE
export const getTableSearchRulesFromRuleset = (ruleset: Ruleset): TableSearchRules | void => {
    const identificationRules = ruleset.identificationRules || {};
    const { searchMode, booleanOperator, conditions } = identificationRules;

    if (searchMode === 'Column names') {
        return {
            searchMode: 'Column Names',
            columnNameJoinType: booleanOperatorMap[booleanOperator],
            columnNameRules: conditions.map((condition, index) => ({
                ruleId: String(index),
                rule:
                    condition.operator === 'Matches'
                        ? getRegExpObjFromString(condition.value)
                        : String(condition.value),
            })),
        };
    }

    if (searchMode === 'Table structure') {
        return {
            searchMode: 'Table Structure',
            columnNameJoinType: booleanOperatorMap[booleanOperator],
            tableStructureRules: getTableStructureRulesFromConditions(conditions),
        };
    }

    throw new Error('Supplied search mode not supported!');
};

const getColumnConditionsFromColumnRuleActionConditions = (
    columnRuleActionConditions: ColumnRuleActionCondition[]
): ColumnCondition[] => {
    const columnConditions: ColumnCondition[] = [];

    columnRuleActionConditions.forEach((condition) => {
        const { operator, value } = condition;

        const columnCondition = {
            valueEquals: operator === 'Equals' ? value : undefined,
            valueMatches: operator === 'Matches' ? getRegExpObjFromString(value) : undefined,
            hasValue: operator === 'Has a value' ? true : undefined,
        };

        columnConditions.push(columnCondition);
    });

    return columnConditions;
};

const getColumnActionDescriptionFromDetails = ({
    type,
    splitOn,
    to,
    columnNames,
    skipDuplicates,
    joinString,
    slice,
}) => {
    if (type === 'Split cell content') {
        return {
            type: 'ContentSplit',
            splitOn: splitOn,
            slice: slice
                ? {
                      ifLengthGreaterThan: Number(slice?.ifLengthGreaterThan),
                      start: Number(slice?.start),
                      end: Number(slice?.end),
                  }
                : undefined,
        };
    }

    if (type === 'Convert cell') {
        const toMap = {
            Number: 'number',
            Date: 'date',
        };

        return {
            type: 'Convert',
            to: toMap[to],
        };
    }

    if (type === 'Merge in columns') {
        const columnNameArray = columnNames.split(',').map((name) => name.trim());

        const skipDuplicatesMap = {
            Yes: true,
            No: false,
        };

        return {
            type: 'MergeColumnsFrom',
            columnNames: columnNameArray,
            joinString: joinString,
            skipDuplicates: skipDuplicatesMap[skipDuplicates],
        };
    }

    if (type === 'Remove column') {
        return {
            type: 'RemoveColumn',
        };
    }

    throw new Error('Supplied column rule transformation type not supported!');
};

const getColumnTransformationsFromColumnRuleActions = (
    columnRuleActions: ColumnRuleAction[]
): ColumnTransformation[] => {
    const columnTransformations: ColumnTransformation[] = [];

    columnRuleActions.forEach((action) => {
        const { booleanOperator, conditions, details } = action;

        const columnTransformation = {
            conditions: getColumnConditionsFromColumnRuleActionConditions(conditions),
            conditionJoinType: booleanOperatorMap[booleanOperator],
            // @ts-ignore
            ...getColumnActionDescriptionFromDetails(details),
        };

        // @ts-ignore
        columnTransformations.push(columnTransformation);
    });

    return columnTransformations;
};

// Nice name :D x2
const getMapFromFromNameFrom = (type, nameFrom, nameFromCoordinates) => {
    if (type === 'RegExp') {
        return getRegExpObjFromString(nameFrom);
    }

    if (type === 'Placeholder') {
        return false;
    }

    if (type === 'Coordinates') {
        return {
            rowIndex: Number(nameFromCoordinates?.rowIndex),
            columnIndex: Number(nameFromCoordinates?.columnIndex),
        };
    }

    return nameFrom;
};

const getColumnMappingRuleFromColumnRules = (columnRules: ColumnRule[]): ColumnMappingRule[] => {
    const columnMappingRules: ColumnMappingRule[] = [];

    columnRules.forEach((columnRule) => {
        const { mapping, actions } = columnRule;
        const { type, nameFrom, nameFromCoordinates, nameTo, offsetLookup } = mapping;

        const columnMappingRule = {
            mappedName: nameTo,
            mapFrom: getMapFromFromNameFrom(type, nameFrom, nameFromCoordinates),
            transformations: getColumnTransformationsFromColumnRuleActions(actions),
            offsetLookup: offsetLookup
                ? {
                      x:
                          typeof offsetLookup.x === 'string' || typeof offsetLookup.x === 'number'
                              ? Number(offsetLookup.x)
                              : undefined,
                      y:
                          typeof offsetLookup.y === 'string' || typeof offsetLookup.y === 'number'
                              ? Number(offsetLookup.y)
                              : undefined,
                  }
                : undefined,

            // TODO: Account for this once aligned.
            // multipleCellJoinString: '',
        };

        columnMappingRules.push(columnMappingRule);
    });

    return columnMappingRules;
};

const getRowConditionsFromRowRuleActionConditions = (conditions: RowRuleActionCondition[]): RowCondition[] => {
    const rowConditions: RowCondition[] = [];

    conditions.forEach((condition) => {
        const { operandDetail, operator, value } = condition;

        const rowCondition = {
            columnName: operandDetail,
            valueEquals: operator === 'Equals' ? value : undefined,
            valueMatches: operator === 'Matches' ? getRegExpObjFromString(value) : undefined,
            valueDoesNotEqual: operator === 'Does not equal' ? value : undefined,
            valueDoesNotMatch: operator === 'Does not match' ? getRegExpObjFromString(value) : undefined,
            hasValue: operator === 'Has a value' ? true : undefined,
        };

        rowConditions.push(rowCondition);
    });

    return rowConditions;
};

const getRowActionDescriptionFromSteps = (steps: RowRuleActionStep[]) => {
    const actionType = String(steps[0]?.value);
    const mappedActionType = rowActionTypeMap[actionType];

    if (mappedActionType === 'Remove Row') {
        return {
            type: mappedActionType,
        };
    }

    if (mappedActionType === 'Merge Row') {
        return {
            type: mappedActionType,
        };
    }

    throw new Error('Supplied row rule action step type not defined!');
};

// RowTransformation is actually missing another abstraction level, so below skips directly to actions.
const getRowTransformationsFromRowRules = (rowRules: RowRule[]): RowTransformation[] => {
    const rowTransformations: RowTransformation[] = [];

    rowRules.forEach((rule) => {
        const { actions }: { actions: RowRuleAction[] } = rule;

        actions.forEach((action: RowRuleAction) => {
            const { booleanOperator, steps, conditions } = action;

            const rowTransformation = {
                conditions: getRowConditionsFromRowRuleActionConditions(conditions),
                conditionJoinType: booleanOperatorMap[booleanOperator],
                ...getRowActionDescriptionFromSteps(steps),
            };

            rowTransformations.push(rowTransformation);
        });
    });

    return rowTransformations;
};

// FE -> BE
export const getTableMappingRulesFromRuleset = (ruleset: Ruleset): TableMappingRules => {
    const { shouldCreateHeadersOnNoMatch, outputHeaderPosition, columnRules, rowRules } = ruleset;

    return {
        columns: getColumnMappingRuleFromColumnRules(columnRules),
        headerPosition: outputHeaderPositionMap[outputHeaderPosition],
        rowTransformations: getRowTransformationsFromRowRules(rowRules),
        shouldCreateHeadersOnNoMatch: !!shouldCreateHeadersOnNoMatch,
    };
};

type tableStructureRules = {
    columnCount?: number;
    rowCount?: number;
    tableIndex?: number;
    containsContent?: RegExp | RegExpObj | string;
};

const getIdentificationConditionsFromDescription = (description: {
    searchMode: string;
    columnNameRules: ColumnNameRule[];
    tableStructureRules: tableStructureRules;
}): IdentificationCondition[] => {
    const { searchMode, columnNameRules, tableStructureRules } = description;

    if (searchMode === 'Column names') {
        return (columnNameRules || []).map(({ ruleId, rule }, index) => ({
            operand: `Column name ${index + 1}`,
            operator: isRegExpObj(rule) ? 'Matches' : 'Equals',
            value: isRegExpObj(rule) ? getRegExpObjPattern(rule as RegExpObj) : String(rule),
        }));
    }

    if (searchMode === 'Table structure') {
        // These string values might prove problematic, though it should do a string comparison on 'Equals', even
        // for numbers. If it's a problem, fix.
        const conditions: IdentificationCondition[] = [];

        if ($check.number(tableStructureRules?.columnCount)) {
            conditions.push({
                operand: 'Column count',
                operator: 'Equals',
                value: String(tableStructureRules?.columnCount),
            });
        }

        if ($check.number(tableStructureRules?.rowCount)) {
            conditions.push({
                operand: 'Row count',
                operator: 'Equals',
                value: String(tableStructureRules?.rowCount),
            });
        }

        if ($check.number(tableStructureRules?.tableIndex)) {
            conditions.push({
                operand: 'Table index',
                operator: 'Equals',
                value: String(tableStructureRules?.tableIndex),
            });
        }

        if (tableStructureRules?.containsContent) {
            conditions.push({
                operand: 'Content',
                operator: isRegExpObj(tableStructureRules?.containsContent) ? 'Matches' : 'Equals',
                value: isRegExpObj(tableStructureRules?.containsContent)
                    ? getRegExpObjPattern(tableStructureRules?.containsContent as RegExpObj)
                    : String(tableStructureRules?.containsContent),
            });
        }

        return conditions;
    }

    throw new Error('Supplied search mode not supported!');
};

const getIdentificationRulesFromSearchRule = (searchRule: TableSearchRules): IdentificationRules => {
    const searchModeToFeSearchModeMap = {
        'Column Names': 'Column names',
        'Table Structure': 'Table structure',
    };

    const joinTypeToBooleanOperatorMap = {
        and: 'All',
        or: 'Any',
    };

    const identificationRules = {
        searchMode: searchModeToFeSearchModeMap[searchRule?.searchMode],
        booleanOperator: joinTypeToBooleanOperatorMap[searchRule?.columnNameJoinType || 'and'],
        conditions: getIdentificationConditionsFromDescription({
            searchMode: searchModeToFeSearchModeMap[searchRule?.searchMode],
            columnNameRules: searchRule.columnNameRules!,
            tableStructureRules: searchRule.tableStructureRules!,
        }),
    };

    return identificationRules;
};

const getColumnRuleActionConditionsFromColumnConditions = (
    columnConditions: ColumnCondition[]
): ColumnRuleActionCondition[] => {
    const conditions: ColumnRuleActionCondition[] = [];

    for (const columnCondition of columnConditions) {
        const { valueEquals, valueMatches, hasValue } = columnCondition;

        if ($check.string(valueEquals)) {
            conditions.push({
                operand: 'Cell',
                operator: 'Equals',
                value: String(valueEquals),
            });

            continue;
        }

        if (isRegExpObj(valueMatches)) {
            conditions.push({
                operand: 'Cell',
                operator: 'Matches',
                value: getRegExpObjPattern(valueMatches as RegExpObj),
            });

            continue;
        }

        if (hasValue) {
            conditions.push({
                operand: 'Cell',
                operator: 'Has a value',
                value: undefined,
            });

            continue;
        }

        // TODO: Support 'valueDoesNotAlreadyContain' once FE is ready.
    }

    return conditions;
};

const getColumnRuleActionDetailsFromColumnTransformation = ({
    type,
    splitOn,
    to,
    columnNames,
    skipDuplicates,
    joinString,
    slice,
}) => {
    const typeMap = {
        ContentSplit: 'Split cell content',
        Convert: 'Convert cell',
        MergeColumnsFrom: 'Merge in columns',
        RemoveColumn: 'Remove column',
    };

    const toMap = {
        number: 'Number',
        date: 'Date',
    };

    return {
        type: typeMap[type],
        splitOn: splitOn || ',',
        slice: {
            ifLengthGreaterThan: String(slice?.ifLengthGreaterThan || '0'),
            start: String(slice?.start || '0'),
            end: String(slice?.end || '0'),
        },
        to: toMap[to || 'number'],
        columnNames: Array.isArray(columnNames) ? columnNames.join(', ') : 'Column 1, Column 2',
        joinString: joinString || ' ',
        skipDuplicates: typeof skipDuplicates === 'boolean' ? (skipDuplicates ? 'Yes' : 'No') : 'No',
    };
};

const getColumnRuleActionFromColumnTransformation = (columnTransformation: ColumnTransformation): ColumnRuleAction => {
    const joinTypeToBooleanOperatorMap = {
        and: 'All',
        or: 'Any',
    };

    const action = {
        booleanOperator: joinTypeToBooleanOperatorMap[columnTransformation?.conditionJoinType || 'and'],
        // @ts-ignore
        details: getColumnRuleActionDetailsFromColumnTransformation(columnTransformation),
        conditions: getColumnRuleActionConditionsFromColumnConditions(columnTransformation?.conditions || []),
    };

    return action;
};

const getColumnRuleActionsFromColumnTransformations = (
    columnTransformations: ColumnTransformation[]
): ColumnRuleAction[] => {
    const actions: ColumnRuleAction[] = [];

    for (const columnTransformation of columnTransformations) {
        actions.push(getColumnRuleActionFromColumnTransformation(columnTransformation));
    }

    return actions;
};

type MapFrom = string | false | RegExp | RegExpObj | MappingCoordinate;

const getTypeFromMapFrom = (mapFrom: MapFrom) => {
    if (mapFrom instanceof RegExp) {
        return 'RegExp';
    }

    if (typeof mapFrom === 'string') {
        return 'String';
    }

    if (!mapFrom) {
        return 'Placeholder';
    }

    if (isRegExpObj(mapFrom)) {
        return 'RegExp';
    }

    if ($check.object(mapFrom)) {
        return 'Coordinates';
    }

    return 'String';
};

// Nice name :D
const getNameFromFromMapFrom = (mapFrom: MapFrom) => {
    const interpretedType = getTypeFromMapFrom(mapFrom);

    if (interpretedType === 'RegExp') {
        return getRegExpObjPattern(mapFrom as RegExpObj);
    }

    if (interpretedType === 'Placeholder') {
        return 'Some name';
    }

    if (interpretedType === 'String') {
        return String(mapFrom);
    }

    // Coordinates should have external property defined, so this can be empty.
    if (interpretedType === 'Coordinates') {
        return 'Some name';
    }

    return String(mapFrom);
};

const getNameFromCoordinates = (mapFrom: MapFrom) => {
    return {
        // @ts-ignore
        rowIndex: String(mapFrom?.rowIndex || '0'),
        // @ts-ignore
        columnIndex: String(mapFrom?.columnIndex || '0'),
    };
};

const getColumnRuleFromColumn = (column: ColumnMappingRule): ColumnRule => {
    const { mapFrom, mappedName, offsetLookup } = column;

    const columnRule = {
        mapping: {
            type: getTypeFromMapFrom(mapFrom),
            nameFrom: getNameFromFromMapFrom(mapFrom),
            nameFromCoordinates: getNameFromCoordinates(mapFrom),
            nameTo: mappedName,
            offsetLookup: offsetLookup
                ? ({
                      x: typeof offsetLookup.x === 'number' ? String(offsetLookup.x) : undefined,
                      y: typeof offsetLookup.y === 'number' ? String(offsetLookup.y) : undefined,
                  } as OffsetLookup)
                : undefined,
        },
        actions: getColumnRuleActionsFromColumnTransformations(column?.transformations || []),
    };

    return columnRule;
};

const getColumnRulesFromColumns = (columns: ColumnMappingRule[]): ColumnRule[] => {
    const columnRules: ColumnRule[] = [];

    for (const column of columns) {
        columnRules.push(getColumnRuleFromColumn(column));
    }

    return columnRules;
};

const getRowRuleActionConditionsFromRowConditions = (rowConditions: RowCondition[]): RowRuleActionCondition[] => {
    const conditions: RowRuleActionCondition[] = [];

    for (const rowCondition of rowConditions) {
        const { columnName, valueEquals, valueMatches, valueDoesNotEqual, valueDoesNotMatch, hasValue } = rowCondition;

        if ($check.string(valueEquals)) {
            conditions.push({
                operand: 'Cell with mapped column name',
                operandDetail: columnName,
                operator: 'Equals',
                value: valueEquals,
            });

            continue;
        }

        if (isRegExpObj(valueMatches)) {
            conditions.push({
                operand: 'Cell with mapped column name',
                operandDetail: columnName,
                operator: 'Matches',
                value: getRegExpObjPattern(valueMatches as RegExpObj),
            });

            continue;
        }

        if ($check.string(valueDoesNotEqual)) {
            conditions.push({
                operand: 'Cell with mapped column name',
                operandDetail: columnName,
                operator: 'Does not equal',
                value: valueDoesNotEqual,
            });

            continue;
        }

        if (isRegExpObj(valueDoesNotMatch)) {
            conditions.push({
                operand: 'Cell with mapped column name',
                operandDetail: columnName,
                operator: 'Does not match',
                value: getRegExpObjPattern(valueDoesNotMatch as RegExpObj),
            });

            continue;
        }

        if (hasValue) {
            conditions.push({
                operand: 'Cell with mapped column name',
                operandDetail: columnName,
                operator: 'Has a value',
                value: undefined,
            });

            continue;
        }
    }

    return conditions;
};

const getRowRuleActionStepsFromDescription = (description: { type: string }): RowRuleActionStep[] => {
    const { type } = description;

    const steps: RowRuleActionStep[] = [];

    const valueMap = {
        'Merge Row': 'Merge row',
        'Remove Row': 'Remove row',
    };

    if (type === 'Merge Row') {
        steps.push({
            type: 'Dropdown',
            value: valueMap[type],
        });
    }

    if (type === 'Remove Row') {
        steps.push({
            type: 'Dropdown',
            value: valueMap[type],
        });
    }

    return steps;
};

const getRowRuleActionsFromRowTransformations = (rowTransformations: RowTransformation[]): RowRuleAction[] => {
    const actions: RowRuleAction[] = [];

    const conditionJoinTypeToBooleanOperatorMap = {
        and: 'All',
        or: 'Any',
    };

    for (const rowTransformation of rowTransformations) {
        const action = {
            booleanOperator: conditionJoinTypeToBooleanOperatorMap[rowTransformation?.conditionJoinType || 'and'],
            steps: getRowRuleActionStepsFromDescription({
                type: rowTransformation?.type,
            }),
            conditions: getRowRuleActionConditionsFromRowConditions(rowTransformation?.conditions),
        };

        actions.push(action);
    }

    return actions;
};

// Because there's an abstraction layer missing for the row rules, i.e. row rules actions do not exist on the BE, just
// a collection of row transformations that are conditionally applied, all transformations would need to be added to
// 1 row rule upon getting them back from the BE.
//
// On the FE, on save, all row rules actions are flattened into a collection of row transforms for the BE to accept.
const getRowRuleFromRowTransformations = (rowTransformations: RowTransformation[]): RowRule[] => {
    const rowRules: RowRule[] = [];

    if (!rowTransformations?.length) {
        return [];
    }

    rowRules.push({
        actions: getRowRuleActionsFromRowTransformations(rowTransformations),
    });

    return rowRules;
};

const getOutputHeaderPositionFromHeaderPosition = (headerPosition: string) => {
    const headerPositionToOutputHeaderPosition = {
        'Top Row': 'Top row',
        'Left Col': 'Left column',
    };

    return headerPositionToOutputHeaderPosition[headerPosition];
};

const getRulesetFromTableAction = (tableAction: TableAction): Ruleset => {
    const ruleset = {
        // @ts-ignore
        id: tableAction?.id || tableAction.rulesetName,
        name: tableAction.rulesetName,
        identificationRules: getIdentificationRulesFromSearchRule(tableAction?.searchRule),
        columnRules: getColumnRulesFromColumns(tableAction?.transformationRules?.columns),
        rowRules: getRowRuleFromRowTransformations(tableAction?.transformationRules?.rowTransformations || []) || [],
        outputHeaderPosition: getOutputHeaderPositionFromHeaderPosition(
            tableAction?.transformationRules?.headerPosition
        ),
        shouldCreateHeadersOnNoMatch: !!tableAction?.transformationRules?.shouldCreateHeadersOnNoMatch,
    };

    return ruleset;
};

// BE -> FE
export const getRulesetsFromTableActions = (tableActions: TableAction[]): Ruleset[] => {
    const rulesets: Ruleset[] = [];

    for (const tableAction of tableActions) {
        rulesets.push(getRulesetFromTableAction(tableAction));
    }

    return rulesets;
};

const getTableActionFromRuleset = (ruleset: Ruleset): TableAction => {
    const tableAction: TableAction = {
        rulesetName: ruleset?.name,
        searchRule: getTableSearchRulesFromRuleset(ruleset)!,
        transformationRules: getTableMappingRulesFromRuleset(ruleset),
    };

    return tableAction;
};

// FE -> BE
export const getTableActionsFromRulesets = (rulesets: Ruleset[]): TableAction[] => {
    const tableActions: TableAction[] = [];

    for (const ruleset of rulesets) {
        tableActions.push(getTableActionFromRuleset(ruleset));
    }

    return tableActions;
};
