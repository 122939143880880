import * as React from 'react';
import { Stack, FontIcon, Text, TextField, Icon } from '@fluentui/react';
import { Field, Label, FieldType, LabelType, FieldFromDoc } from 'pages/label/models/customModels';
import { encodeLabelString } from 'pages/label/utils/customModel';
import MessageModal from 'pages/label/components/messageModal/messageModal';
import { DeleteButton } from './deleteButton';
import MenuButton from './menuButton';
import { ApplicationState } from 'pages/label/store';
import { setColorForFieldsByName } from 'pages/label/store/customModel/customModel';
import { connect, ConnectedProps } from 'react-redux';
import $check from 'check-types';
import { IPrediction } from 'pages/label/store/predictions/predictions';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import './labelItem.scss';

export interface ILabelItemProps {
    field: FieldFromDoc;
    label?: Label;
    color: string;
    isReadonly: boolean;
    onSwitchSubType: (fieldKey, type) => void;
    onDeleteField: (fieldKey) => Promise<void>;
    onRenameField: (oldName, newName) => Promise<void>;
    onGetRenameErrorMessage: (value) => string | undefined;
    onClickTableField: (field) => void;
    onDeleteLabel: (fieldKey) => void;
    onClickField: (labelName: string) => void;
    onItemMouseEnter: (labelName: string) => void;
    onItemMouseLeave: () => void;
    dragHandleProps?: any;
    predictions: { [id: string]: IPrediction };
}

export interface ILabelItemState {
    isConfirmModalOpen: boolean;
    isConfirmModalLoading: boolean;
    isRenaming: boolean;
    isTableOpen: boolean;
    confirmOperation?: 'delete' | 'rename';
    newFieldName?: string;
}

export class LabelItem extends React.Component<ILabelItemProps & ConnectedProps<typeof connector>, ILabelItemState> {
    private textFieldRef: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.state = {
            isConfirmModalOpen: false,
            isConfirmModalLoading: false,
            isRenaming: false,
            isTableOpen: false,
        };
        this.textFieldRef = React.createRef();
    }

    private onKeyDown = (event) => {
        const { confirmOperation } = this.state;
        const onConfirmModalClick = confirmOperation === 'delete' ? this.handleDeleteField : this.handleRenameField;

        if (event.key === 'Enter') {
            onConfirmModalClick();
        }
    };

    private isEnteringRename: boolean = false;

    private handleDeleteLabel = (event: Event) => {
        event.stopPropagation();
        const { field, onDeleteLabel } = this.props;
        onDeleteLabel(field.fieldKey);
    };

    private handleSwitchSubType = (type: FieldType) => {
        const { field, onSwitchSubType } = this.props;
        onSwitchSubType(field.fieldKey, type);
    };

    private handleDeleteMenuClick = () => {
        this.setState({ isConfirmModalOpen: true, confirmOperation: 'delete' });
        document.addEventListener('keydown', this.onKeyDown, true);
    };

    private handleRenameMenuClick = () => {
        this.setState({ isRenaming: true });
        //TODO: temporarily using setTimeout for TextField losing focus issue. This should be refactored with a extracted TextField component to handle focus in componentDidMount
        setTimeout(() => {
            this.textFieldRef.current.focus();
        }, 0);
    };

    private handleDeleteField = async () => {
        const { field, onDeleteField } = this.props;
        this.setState({ isConfirmModalLoading: true });
        await onDeleteField(field.fieldKey);
        this.setState({
            isConfirmModalLoading: false,
            isConfirmModalOpen: false,
            confirmOperation: undefined,
        });
        document.removeEventListener('keydown', this.onKeyDown, true);
    };

    private handleRenameField = async () => {
        const { field, onRenameField, setColorForFieldsByName } = this.props;
        const { newFieldName } = this.state;
        this.setState({ isConfirmModalLoading: true });
        setColorForFieldsByName({
            fieldName: field.fieldKey,
            newFieldName: newFieldName,
        });
        await onRenameField(field.fieldKey, newFieldName);
        this.setState({
            isConfirmModalLoading: false,
            isConfirmModalOpen: false,
            isRenaming: false,
            confirmOperation: undefined,
            newFieldName: undefined,
        });
        document.removeEventListener('keydown', this.onKeyDown, true);
    };

    private handleRenameFieldBlur = (event) => {
        if (this.isEnteringRename) {
            this.isEnteringRename = false;
            return;
        }
        this.setState({ isRenaming: false });
    };

    private handleRenameFieldKeyDown = (event) => {
        event.stopPropagation();
        const { field } = this.props;
        const hasError = this.handleRenameErrorMessage(event.target.value) !== undefined;
        const isSameName = event.target.value === field.fieldKey;
        const isEmpty = !event.target.value;

        if (event.key === 'Enter' && (isSameName || isEmpty)) {
            this.setState({ isRenaming: false });
            return;
        }

        if (event.key === 'Escape') {
            this.setState({ isRenaming: false });
            return;
        }

        if (event.key === 'Enter' && !hasError) {
            this.isEnteringRename = true;
            this.handleRenameFieldEnter(event.target.value);
        }
    };

    private handleRenameFieldEnter = (value: string) => {
        this.setState({ isConfirmModalOpen: true, confirmOperation: 'rename', newFieldName: value });
        document.addEventListener('keydown', this.onKeyDown, true);
    };

    private handleRenameErrorMessage = (value: string) => {
        const { field, onGetRenameErrorMessage } = this.props;
        if (value === field.fieldKey) {
            // Nothing changed.
            return undefined;
        }
        return onGetRenameErrorMessage(value);
    };

    private handleConfirmModalClose = () => {
        document.removeEventListener('keydown', this.onKeyDown, true);
        this.setState({ isConfirmModalOpen: false });
    };

    private isTableInAnalyzeMode = () => {
        return (
            this.props.isReadonly &&
            !this.props.field.value &&
            this.props.field.fieldKey &&
            (this.props.field.fieldType === FieldType.Array || this.props.field.fieldType === FieldType.Object)
        );
    };

    private handleTableFieldClick = () => {
        const { field, onClickTableField } = this.props;

        if (!this.props.isReadonly) {
            onClickTableField(field);

            return;
        }

        if (this.isTableInAnalyzeMode()) {
            this.setState({
                isTableOpen: !this.state.isTableOpen,
            });
        }
    };

    private handleFieldClick = () => {
        const { field, onClickField } = this.props;
        onClickField(encodeLabelString(field.fieldKey));
    };

    private handleMouseEnter = () => {
        const { label, onItemMouseEnter } = this.props;

        if (label && label.value && label.value.length > 0) {
            onItemMouseEnter(label.label || '');
        }
    };

    private handleMouseLeave = () => {
        this.props.onItemMouseLeave();
    };

    private noOperation = (evt) => {
        // prevent event bubbling to trigger handleTableFieldClick
        evt.stopPropagation();
    };

    private formatConfidence = (confidenceText) => {
        return `${Number(confidenceText * 100).toFixed(2)}%`;
    };

    private formatTableConfidence = (confidenceText, contentText) => {
        const number = Number(confidenceText);

        if ($check.number(number) && number === 0 && $check.string(contentText) && contentText.length) {
            return 'Unknown %';
        }

        return this.formatConfidence(confidenceText);
    };

    private renderItemTitle = () => {
        const { field, color } = this.props;
        const { newFieldName, isConfirmModalOpen, isConfirmModalLoading, isRenaming, confirmOperation } = this.state;
        const hasSubType =
            field.fieldType !== FieldType.SelectionMark &&
            field.fieldType !== FieldType.Signature &&
            field.fieldType !== FieldType.Array &&
            field.fieldType !== FieldType.Object;
        const confirmModalTitle = confirmOperation === 'delete' ? 'Delete Field' : 'Rename Field';
        const confirmModalMessage =
            confirmOperation === 'delete' ? (
                <Text>
                    Are you sure you want to delete <b>{field.fieldKey}</b>? All labels and regions assigned to this
                    field will be deleted.
                </Text>
            ) : (
                <Text>
                    Are you sure you want to rename <b>{field.fieldKey}</b> to <b>{newFieldName}</b>? All labels and
                    regions assigned to this field will be changed thoroughly
                </Text>
            );
        const onConfirmModalClick = confirmOperation === 'delete' ? this.handleDeleteField : this.handleRenameField;
        const { dragHandleProps } = this.props;

        return (
            <div
                className={`${
                    this.isTableInAnalyzeMode()
                        ? 'label-item-entry-table'
                        : this.props.isReadonly
                        ? 'label-item-entry-with-confidence'
                        : 'label-item-entry'
                } no-select`}
            >
                <div {...dragHandleProps} className={`${this.props.isReadonly ? 'hide-drag-icon' : ''}`}>
                    <Icon iconName="GlobalNavButton" />
                </div>
                <FontIcon iconName="CircleFill" style={{ color, fontSize: 16 }} />
                {isRenaming ? (
                    <TextField
                        id="rename-textfield"
                        defaultValue={field.fieldKey}
                        onClick={this.noOperation} // subscribe click event to prevent triggering handleTableFieldClick
                        onKeyDown={this.handleRenameFieldKeyDown}
                        onBlur={this.handleRenameFieldBlur}
                        onGetErrorMessage={this.handleRenameErrorMessage}
                        styles={{ fieldGroup: { height: 24 } }}
                        componentRef={this.textFieldRef}
                        autoComplete="off"
                    />
                ) : (
                    <Text variant="medium">{field.fieldKey}</Text>
                )}

                {/* @ts-ignore*/}
                {field.confidence && (
                    // @ts-ignore
                    <Text variant={'medium'}>{this.formatConfidence(field.confidence)}</Text>
                )}

                {this.isTableInAnalyzeMode() && !this.state.isTableOpen && <DownOutlined style={{ fontSize: 12 }} />}

                {this.isTableInAnalyzeMode() && this.state.isTableOpen && <UpOutlined style={{ fontSize: 12 }} />}

                {!this.props.isReadonly && (
                    <MenuButton
                        hasSubType={hasSubType}
                        subType={(field as Field).fieldType}
                        onSwitchSubType={this.handleSwitchSubType}
                        onDeleteField={this.handleDeleteMenuClick}
                        onRenameField={this.handleRenameMenuClick}
                    />
                )}
                <MessageModal
                    isOpen={isConfirmModalOpen}
                    isLoading={isConfirmModalLoading}
                    title={confirmModalTitle}
                    body={<Text variant="medium">{confirmModalMessage}</Text>}
                    actionButtonText="Yes"
                    rejectButtonText="No"
                    onActionButtonClick={onConfirmModalClick}
                    onClose={this.handleConfirmModalClose}
                    onDismiss={this.handleConfirmModalClose}
                />
            </div>
        );
    };

    private renderItemValue = () => {
        const { field, label } = this.props;
        const isTable = field.fieldType === FieldType.Array || field.fieldType === FieldType.Object;
        if (!label) {
            return;
        }

        let itemValue;
        const text = label?.value.map((v) => v.text).join(' ');
        if (isTable) {
            itemValue = <FontIcon className="label-item-icon" iconName="Table" />;
        } else if (field.fieldType === FieldType.Signature) {
            itemValue = <FontIcon className="label-item-icon" iconName="WhiteBoardApp16" />;
        } else if (field.fieldType === FieldType.SelectionMark) {
            itemValue = (
                <Stack horizontal tokens={{ childrenGap: 6 }} verticalAlign="center">
                    <FontIcon className="label-item-icon" iconName="CheckboxComposite" />
                    {text && <Text className="label-item-text">{text}</Text>}
                </Stack>
            );
        } else if (label.labelType === LabelType.Region) {
            itemValue = <FontIcon className="label-item-icon" iconName="SingleColumnEdit" />;
        } else {
            itemValue = text && <Text className="label-item-text">{text}</Text>;
        }

        return (
            <div className="label-item-result label-item-value">
                <div></div>
                {itemValue}
                <DeleteButton onClick={this.handleDeleteLabel} />
            </div>
        );
    };

    private renderTable = () => {
        const { field, predictions } = this.props;

        const predictionsArr = Object.values(predictions || {});
        const lastPredictions = predictionsArr?.[predictionsArr.length - 1];

        const { confidenceTableFields: tableFields } = lastPredictions || {};
        const { fieldKey: tableName } = field;

        const table = tableFields?.[tableName];

        if (!table) {
            return null;
        }

        return (
            <div className="table-container" onClick={this.noOperation}>
                {(table?.rows || []).map((row, rowIndex) => (
                    <div key={`table-row-${rowIndex}`} className="table-row">
                        <span className="table-row-heading">
                            {typeof row?.name === 'string' ? `Row - ${row?.name}` : `Row ${rowIndex + 1} Content`}
                        </span>
                        {(row?.words || []).map((word, wordIndex) => (
                            <div key={`table-row-${rowIndex}-word-${wordIndex}`} className="table-row-word">
                                <div className="table-row-word-left">
                                    <div className="table-row-word-column-name">{word?.columnName}</div>
                                    <div className="table-row-word-content">{word?.content || 'No value'}</div>
                                </div>
                                <div className="table-row-word-right">
                                    <span style={{ marginLeft: 16 }}>
                                        {word?.content
                                            ? this.formatTableConfidence(word?.confidence, word?.content)
                                            : null}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    public render() {
        const { field } = this.props;
        // TODO: handle general field click to assign label.
        const onClickItem =
            field.fieldType === FieldType.Array || field.fieldType === FieldType.Object
                ? this.handleTableFieldClick
                : this.handleFieldClick;

        return (
            <Stack
                className="label-item"
                onClick={onClickItem}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                {this.renderItemTitle()}
                {!this.props.isReadonly && this.renderItemValue()}
                {this.props.field.value && (
                    <Text style={{ fontSize: 14, marginTop: 10 }}>{renderValue(this.props.field.value)}</Text>
                )}
                {this.isTableInAnalyzeMode() && this.state.isTableOpen && this.renderTable()}
            </Stack>
        );
    }
}

function renderValue(value: unknown) {
    if ($check.object(value)) {
        return (
            <ul>
                {Object.entries(value as object).map(([key, subValue]) => (
                    <li key={key}>
                        <b>{key}</b> :{renderValue(subValue)}
                    </li>
                ))}
            </ul>
        );
    }
    if (Array.isArray(value)) {
        return (
            <ul>
                {value.map((v, index) => (
                    <li key={index} className="label-item-array">
                        {renderValue(v)}
                    </li>
                ))}
            </ul>
        );
    }
    return value;
}

const mapState = (state: ApplicationState) => ({});
const mapDispatch = {
    setColorForFieldsByName,
};

const connector = connect(mapState, mapDispatch);

export default connector(LabelItem);
