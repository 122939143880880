import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { httpService } from 'core/services/httpService';
import { Loader } from 'components/common/loader';
import { Sidebar } from './sidebar';
import { LOGIN_PAGE } from '../../data/consts/routes';

import styles from './layout.module.scss';

export function AppLayout() {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    httpService.setAccessTokenGenerator(getAccessTokenSilently);

    if (isLoading) {
        return <Loader />;
    }

    if (!isLoading && !isAuthenticated) {
        return <Navigate replace to={LOGIN_PAGE} state={{ returnTo: window.location.pathname }} />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.sidebar}>
                <Sidebar />
            </div>
            <div className={styles.right}>
                <div className={styles.content}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
