import axios, { AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import { getApiUrl } from 'helpers';

export interface IHttpResponse<T> extends AxiosResponse<T> {}

export interface IHttpRequestConfig extends AxiosRequestConfig {}

export interface IHttpService {
    get: <T = any, R = IHttpResponse<T>>(url: string, config?: IHttpRequestConfig) => Promise<R>;
    delete: <T = any, R = IHttpResponse<T>>(url: string, config?: IHttpRequestConfig) => Promise<R>;
    post: <T = any, R = IHttpResponse<T>>(url: string, data?: any, config?: IHttpRequestConfig) => Promise<R>;
    put: <T = any, R = IHttpResponse<T>>(url: string, data?: any, config?: IHttpRequestConfig) => Promise<R>;
    patch: <T = any, R = IHttpResponse<T>>(url: string, data?: any, config?: IHttpRequestConfig) => Promise<R>;
    setAccessTokenGenerator: (cb: () => Promise<string>) => void;
    getAccessToken: () => Promise<string>;
}

const HttpService = (axiosStatic: AxiosStatic): IHttpService => {
    const client = axiosStatic.create({
        baseURL: `${getApiUrl()}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    let accessTokenGenerator = () => '';

    const setAccessTokenGenerator = (generator) => {
        if (typeof generator !== 'function') {
            return;
        }

        accessTokenGenerator = generator;
    };

    const getAccessToken = async () => {
        return await accessTokenGenerator();
    };

    client.interceptors.request.use(
        async (config) => {
            const accessToken = await accessTokenGenerator();

            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken}`,
                },
            };
        },
        (error) => Promise.reject(error)
    );

    const service: IHttpService = {
        get: client.get,
        post: client.post,
        put: client.put,
        delete: client.delete,
        patch: client.patch,
        setAccessTokenGenerator: setAccessTokenGenerator,
        getAccessToken: getAccessToken,
    };

    return service;
};

export const httpService = HttpService(axios);
