import * as React from 'react';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { MainRouter } from './components/router';
import { MainProvider } from './components/providers/MainProvider';
import { Auth0ProviderWithNavigate } from './components/router/Auth0ProviderWithNavigate';

import './custom.css';
import 'assets/styles/variable.scss';
import 'assets/styles/ant-override.scss';
import 'assets/styles/globall.scss';

const queryClient = new QueryClient();
const theme = {
    components: {
        Typography: {
            colorPrimary: 'rgba(0, 0, 0, 0.85)',
        },
        Layout: {
            colorBgHeader: '#001F42',
        },
        Menu: {
            colorPrimary: '#001F42',
        },
        Button: {
            colorPrimary: '#0085E5',
        },
    },
    token: {
        // colorBgBase: '#FFFFFF',
        // colorPrimary: '#0085E5',
    },
};

export default function App() {
    return (
        <MainProvider>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <ConfigProvider theme={theme}>
                        <QueryClientProvider client={queryClient}>
                            <MainRouter />
                        </QueryClientProvider>
                    </ConfigProvider>
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </MainProvider>
    );
}
