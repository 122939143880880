// @ts-nocheck
import * as React from 'react';
import { Button, Spin, Pagination, Result } from 'antd';
import { LoadingOutlined, ReloadOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Stack, IStackTokens } from '@fluentui/react';
import { useContextValues } from 'components/providers/MainProvider';
import { Select } from '../components';
import { rulesetService } from 'data/services/rulesetService';
import { getTableSearchRulesFromRuleset, getTableMappingRulesFromRuleset } from 'helpers';
import { findTable, mapTables } from '@synatic/ocr-table-actions';
import { getFormIdFromLocation } from 'helpers';
import { formFilesService } from 'pages/label/services/formFilesService';
import { getFileFromBlob, file2Base64 } from 'helpers';

import { Ruleset } from 'types';
import { StudioDocumentTable } from 'pages/label/models/analyzeResult';
import { V3_0_3_AnalyzeResult } from 'pages/label/models/analyzeResult/v3_0_3';
import { DocumentTable } from '@azure/ai-form-recognizer';
import { IDocument } from 'pages/label/store/documents/documentsTypes';

import styles from './transformTables.module.scss';

type Props = {
    formId?: string;
    currentDocument: IDocument;
    onClose: () => void;
    currentFileName: string | undefined;
    notificationApi: any;
    onTransformAllTables: (outputTables: any[]) => void;
    onTransformTable: (outputTables: any[]) => void;
};

type State = {
    isLoading: boolean;
    isFindTableLoading: boolean;
    isMapTablesLoading: boolean;
    shouldRetryLoad: boolean;
    selectedRuleset: Ruleset | undefined;
    selectedRulesetIndex: number | undefined;
    rulesets: Ruleset[];
    foundTables: DocumentTable[] | undefined;
    foundTableErrors: string[] | undefined;
    foundTablePageNumber: number;
    analyzeResult: V3_0_3_AnalyzeResult | undefined;
};

class TransformTablesTab extends React.Component<Props, State> {
    state = {
        isLoading: true,
        isFindTableLoading: true,
        isMapTablesLoading: false,
        shouldRetryLoad: false,
        selectedRuleset: undefined,
        selectedRulesetIndex: undefined,
        rulesets: [],
        foundTables: undefined,
        foundTableErrors: [],
        foundTablePageNumber: 1,
    };

    private loadRulesets = (targetIndex?) => {
        const { notificationApi } = this.props;

        this.setState(
            {
                isLoading: true,
                shouldRetryLoad: false,
            },
            async () => {
                try {
                    const formId = getFormIdFromLocation();

                    // Removes the 'New' ruleset.
                    const rulesets = (await rulesetService.getRulesets(formId)).slice(1);
                    const selectedRuleset =
                        typeof targetIndex === 'number'
                            ? rulesets[targetIndex] && { ...rulesets[targetIndex] }
                            : rulesets[0];

                    this.setState(
                        {
                            isLoading: false,
                            selectedRuleset: selectedRuleset,
                            selectedRulesetIndex: typeof targetIndex === 'number' ? targetIndex : 0,
                            rulesets: rulesets,
                        },
                        this.findTables
                    );
                } catch (e) {
                    this.setState({
                        shouldRetryLoad: true,
                    });

                    notificationApi.error({
                        message: 'Failed to load rulesets',
                        description: e?.response?.data?.message,
                        placement: 'topRight',
                    });
                }
            }
        );
    };

    private getRulesetsDropdown = () => {
        return this.state.rulesets.map((ruleset) => ({
            value: ruleset.name,
            label: ruleset.name,
        }));
    };

    private buildTableBody = (table: StudioDocumentTable): JSX.Element[] | null => {
        if (!table) {
            return null;
        }

        const stackToken: IStackTokens = {
            childrenGap: 5,
        };
        const tableBody: JSX.Element[] = [];
        const { rowCount, cells } = table;

        for (let i = 0; i < rowCount; i++) {
            const tableRow = [];
            tableBody.push(<tr key={i}>{tableRow}</tr>);
        }

        cells.forEach((cell) => {
            const { rowIndex, columnIndex, rowSpan, columnSpan, kind, content } = cell;

            let cellContentWithSpan: JSX.Element[] | null = null;

            if (Array.isArray(content)) {
                cellContentWithSpan = content.map((cellContent, index) => {
                    return <span key={index}>{cellContent}</span>;
                });
            }

            tableBody[rowIndex]['props']['children'][columnIndex] =
                kind === 'columnHeader' ? (
                    <th key={columnIndex} colSpan={columnSpan} rowSpan={rowSpan}>
                        <Stack horizontal wrap tokens={stackToken}>
                            {Array.isArray(content) ? cellContentWithSpan : content}
                        </Stack>
                    </th>
                ) : (
                    <td key={columnIndex} colSpan={columnSpan} rowSpan={rowSpan}>
                        <Stack horizontal wrap tokens={stackToken}>
                            {Array.isArray(content) ? cellContentWithSpan : content}
                        </Stack>
                    </td>
                );
        });

        return tableBody;
    };

    private onRulesetClick = (selectedName) => {
        const selectedRuleset = this.state.rulesets.find((ruleset) => ruleset.name === selectedName);
        const selectedRulesetIndex = this.state.rulesets.findIndex((ruleset) => ruleset.name === selectedName);

        this.setState(
            {
                selectedRuleset: { ...selectedRuleset },
                selectedRulesetIndex: selectedRulesetIndex,
            },
            this.findTables
        );
    };

    private onTransformAll = () => {
        const { onTransformAllTables, notificationApi } = this.props;
        const { analyzeResult, foundTables, selectedRuleset } = this.state;

        if (!analyzeResult) {
            return;
        }

        this.setState(
            {
                isMapTablesLoading: true,
            },
            () => {
                let outputTables = [];

                try {
                    const tablesToTransform = [...(foundTables || [])];
                    const tableMappingRules = getTableMappingRulesFromRuleset(selectedRuleset!);

                    outputTables = tablesToTransform.map((table) =>
                        mapTables([table], tableMappingRules, analyzeResult)
                    );
                } catch (e) {
                    notificationApi.error({
                        message: 'Failed to map tables',
                        description: String(e),
                        placement: 'topRight',
                    });
                }

                this.setState(
                    {
                        isMapTablesLoading: false,
                    },
                    () => onTransformAllTables(outputTables)
                );
            }
        );
    };

    private onTransform = () => {
        const { onTransformTable, notificationApi } = this.props;
        const { analyzeResult, foundTables, foundTablePageNumber, selectedRuleset } = this.state;

        if (!analyzeResult) {
            return;
        }

        this.setState(
            {
                isMapTablesLoading: true,
            },
            () => {
                let outputTables = [];

                try {
                    const tablesToTransform = [(foundTables || [])[foundTablePageNumber - 1]];
                    const tableMappingRules = getTableMappingRulesFromRuleset(selectedRuleset!);

                    outputTables = [mapTables(tablesToTransform, tableMappingRules, analyzeResult)];
                } catch (e) {
                    notificationApi.error({
                        message: 'Failed to map tables',
                        description: String(e),
                        placement: 'topRight',
                    });
                }

                this.setState(
                    {
                        isMapTablesLoading: false,
                    },
                    () => onTransformTable(outputTables)
                );
            }
        );
    };

    private findTables = () => {
        const { analyzeResult, selectedRuleset } = this.state;
        const { notificationApi } = this.props;

        if (!analyzeResult || !selectedRuleset) {
            return;
        }

        this.setState(
            {
                isFindTableLoading: true,
            },
            () => {
                // Artificial delay to improve user feedback.
                setTimeout(() => {
                    try {
                        const tableSearchRules = getTableSearchRulesFromRuleset(selectedRuleset!);
                        const tableMatchResult = findTable(analyzeResult as any, tableSearchRules!);

                        const foundTables = tableMatchResult?.tables || [];
                        const foundTableErrors =
                            Array.isArray(tableMatchResult.errors) && tableMatchResult.errors.length
                                ? tableMatchResult.errors
                                : undefined;

                        if (foundTableErrors) {
                            throw foundTableErrors;
                        }

                        this.setState({
                            isFindTableLoading: false,
                            foundTables: foundTables,
                            foundTableErrors: foundTableErrors?.length ? foundTableErrors : undefined,
                            foundTablePageNumber: 1,
                        });
                    } catch (e) {
                        this.setState({
                            isFindTableLoading: false,
                        });

                        notificationApi.error({
                            message: 'Failed to find tables',
                            description: String(e),
                            placement: 'topRight',
                        });
                    }
                }, 300);
            }
        );
    };

    private onPaginationChange = (foundTablePageNumber) => {
        this.setState({
            foundTablePageNumber: foundTablePageNumber,
        });
    };

    private analyzeFile = async () => {
        const { notificationApi, formId, currentDocument } = this.props;

        try {
            const url = currentDocument.url;

            if (!url) {
                throw new Error('No downlaod url associated with the current document.');
            }

            const res = await formFilesService.getFile(url, {
                responseType: 'blob',
            });

            if (res.status < 200 || res.status > 299) {
                throw new Error('Failed to download file associated with the current document.');
            }

            const file = getFileFromBlob(res.data, 'file');
            const base64 = await file2Base64(file);

            const analyzeResult = await formFilesService.analyzeFormFile(formId, base64);

            this.setState({
                analyzeResult: analyzeResult,
            });

            return true;
        } catch (e) {
            notificationApi.error({
                message: 'Failed to analyze file',
                description: e?.response?.data?.message || e?.message || String(e),
                placement: 'topRight',
            });
        }
    };

    public async componentDidMount() {
        const didAnalyze = await this.analyzeFile();

        if (didAnalyze) {
            this.loadRulesets();
        }
    }

    public render = () => {
        const { onClose, currentFileName } = this.props;
        const {
            isLoading,
            isFindTableLoading,
            shouldRetryLoad,
            rulesets,
            selectedRuleset,
            foundTables,
            foundTableErrors,
            foundTablePageNumber,
            isMapTablesLoading,
        } = this.state;

        if (shouldRetryLoad) {
            return (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 32,
                            height: 256,
                        }}
                    >
                        <Result
                            title="Failed to load rulesets"
                            subTitle="Please click on `Reload` to try again."
                            status="error"
                            icon={<CloseCircleFilled style={{ fontSize: 32 }} />}
                            extra={[
                                <Button
                                    size={'middle'}
                                    danger={true}
                                    type={'primary'}
                                    key={'reload'}
                                    icon={<ReloadOutlined rev={'true'} />}
                                    onClick={() => this.loadRulesets()}
                                >
                                    Reload
                                </Button>,
                            ]}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className={styles['footer-button']} onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </>
            );
        }

        if (!rulesets?.length && !isLoading) {
            return (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 32,
                            height: 256,
                        }}
                    >
                        <span style={{ textAlign: 'center', maxWidth: 512, marginBottom: 16 }}>
                            There are currently no rulesets to choose from, please refresh or create a few first.
                        </span>
                        <Button onClick={() => this.loadRulesets(this.state.selectedRulesetIndex)}>Refresh</Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className={styles['footer-button']} onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </>
            );
        }

        if (isLoading) {
            return (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 32,
                            height: 256,
                        }}
                    >
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className={styles['footer-button']} onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </>
            );
        }

        return (
            <div className={styles['container']}>
                <div className={styles['row']}>
                    <div>
                        <span style={{ marginRight: 8 }}>Ruleset</span>
                        <Select
                            options={this.getRulesetsDropdown()}
                            onChange={this.onRulesetClick}
                            value={selectedRuleset?.name}
                        />
                        <Button onClick={() => this.loadRulesets(this.state.selectedRulesetIndex)}>Refresh</Button>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: 8 }}>Current file</span>
                        <span style={{ fontWeight: 500 }}>{currentFileName}</span>
                    </div>
                </div>

                {isFindTableLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 32,
                            height: 256,
                        }}
                    >
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} />
                    </div>
                ) : (
                    <>
                        {!foundTables?.length ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 32,
                                    height: 256,
                                }}
                            >
                                <span style={{ textAlign: 'center', maxWidth: 512 }}>
                                    {`Sorry, no tables were found with the selected ruleset's identification rules. Please
                                    try refining them or select another ruleset.`}
                                </span>
                            </div>
                        ) : (
                            <div className={styles['table-container']}>
                                <div className={styles['table-scrollable-container']}>
                                    <table className={styles['table-view']}>
                                        <tbody>{this.buildTableBody(foundTables[foundTablePageNumber - 1])}</tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </>
                )}

                <div className={styles['row']}>
                    <div>
                        {!!foundTables?.length && (
                            <Pagination
                                simple={true}
                                current={foundTablePageNumber}
                                total={foundTables?.length}
                                pageSize={1}
                                onChange={this.onPaginationChange}
                            />
                        )}
                    </div>
                    <div>
                        <Button className={styles['footer-button']} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            className={styles['footer-button']}
                            onClick={this.onTransformAll}
                            disabled={isFindTableLoading || !!foundTableErrors?.length || !foundTables?.length}
                            loading={isMapTablesLoading}
                        >
                            Transform all
                        </Button>
                        <Button
                            className={styles['footer-button']}
                            type={'primary'}
                            onClick={this.onTransform}
                            disabled={isFindTableLoading || !!foundTableErrors?.length || !foundTables?.length}
                            loading={isMapTablesLoading}
                        >
                            Transform
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
}

function withHooks(Component) {
    return function WrappedComponent(props) {
        const { notificationApi } = useContextValues();
        return <Component {...props} notificationApi={notificationApi} />;
    };
}

export default withHooks(TransformTablesTab);
