import * as React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined, CaretUpOutlined, CaretDownOutlined, SettingOutlined } from '@ant-design/icons';
import { RowCondition } from './rowCondition';
import { RowSteps } from './rowSteps';
import { Select } from './select';

import { RowRuleAction, RowRuleActionStep } from 'types';

import styles from './rowAction.module.scss';

type DropdownItem = {
    value: string;
    label: string;
};

type Props = {
    ruleIndex: number;
    actionIndex: number;
    description: RowRuleAction;
    onBooleanOperatorClick: (item: DropdownItem, ruleIndex: number, actionIndex: number) => void;
    onStepsChange: (steps: RowRuleActionStep[], ruleIndex: number, actionIndex: number) => void;
    onDelete: (ruleIndex: number, actionIndex: number) => void;
    onMoveUp: (ruleIndex: number, actionIndex: number) => void;
    onMoveDown: (ruleIndex: number, actionIndex: number) => void;
    onConditionOperandChange: (
        item: DropdownItem,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionOperandDetailChange: (
        text: string,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionOperatorChange: (
        item: DropdownItem,
        ruleIndex: number,
        actionIndex: number,
        conditionIndex: number
    ) => void;
    onConditionValueChange: (text: string, ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onConditionDelete: (ruleIndex: number, actionIndex: number, conditionIndex: number) => void;
    onConditionCreate: (ruleIndex: number, actionIndex: number) => void;
};

export class RowAction extends React.PureComponent<Props> {
    private getBooleanOperatorDropdown = () => {
        return [
            { value: 'All', label: 'All' },
            { value: 'Any', label: 'Any' },
        ];
    };

    public render = () => {
        const {
            ruleIndex,
            actionIndex,
            description,
            onBooleanOperatorClick,
            onStepsChange,
            onDelete,
            onMoveUp,
            onMoveDown,
            onConditionOperandChange,
            onConditionOperatorChange,
            onConditionOperandDetailChange,
            onConditionValueChange,
            onConditionDelete,
            onConditionCreate,
        } = this.props;

        const { booleanOperator, steps, conditions } = description;

        return (
            <div className={styles['container']}>
                <div className={styles['row']}>
                    <div className={styles['name-container']}>
                        <SettingOutlined className={styles['setting-icon']} />
                        <span>{`Action ${actionIndex + 1}`}</span>
                    </div>
                    <div className={styles['button-container']}>
                        <div className={styles['order-button-container']}>
                            <Button
                                className={styles['button']}
                                icon={<CaretUpOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onMoveUp(ruleIndex, actionIndex)}
                            />
                            <Button
                                className={styles['button']}
                                icon={<CaretDownOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onMoveDown(ruleIndex, actionIndex)}
                            />
                        </div>
                        <Button
                            className={styles['button']}
                            icon={<MinusOutlined style={{ fontSize: 10 }} />}
                            onClick={() => onDelete(ruleIndex, actionIndex)}
                        />
                    </div>
                </div>
                <div className={styles['row']}>
                    <RowSteps
                        ruleIndex={ruleIndex}
                        actionIndex={actionIndex}
                        description={steps}
                        onStepsChange={onStepsChange}
                    />
                </div>
                <div className={styles['row']}>
                    {conditions.length ? (
                        <>
                            <div>
                                <span className={styles['boolean-operator-text-right']}>if</span>
                                <Select
                                    className={styles['select']}
                                    options={this.getBooleanOperatorDropdown()}
                                    onChange={(value) => {
                                        const item = this.getBooleanOperatorDropdown().find(
                                            (option) => option.value === value
                                        );

                                        onBooleanOperatorClick(item!, ruleIndex, actionIndex);
                                    }}
                                    value={booleanOperator}
                                />
                                <span className={styles['boolean-operator-text-left']}>
                                    of the following conditions are met
                                </span>
                            </div>
                            <Button
                                className={styles['button']}
                                icon={<PlusOutlined style={{ fontSize: 10 }} />}
                                onClick={() => onConditionCreate(ruleIndex, actionIndex)}
                            />
                        </>
                    ) : (
                        <>
                            <Button type={'default'} onClick={() => onConditionCreate(ruleIndex, actionIndex)}>
                                Add condition(s)
                            </Button>
                        </>
                    )}
                </div>
                {conditions.map((condition, index) => (
                    <RowCondition
                        key={`row-rule-${ruleIndex}-action-${actionIndex}-condition-${index}`}
                        ruleIndex={ruleIndex}
                        actionIndex={actionIndex}
                        conditionIndex={index}
                        description={condition}
                        onOperandChange={onConditionOperandChange}
                        onOperatorChange={onConditionOperatorChange}
                        onOperandDetailChange={onConditionOperandDetailChange}
                        onValueChange={onConditionValueChange}
                        onDelete={onConditionDelete}
                    />
                ))}
            </div>
        );
    };
}
