// Main pages
export const HOME_PAGE = '/';
export const LABEL_PAGE = '/label/:pageType/:formId/:formTypeId/:formTypeVersionId/:formTypeVersionPageId';
export const LABEL_DOCUMENT = '/label/:pageType/:formId';
export const ANALYZE_PAGE = '/analyze';
export const WORKSPACE_PAGE = '/workspace';
export const ADMIN_PAGE = '/admin';
export const LOGIN_PAGE = '/login';
export const CALLBACK_PAGE = '/callback';

// Logs pages
export const REBUILD_LOGS = '/:formId/rebuild-logs';
export const TRAINING_LOGS =
    '/:formId/types/:formTypeId/versions/:formTypeVersionId/pages/:formTypeVersionPageId/rebuild-logs';

// Form Crud pages
export const DOCUMENT_CREATE = '/document/create';
export const DOCUMENT_EDIT = '/document/:formId/edit';
export const FORM_CREATE = '/form/create';
export const FORM_EDIT_ID = '/form/:formId/edit';
export const FORM_TYPE_CREATE = '/form/:formId/types/create';
export const FORM_TYPE_EDIT = '/form/:formId/types/:formTypeId/edit';
export const FORM_TYPE_VERSION_CREATE = '/form/:formId/types/:formTypeId/versions/create';
export const FORM_TYPE_VERSION_EDIT = '/form/:formId/types/:formTypeId/versions/:formTypeVersionId/edit';
export const FORM_TYPE_VERSION_PAGE_CREATE = '/form/:formId/types/:formTypeId/versions/:formTypeVersionId/pages/create';
export const FORM_TYPE_VERSION_PAGE_EDIT =
    '/form/:formId/types/:formTypeId/versions/:formTypeVersionId/pages/:formTypeVersionPageId/edit';

// Model settings pages
export const FORM_MODEL_SETTINGS = '/form/:formId/model-settings';
export const FORM_TYPE_VERSION_PAGE_MODEL_SETTINGS =
    '/form/:formId/types/:formTypeId/versions/:formTypeVersionId/pages/:formTypeVersionPageId/model-settings';
export const DOCUMENT_MODEL_SETTINGS = '/document/:formId/model-settings';
