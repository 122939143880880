import { httpService, IHttpService } from '../../../core/services/httpService';

export interface IFormService {
    getList: (formId: string) => Promise<any>;
}

export const LogsService = (httpService: IHttpService): IFormService => {
    const baseUrl = '/forms';

    return {
        getList: async (formId: string) => {
            const res = await httpService.get(`${baseUrl}/${formId}/rebuild-logs?$sort=-_id`);
            return res?.data.map((item) => ({
                ...item,
                rebuildStartDate: new Date(item?.rebuildStartDate || Date.now()).toLocaleString(),
                rebuildCompletedDate: new Date(item?.rebuildCompletedDate || Date.now()).toLocaleString(),
            }));
        },
    };
};

export const logsService = LogsService(httpService);
