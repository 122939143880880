import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Input, Result, Skeleton, Typography } from 'antd';
import { ReloadOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formService } from '../data/formService';
import { IFormValues } from '../data/model';
import { useContextValues } from '../../../components/providers/MainProvider';
import { FORM_TREE_VIEW, FORM_TYPE_VERSION_PAGE_EDIT } from '../../../data/consts/apiQuery';
import { AsyncRender } from '../../../components/common/async-render';
import CodeEditor from '@uiw/react-textarea-code-editor';

import styles from '../form-crud.module.scss';

export function FormTypeVersionPageEdit() {
    const queryClient = useQueryClient();
    const { notificationApi } = useContextValues();
    const [form] = Form.useForm();
    const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = useParams();

    const queryData = useQuery({
        queryKey: [FORM_TYPE_VERSION_PAGE_EDIT, formTypeVersionPageId],
        queryFn: () =>
            formService.getFormTypeVersionPage(
                formId as string,
                formTypeId as string,
                formTypeVersionId as string,
                formTypeVersionPageId as string
            ),
        retry: 0,
        onSuccess: (res) => {
            form.setFieldsValue({
                title: res.title,
                description: res.description,
                postScript: res.postScript,
                modelTrainingType: res.modelTrainingType,
            });
        },
    });

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
        mutationFn: (values: IFormValues) => {
            return formService.updateFormTypeVersionPage(
                values,
                formId as string,
                formTypeId as string,
                formTypeVersionId as string,
                formTypeVersionPageId as string
            );
        },
        onSuccess: () => {
            notificationApi.success({
                message: 'Edited page',
                description: 'The page has been edited.',
                placement: 'topRight',
            });

            queryClient.invalidateQueries({ queryKey: [FORM_TREE_VIEW] });
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Failed to edit page',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    const handleFormSubmit = (values) => {
        mutateUpdate(values);
    };

    return (
        <AsyncRender
            queryData={queryData}
            RenderSuccess={() => (
                <>
                    <Typography.Paragraph className={styles.card__title}>Edit page</Typography.Paragraph>

                    <Form form={form} layout="vertical" size="large" onFinish={handleFormSubmit}>
                        <Form.Item
                            label="Title"
                            name="title"
                            required
                            rules={[{ required: true, message: 'Title cannot be empty' }]}
                        >
                            <Input style={{ width: '50%' }} placeholder="Please enter a title" size="middle" />
                        </Form.Item>

                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={4} placeholder="Please enter a description" size="middle" />
                        </Form.Item>

                        <Form.Item label="Post script" name="postScript">
                            <CodeEditor
                                className={styles.form__script_field}
                                language="js"
                                placeholder="Please enter JS code."
                                padding={10}
                                minHeight={150}
                            />
                        </Form.Item>

                        <Form.Item label="Last model training type" name="modelTrainingType">
                            <Input style={{ width: '50%' }} placeholder="None" size="middle" disabled={true} />
                        </Form.Item>

                        <Button size={'middle'} loading={isLoadingUpdate} type="primary" htmlType="submit">
                            {isLoadingUpdate ? 'Updating' : 'Update'}
                        </Button>
                    </Form>
                </>
            )}
            renderLoading={
                <>
                    <Skeleton active />
                    <Skeleton active className="mt-8" />
                    <Skeleton active className="mt-8" />
                </>
            }
            renderError={
                <Result
                    title="Failed to load form"
                    subTitle="Please click on `Reload` to try again."
                    status="error"
                    icon={<CloseCircleFilled style={{ fontSize: 32 }} />}
                    extra={[
                        <Button
                            size={'middle'}
                            danger
                            type="primary"
                            key="reload"
                            icon={<ReloadOutlined rev="true" />}
                            onClick={() => {
                                queryClient.invalidateQueries({
                                    queryKey: [FORM_TYPE_VERSION_PAGE_EDIT, formTypeVersionPageId],
                                });
                            }}
                        >
                            Reload
                        </Button>,
                    ]}
                />
            }
        />
    );
}
