import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Text } from '@fluentui/react';
import { DocumentPreviewList } from './components/documentPreviewList';
import { setCurrentDocument, deleteDocument, clearCurrentDocument } from 'pages/label/store/documents/documents';
import { IDocument, DocumentStatus } from 'pages/label/store/documents/documentsTypes';
import { ApplicationState } from 'pages/label/store';
import MessageModal from 'pages/label/components/messageModal/messageModal';
import { withRouter } from '../../components/withRouter/withRouter';
import { RouterProps } from '../../models';
import { formFilesService } from '../../services/formFilesService';

import './documentGallery.scss';

interface IDocumentGalleryProps {
    hideAddButton?: boolean;
    isReadonly: boolean;
    onDocumentDeleted?: (document: IDocument) => void;
    shouldConfirmDeleteDocument?: boolean;
    onError: (err) => void;
}

type DocumentGalleryProps = ConnectedProps<typeof connector> & IDocumentGalleryProps & RouterProps;

interface IDocumentGalleryState {
    displayedDocuments: IDocument[];
    isDeleteModelOpen: boolean;
    documentToDeleted?: {
        id: string;
        name: string;
        index: number;
    };
}

export class DocumentGallery extends React.PureComponent<DocumentGalleryProps, IDocumentGalleryState> {
    constructor(props) {
        super(props);
        this.state = {
            displayedDocuments: props.documents,
            isDeleteModelOpen: false,
            documentToDeleted: undefined,
        };
    }

    public componentDidUpdate(prevProps) {
        const { documents, currentDocument, setCurrentDocument } = this.props;
        if (prevProps.documents !== documents) {
            this.setState({ displayedDocuments: documents });
        }
        if (
            documents &&
            documents[0] &&
            documents[0].states.loadingStatus === DocumentStatus.Loaded &&
            !currentDocument
        ) {
            setCurrentDocument(documents[0]);
        }
    }

    private handleDocumentClick = (index: number, id?: string) => {
        const { documents, setCurrentDocument } = this.props;
        const selectedDoc = documents.find((doc) => doc.id === id);
        if (selectedDoc) {
            setCurrentDocument(selectedDoc);
        }
    };

    private setCurrentDocumentToNextOrPreviousDocument = (docIndexToDelete: number) => {
        const { documents, setCurrentDocument, clearCurrentDocument } = this.props;
        let nextDocument: IDocument;
        if (documents.length === 1) {
            clearCurrentDocument();
            return;
        }
        if (docIndexToDelete + 1 === documents.length) {
            nextDocument = documents[docIndexToDelete - 1];
        } else {
            nextDocument = documents[docIndexToDelete + 1];
        }
        setCurrentDocument(nextDocument);
    };

    private handleDocumentDelete = async (docNameToDelete: string, id, index: number) => {
        const { documents, deleteDocument, currentDocument } = this.props;
        const docToDelete = documents.find((doc) => doc.id === id);

        if (docToDelete) {
            const { formId, formTypeId, formTypeVersionId, formTypeVersionPageId } = this.props.router.params;

            if (this.props.router.params.pageType === 'document') {
                await formFilesService
                    .deleteDocumentFile(formId as string, docToDelete.id as string)
                    .then((res) => {
                        if (res?.status === 204) {
                            deleteDocument(docToDelete);
                            if (currentDocument?.id === id) {
                                this.setCurrentDocumentToNextOrPreviousDocument(index);
                            }
                        }
                    })
                    .catch((err) => this.props.onError(err));
            } else {
                await formFilesService
                    .deletePageFormFile(
                        formId as string,
                        formTypeId as string,
                        formTypeVersionId as string,
                        formTypeVersionPageId as string,
                        docToDelete.id as string
                    )
                    .then((res) => {
                        if (res?.status === 204) {
                            deleteDocument(docToDelete);
                            if (currentDocument?.id === id) {
                                this.setCurrentDocumentToNextOrPreviousDocument(index);
                            }
                        }
                    })
                    .catch((err) => this.props.onError(err));
            }
        }
    };

    private handleConfirmDocumentDeletion = () => {
        const { name, index, id } = this.state.documentToDeleted!;
        this.handleDocumentDelete(name, id, index);
        this.setState({
            documentToDeleted: undefined,
            isDeleteModelOpen: false,
        });
    };

    private handleDocumentDeleteClicked = (name: string, id: string, index: number) => {
        this.setState({
            documentToDeleted: {
                id,
                name,
                index,
            },
            isDeleteModelOpen: true,
        });
    };

    public render() {
        const { currentDocument, shouldConfirmDeleteDocument } = this.props;
        const { displayedDocuments, isDeleteModelOpen, documentToDeleted } = this.state;
        return (
            <div className="document-gallery-container">
                <div className="document-gallery-list">
                    <DocumentPreviewList
                        isReadonly={this.props.isReadonly}
                        currentDocument={currentDocument}
                        documents={displayedDocuments}
                        onDocumentClick={this.handleDocumentClick}
                        onDocumentDelete={
                            shouldConfirmDeleteDocument ? this.handleDocumentDeleteClicked : this.handleDocumentDelete
                        }
                    />
                </div>
                {isDeleteModelOpen && (
                    <MessageModal
                        isOpen={isDeleteModelOpen}
                        title="Delete Document"
                        body={<Text variant="medium">Are you sure to delete {documentToDeleted!.name} ?</Text>}
                        actionButtonText="Delete"
                        onActionButtonClick={this.handleConfirmDocumentDeletion}
                        onClose={() => this.setState({ isDeleteModelOpen: false })}
                    />
                )}
            </div>
        );
    }
}

const mapState = (state: ApplicationState) => ({
    documents: state.documents.documents,
    currentDocument: state.documents.currentDocument,
});
const mapDispatch = {
    setCurrentDocument,
    deleteDocument,
    clearCurrentDocument,
};

const connector = connect(mapState, mapDispatch);

export default withRouter(connector(DocumentGallery));
