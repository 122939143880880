import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Button } from 'antd';
import { HOME_PAGE } from '../../data/consts/routes';
import { Logo } from './components/logo';
import { Wall } from './components/wall';

import styles from './login.module.scss';

export default function Login() {
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: location.state?.returnTo || HOME_PAGE,
            },
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.top}>
                    <Logo className={styles.logo} />
                </div>
                <div className={styles.bottom}>
                    <Typography className={styles.heading}>OCR</Typography>
                    <Typography className={styles.terms}>
                        By signing in to Synatic you agree to the
                        <div className={styles.link}>
                            <a href={'https://www.synatic.com/legal/terms-of-service'}>Terms of Service</a>
                        </div>
                    </Typography>
                    <Button
                        type={'primary'}
                        size={'large'}
                        className={styles.button}
                        block={true}
                        onClick={handleLogin}
                    >
                        Sign in
                    </Button>
                </div>
            </div>
            <div className={styles.overlay}>
                <Wall />
            </div>
        </div>
    );
}
