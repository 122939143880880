import { httpService, IHttpService } from '../../core/services/httpService';
import { ModelsInUse, ModelEnvironment } from 'types';

type IModelsService = {
    getForm: (formId: string) => Promise<any>;
    getPage: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string
    ) => Promise<any>;
    updateFormModelsInUse: (formId: string, modelsInUse: ModelsInUse) => Promise<any>;
    updateFormModelsRebuildEnvironment: (formId: string, modelsRebuildEnvironment: ModelEnvironment) => Promise<any>;
    updatePageModelsInUse: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsInUse: ModelsInUse
    ) => Promise<any>;
    updatePageModelsRebuildEnvironment: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsRebuildEnvironment: ModelEnvironment
    ) => Promise<any>;
};

type IModelsRepository = {
    getForm: (formId: string) => Promise<any>;
    getPage: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string
    ) => Promise<any>;
    updateFormModelsInUse: (formId: string, modelsInUse: ModelsInUse) => Promise<any>;
    updateFormModelsRebuildEnvironment: (formId: string, modelsRebuildEnvironment: ModelEnvironment) => Promise<any>;
    updatePageModelsInUse: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsInUse: ModelsInUse
    ) => Promise<any>;
    updatePageModelsRebuildEnvironment: (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsRebuildEnvironment: ModelEnvironment
    ) => Promise<any>;
};

class HttpModelsRepository implements IModelsRepository {
    private httpService: IHttpService;

    constructor(httpService: IHttpService) {
        this.httpService = httpService;
    }

    getForm = async (formId: string) => {
        const res = await this.httpService.get(`forms/${formId}`);

        const form = res?.data || {};

        form.models = form?.models || [];
        form.modelsInUse = form?.modelsInUse || {
            development: 'none',
            staging: 'none',
            production: 'none',
        };
        form.modelsRebuildEnvironment = form?.modelsRebuildEnvironment || 'development';
        form.modelsChangeLog = form?.modelsChangeLog || [];

        return form;
    };

    getPage = async (formId: string, formTypeId: string, formTypeVersionId: string, formTypeVersionPageId: string) => {
        const res = await this.httpService.get(
            `forms/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}`
        );

        const page = res?.data || {};

        page.models = page?.models || [];
        page.modelsInUse = page?.modelsInUse || {
            development: 'none',
            staging: 'none',
            production: 'none',
        };
        page.modelsChangeLog = page?.modelsChangeLog || [];

        return page;
    };

    updateFormModelsInUse = async (formId: string, modelsInUse: ModelsInUse) => {
        return this.httpService.put(`forms/${formId}/models-in-use`, {
            modelsInUse: modelsInUse,
        });
    };

    updateFormModelsRebuildEnvironment = async (formId: string, modelsRebuildEnvironment: ModelEnvironment) => {
        return this.httpService.put(`forms/${formId}/models-rebuild-environment`, {
            modelsRebuildEnvironment: modelsRebuildEnvironment,
        });
    };

    updatePageModelsInUse = async (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsInUse: ModelsInUse
    ) => {
        return this.httpService.put(
            `forms/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/models-in-use`,
            {
                modelsInUse: modelsInUse,
            }
        );
    };

    updatePageModelsRebuildEnvironment = async (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsRebuildEnvironment: ModelEnvironment
    ) => {
        return this.httpService.put(
            `forms/${formId}/types/${formTypeId}/versions/${formTypeVersionId}/pages/${formTypeVersionPageId}/models-rebuild-environment`,
            {
                modelsRebuildEnvironment: modelsRebuildEnvironment,
            }
        );
    };
}

const OcrModelsService = (httpService: IHttpService): IModelsService => {
    const repository = new HttpModelsRepository(httpService);

    const getForm = async (formId: string) => {
        return await repository.getForm(formId);
    };

    const getPage = async (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string
    ) => {
        const page = await repository.getPage(formId, formTypeId, formTypeVersionId, formTypeVersionPageId);

        return page;
    };

    const updateFormModelsInUse = async (formId: string, modelsInUse: ModelsInUse) => {
        return await repository.updateFormModelsInUse(formId, modelsInUse);
    };

    const updateFormModelsRebuildEnvironment = async (formId: string, modelsRebuildEnvironment: ModelEnvironment) => {
        return await repository.updateFormModelsRebuildEnvironment(formId, modelsRebuildEnvironment);
    };

    const updatePageModelsInUse = async (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsInUse: ModelsInUse
    ) => {
        return await repository.updatePageModelsInUse(
            formId,
            formTypeId,
            formTypeVersionId,
            formTypeVersionPageId,
            modelsInUse
        );
    };

    const updatePageModelsRebuildEnvironment = async (
        formId: string,
        formTypeId: string,
        formTypeVersionId: string,
        formTypeVersionPageId: string,
        modelsRebuildEnvironment: ModelEnvironment
    ) => {
        return await repository.updatePageModelsRebuildEnvironment(
            formId,
            formTypeId,
            formTypeVersionId,
            formTypeVersionPageId,
            modelsRebuildEnvironment
        );
    };

    return {
        getForm: getForm,
        getPage: getPage,
        updateFormModelsInUse: updateFormModelsInUse,
        updateFormModelsRebuildEnvironment: updateFormModelsRebuildEnvironment,
        updatePageModelsInUse: updatePageModelsInUse,
        updatePageModelsRebuildEnvironment: updatePageModelsRebuildEnvironment,
    };
};

export const ocrModelsService = OcrModelsService(httpService);
