import React, { ReactElement } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

type Children = {
    children: ReactElement;
};

export const Auth0ProviderWithNavigate = ({ children }: Children) => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!domain || !clientId || !audience || !redirectUri) {
        throw new Error(
            `Auth0 domain, clientId, audience or redirectUri is missing! values are: ${{
                domain,
                clientId,
                audience,
                redirectUri,
            }}`
        );
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
            cacheLocation={'localstorage'}
        >
            {children}
        </Auth0Provider>
    );
};
